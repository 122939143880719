
.item-list {
    /* Positioning */
    position: absolute;
    margin-top: 30px;
  
    /* Display & Box model */
    padding-left: 10px;
    background-color: white;
    width: 69.5%;
    max-height: 40vh;
    min-height: 0px;
    box-shadow: 10px 10px 5px grey;
  
    /* Color */
  
    /* Text */
    color: black;
    text-align: left;
    font-size: 15px;

    /* Other */
    z-index: 2;
    overflow-y: auto ;
}

.item {
  /* Display & Box model */
  padding-top: 5px;
  padding-bottom: 5px;
}

.item:hover {
     /* Display & Box model */
     background-color: var( --default-orange-color );
   
     /* Text */
     color: black;
}

.popup-dropDown .Triangle {
    top: 38px;
    left: 97%;
}

.PopupAddProduct-popup-main-container .popup-select{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 99% !important;
}

.popup-select {
    /* Display & Box model */
  padding-top: 5px;
  padding-bottom: 1px;

   /* Text */
   color: black;
   text-align: left;
   font-size: 15px; 
}