.order-section-info-page-container {
    /* Display & Box model */
    display: grid;
    grid-template-rows: 15px 1fr 1fr auto;
}

.button-back-to-orders-table {
    /* Positioning */
    justify-self: right;
}

.order-info {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-row: 2 / 3;
}

.order-history {
    /* Positioning */
    margin-bottom: 40px;

    /* Display & Box model */
    display: grid;
    grid-template-rows: 40px auto;
    grid-row: 3 / 4;
}

.order-history-title {
    /* Display & Box model */
    padding-bottom: 20px;
    grid-row: 1 / 2;

    /* Text */
    font-size: 15px;
    color: var( --default-blue-color );
    font-weight: 600;
}

.order-history-progress {
    /* Display & Box model */
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-row: 2 / 3;
    grid-column-gap: 5px;
}

.orderCycle,
.orderCustomer,
.customerTelephone,
.orderStatus,
.orderLogistic,
.orderStart,
.orderEnd {
    /* Display & Box model */
    padding-bottom: 10px;

    /* Text */
    color: var( --default-blue-color );
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 1px;
}

.orderStatus {
    margin-top: 35px !important;
    display: grid;
    grid-template-columns: 60px auto;
    margin: auto;
}

.order-infoOne-text {
    /* Text */
    color: black;
}

.order {
    /* Display & Box model */
    padding-bottom: 10px;

    /* Text */
    color: var( --default-blue-color );
}

.orderCode {
    /* Positioning */
    margin-bottom: 25px;

    /* Text */
    color: var( --default-blue-color );
}

.orderStatus {
    /* Positioning */
    margin-top: 46px;
}

.orders-product-title {
    /* Text */
    font-size: 15px;
    color: var( --default-blue-color );
    font-weight: 600;
}

.status {
    display: grid;
    grid-auto-rows: 20px 20px 35px auto;
}

.status-greyed {
    /* Text */
    color: lightgrey;
}

.status-text {
    /* Display & Box model */
    padding-bottom: 10px;

    /* Text */
    font-size: 12px;
    letter-spacing: 1px;
    text-align: center;
}

.progress {
    /* Positioning */
    margin-bottom: 10px;

    /* Display & Box model */
    border-bottom: 5px solid var( --deafult-grey-color );
}

.time,
.user,
.note {
    /* Text */
    color: var( --deafult-dark-grey-color );
    font-size: 12px;
    text-align: center;
}

.order-row-red {
    /* Text */
    color: red;
}

.dropdown-select-my-orders-statuses {
    /* Positioning */
    width: 200px;
    height: 35px;

    /* Display & Box model */
    line-height: 23px !important;

    /* Text */
    color: black !important;

    /* Other */
    cursor: pointer;  
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 2px grey;
    border: 1px solid rgb(236, 236, 236) !important;
    
}

.dropdown-select-my-orders-statuses .Triangle{
    /* Positioning */
    left: 180px;
    top: 15px;
    position: relative;
}

.dropdown-select-my-orders-statuses .Value{
    /* Positioning */
    padding-left: 8px;
    color: black !important;
    margin-top: 0;
    position: absolute;
    width: 200px;
}


.dropdown-item-list-my-orders-status {
    /* Positioning */
   margin-top: 30px !important;
   margin-left: 0px;
   position: absolute;
 
   /* Display & Box model */
   padding-left: 0px;
   padding-right: 0px;
   background-color: white;
   border: 1px solid black;
   width: 10%;
   max-height: 40vh;
   min-height: 0px;
   line-height: 25px;
 
   /* Text */
   color: black;
   text-align: left;
   font-size: 13px;
 
   /* Other */
   z-index: 2;
   overflow-y: auto;
 }

.item-order {
    /* Positioning */
    padding-left: 5px;
}

.item-order:hover {
    /* Display & Box model */
    background-color: white !important;

    /* Text */
    color: black;
}


.text-status-container {
    /* Positioning */
    height: 40px;
    line-height: 40px;
}

.verify-button-statuses{
    margin: 0 auto;
    color: green;
    text-align: center;
    border: 1px solid lightgray;
    padding: 2px;
    border-radius: 15px;
    margin-top: 5px;
    width: 50%;
    background-color: var( --deafult-grey-color);
    align-self: end;
}


.verify-button-statuses a {
    text-decoration: none;
    color: green;
}
