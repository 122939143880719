.gridPackages-item-info {
    /* Positioning */


    /* Display & Box model */
    padding: 7px;
    text-align: left;
    margin-top: 9px;
    margin-bottom: auto;

    /* Color */
    
    /* Text */
    text-align: left;

    /* Other */
}

.gridPackages-item-dots-row{
    /* Positioning */
   
    /* Display & Box model */
    display: grid;
    grid-column-start: 1;
    grid-column-end: 12;
    grid-row: 3/4;
    height: auto;

    /* Color */
    
    /* Text */

    /* Other */
}

.hub-packages-row-data {
    /* Positioning */
    margin-top: 5px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    justify-content: center;

    /* Color */
    
    /* Text */

    /* Other */
}
.gridPackages-item-packetagcode{
    /* Positioning */

    /* Display & Box model */
    padding: 7px;
    text-align: left;
    margin-top: 9px;
    margin-bottom: auto;

    /* Color */
    color: var(--default-blue-color);

    /* Text */
    text-align: left;
    font-weight: bold;
    font-size: 16px;

    /* Other */

}