.providerInfoDetails-main-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 7fr 1fr;
    grid-template-rows: 1fr;
}

.providerInfoDetail-content {
    /* Display & Box model */
    padding-top: 40px;
    display: grid;
    grid-column: 2 / 3;
    grid-template-rows: 50px auto auto auto auto
}

.providerInfoDetail-back-to-stands {
    /* Display & Box model */
    grid-row: 1 / 2;
}

.providerInfoDetail-provider-or-product-component {
    /* Display & Box model */
    grid-row: 2 / 3;
}

.providerInfoDetail-provider-details-component {
    /* Display & Box model */
    grid-row: 3 / 4;
}

.providerInfoDetail-hub-details-component {
    /* Display & Box model */
    grid-row: 4 / 5;
}

.providerInfoDetail-products {
    /* Positioning */
    margin-top: 40px;
    margin-bottom: 20px;

     /* Display & Box model */
     display: grid;
     grid-row: 5 / 6;
     grid-template-columns: repeat(4, 1fr);
     border-top: 3px dotted grey;
}

@media only screen and (max-width: 700px) {
    .providerInfoDetails-main-container {
        /* Display & Box model */
        grid-template-columns: 1fr;
    }

    .HubInfoDetail-container {
        /* Display & Box model */
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .providerInfoDetail-content {
        /* Display & Box model */
        padding: 5px;
    }

    .providerInfoDetail-products {
        /* Display & Box model */
        grid-template-columns: 1fr;
    }
}