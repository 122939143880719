.hub-price-list-title {
    /* Positioning */
    margin-bottom: 40px;

    /* Display & Box model */
    
    /* Color */
    color: var( --default-blue-color );

    /* Text */
    font-family: 'Segoe UI Bold';
    font-size: 16px;

    /* Other */
}

.hub-price-list-header {
    /* Positioning */
    margin-bottom: 20px;
    
    /* Display & Box model */
    display: grid;
    grid-template-columns: repeat( 5, 1fr );
    justify-items: center;

    /* Color */
    
    /* Text */
    font-family: 'Segoe UI Semilight';
    letter-spacing: 1px;
    color: grey;
    font-size: 13px;

    /* Other */
}

.hub-price-list-row-input {
    /* Positioning */
    margin: 20px 0 20px 0;
    padding: 8px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    justify-items: center;

    /* Color */
    background-color: #ebebeb;

    /* Text */

    /* Other */
}

.hub-price-list-row-input-box {
    /* Positioning */
    height: 50px;
    width: 100%;
}

.hub-price-list-buttons {
    display: grid;
    padding-bottom: 30px;
}

.price-input-pricelist{
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.hub-price-list-row-input .hub-price-list-row-input-box {
    text-align: center;
}

.delete-row-pricelist-button{
    border: 1px solid var( --default-light-red-color );
    border-radius: 15px;
    height: 23px;
    width: 23px;
    background-color: var( --default-light-red-color );
    color: white;
}


#editingButton{
    margin-top: 15px;
}


