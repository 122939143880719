


#my-purchases-container {
    /* Positioning */
    padding: 0 5% 0 5%;

    /* Display & Box model */
    display: grid;
    grid-row: 2 / 3;
    grid-template-rows: 88px auto;
    min-height: 100%;
    /* Color */
    
    /* Text */

    /* Other */
}

#current-orders-container {
    /* Positioning */
    padding: 0 0 30px 0;
    /* Display & Box model */
    background-image: linear-gradient(to right, #333 20%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;

    /* Color */
    
    /* Text */

    /* Other */
}

#my-purchases-page {
    display: grid;
    grid-template-rows: 300px auto 80px;
    min-height: 100%;
}

#my-purchases-page #marketing-banner {
    /* Positioning */
    height: 300px;
    width: 100%;
    /* Display & Box model */
    grid-row: 1 / 2;
    background: url('/img/marketing-banner.png') no-repeat;
    background-size: cover;
    /* Color */
    background-color: #ececec;
    /* Text */

    /* Other */
}

#my-purchases-page .footer-bottom-main {
    margin-top: 20px;
    height: fit-content;
}