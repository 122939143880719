#provider-view-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "provider-view-picture-slider provider-view-hub-conatent";
}

.provider-view-title {
    /* Positioning */
    margin-right: 2px;

    /* Text */
    font-size: 20px;
    color: var( --default-blue-color );
    font-weight: 600;
}

.provider-description {
    /* Display & Box model */
    grid-column: 1 / 2;
}

.providerDetails-filters {
    /* Display & Box model */
    width: 310px;
}

#my-provider-actions {
    /* Positioning */
    margin-top: 20px;
}

.provider-view-title-editable {
    /* Display & Box model */
    background-color: #fff;
    height: 33px;

    /* Text */
    font-family: 'Segoe UI Semibold' !important;
    font-size: 25px !important;
    color: var( --default-blue-color );
}

.provider-view-quality {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.provider-view-item-data {
    /* Display & Box model */
    grid-column: 1 / 2;

    /* Text */
    text-align: left;
}

#provider-view-container .Triangle {
    /* Positioning */
    left: 85px;
}

#provider-view-container #dots-row {
    /* Display & Box model */
    grid-column: 2/ 3;
}

#provider-view-first-row {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 320px min-content;
    align-items: center;
}

#provider-view-info {
    /* Display & Box model */
    display: grid;
    grid-gap: 5px;
    grid-template-rows: 41px auto 28px 45px 10px;
    grid-template-columns: 1fr;
}

#provider-view-container .gallery-button {
    /* Text */
    text-align: right;
}

#provider-view-container .button {
    /* Positioning */
    margin-right: 60px;

    /* Display & Box model */
    width: 217px;

    /* Text */
    font-weight: bold;
}

@media only screen and (max-width: 1024px) { 
    #provider-view-container {
        /* Display & Box model */
        grid-template-columns: auto !important;
        grid-template-rows: 1fr 1fr !important;
        grid-template-areas: "provider-view-hub-conatent" "provider-view-picture-slider"  !important;
    }

    #provider-view-container #dots-row {
        /* Display & Box model */
        grid-column: 1/ 2;
    }

    .title-actions {
        /* Display & Box model */
        grid-row: 2 / 3;
        width: 320px;
    }

    #provider-view-first-row {
        /* Positioning */
        margin-top: 25px;
        
        /* Display & Box model */
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        align-items: center;
    }

    #provider-view-info {
        /* Display & Box model */
        grid-template-rows: 91px auto 28px 45px 10px;
    }

    #provider-view-container .gallery-button {
        /* Text */
        text-align: center;
    }
    
    #provider-view-container .button {
        /* Positioning */
        margin-right: 0px;

        /* Display & Box model */
        width: 190px;

        /* Text */
        font-weight: bold;
    }
}

#provider-view-picture-slider {
    grid-area: provider-view-picture-slider;
}

.provider-view-first-row-name {
    /* Positioning */
    padding-left: 5px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;

    /* Other */
    overflow: hidden;
    white-space: nowrap;
}

.title-actions {
    /* Display & Box model */
    display: grid;
    grid-template-columns: min-content min-content, min-content;
    align-items: center;
}

.provider-comments {
    /* Display & Box model */
    grid-column: 1 / 2;
}

.provider-nakupi {
    /* Display & Box model */
    grid-column: 2 / 3;
}

.provider-starGrading {
    /* Display & Box model */
    grid-column: 3 / 4;
}

.provider-view-one-line {
    /* Display & Box model */
    padding-left: 5px;
    height: 49px;
    display: grid;
    grid-template-columns: 77px 91px 100px;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
}

.provider-view-one-line .edit-area {
    /* Display & Box model */
    width: 75px;
    height: 18px;
}

.provider-view-first-row-name {
    /* Positioning */
    padding-left: 5px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;

    /* Other */
    overflow: hidden;
    white-space: nowrap;
}