.invalid-mail-notification {
    /* Positioning */

    /* Display & Box model */
    grid-column: 2;
    
    /* Color */
    color: var( --default-light-red-color);  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.role-container {
    /* Positioning */
    -webkit-box-align: center;
    -webkit-box-pack: center;
    min-height: 50px;
    width:100%;
    border: 1px solid var( --deafult-dark-grey-color );
    border-radius: 8px;
    
    margin-bottom: 6px;
   

    /* Display & Box model */
    display: flex;
    flex-wrap: wrap;
    grid-column: 2;
    justify-content: center;
    -webkit-box-align: center;
    
    align-items: center;
    align-self: center;

    /* Color */
    background-color: #fff;  
}

.avilable-roles-chip {
    /* Positioning */  
    height: min-content;
    line-height: 40px;
    border-radius: 25px;
    padding-left: 12px;
    padding-right: 12px;
    margin: 3px;

    /* Display & Box model */
    display: flex;
    align-items: center;

    /* Color */
    background-color: var( --default-orange-color );

    /* Text */
    font-size: 13px;
    text-align: center;

    /* Other */
    cursor: inherit;
    white-space: nowrap;
    -moz-user-select: none;
  }

  .chip-closebtn {
    /* Positioning */
    padding-left: 10px;
    cursor: pointer;
    line-height: 30px;
    float: right;

    /* Color */
    color: #888;
    
    /* Text */
    font-weight: bold;
    font-size: 20px;
    
  }
  
  .chip-closebtn:hover {
    color: #000;
  }
  
  .invalid-value-box {
    background-color: var(  --default-light-red-color );
  }

  @media only screen and (max-width: 600px) {
    .user-profile-container{
        grid-column: 1;
        grid-template-columns: auto;
    }

    .name-settings{
        text-align: left;
    }

    .edit-area-settings{
      grid-column: 1;
      min-height: 30px;
    }

    .role-container {
      min-height: 50px;
      grid-column: 1;
    }
} 



