#my-purchase-order {
    /* Positioning */
    margin-bottom: 40px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: min-content auto min-content;

    /* Color */

    /* Text */

    /* Other */
}

#my-purchase-order-qr-image {
    /* Positioning */
    padding: 0 50px 0 0;

    /* Display & Box model */
    
    /* Color */
    
    /* Text */

    /* Other */
}

#QR-image-background {
    /* Positioning */
    height: 120px;
    width: 120px;

    /* Display & Box model */
    
    /* Color */
    /* background-image: url('/img/QR_code_example.png'); */
    background-size:cover;
    /* Text */

    /* Other */
}

#my-purchase-order-info {
    /* Positioning */
    padding: 0 50px 0 50px;

    /* Display & Box model */
    display: grid;

    /* Color */
    
    /* Text */

    /* Other */
}

#my-purchase-order-delivery {
    /* Positioning */
    padding: 0 0 0 50px;

    /* Display & Box model */
    justify-self: end;
    align-self: end;    
    /* Color */
    
    /* Text */
    letter-spacing: 1px;

    /* Other */
}

.my-purchase-order-delivery-text {
    /* Positioning */

    /* Display & Box model */

    /* Color */
    
    /* Text */
    text-transform: uppercase;

    /* Other */
}

.order-blue-text {
    /* Positioning */
    padding-bottom: 10px;

    /* Display & Box model */
    
    /* Color */
    color: #0E5FA6;

    /* Text */
    letter-spacing: 1px;
    font-size: 13px;

    /* Other */
}

.order-blue-bold-text {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    color: #0E5FA6;

    /* Text */
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 12px;

    /* Other */
}

.order-normal-text {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    color: black;

    /* Text */
    letter-spacing: 1px;
    font-size: 12px;

    /* Other */
}

.ordered-products-text {
    /* Positioning */
    padding-top: 5px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: min-content min-content max-content min-content 1fr;

    /* Color */
    color: black;

    /* Text */
    letter-spacing: 1px;
    font-size: 12px;

    /* Other */
}

#ordered-item-price {
    /* Positioning */
        
    /* Display & Box model */
    justify-self: end;
    /* Color */

    /* Text */

    /* Other */
}

#line {
    /* Positioning */
    padding: 5px 0 5px 0;
    
    /* Display & Box model */
    border-bottom: 2px solid rgb(197, 194, 194);

    /* Color */

    /* Text */

    /* Other */
}

.ordered-item-margin-right {
    margin-right: 5px;
}

#order-again-text {
    /* Positioning */
    margin-bottom: 80px;

    /* Display & Box model */

    /* Color */
    color: #0E5FA6;

    /* Text */
    text-transform: uppercase;
    font-family: 'Segoe UI Bold';

    /* Other */
    cursor: pointer;
}

@media only screen and (max-width: 1024px) {
    #my-purchase-order {
        /* Display & Box model */
        grid-template-columns: auto;
    }

    #my-purchase-order-info {
        /* Text */
        text-align: center;
    }

    #QR-image-background {
        /* Positioning */
        justify-self: center;
    }

    #my-purchase-order-delivery {
        /* Positioning */
        justify-self: center;
        align-self: end;

        /* Display & Box model */
        padding: 0px;

        /* Text */
        letter-spacing: 1px;
        text-align: center;
    }
}