
.message-handler {
    /* Positioning */
    position: absolute;
    left: 50px;
    width: 300px;
    padding: 10px;

    /* Display & Box model */
    display: inline-grid;
    grid-template-columns: 15% 85%;
    border: 1px solid #f0f0f0;
    box-shadow: 0 0 15px #b4b3b3;
    border-radius:  20px;

    /* Color */
    background: #f0f0f0;
   
   /* Text */

   /* Other */
}

.icon-item {
    /* Other */
    text-align: center;
}