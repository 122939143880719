.tableHubOrder-row-container {
    /* Display & Box model */
    height: 51px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: grid;
    grid-template-columns: 7% 8% 14% 12% 7% 22% 7% 18% 0%;
    background-image: linear-gradient(to right, var( --deafult-dark-grey-color ) 20%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 2px;
    background-repeat: repeat-x;

    /* Text */
    font-size: 14px;

    /* Other */
    cursor: pointer;
}

.tableHubOrder-code {
    /* Display & Box model */
    padding-left: 20px;
    
    /* Text */
    font-family: 'Segoe UI Bold';
    font-size: 17px;
    color: var( --default-blue-color );
    font-weight: 600;
    text-align: left;
    line-height: 51px;
}

#driverSelect {
    /* Display & Box model */
    padding-left: 10px;
    height: 28px;
    width: 140px;
    margin-bottom: 40px;
    background-color: #ebebeb;
}


.tableHubOrder-row-container .button {
    width: 70px;
    line-height: 15px;
    font-size: 14px;
}



.tableHubOrder-provider,
.tableHubOrder-customer,
.tableHubOrder-status, 
.tableHubOrder-logistic,
.tableHubOrder-orderAddress,
.tableHubOrder-date,
.tableHubOrder-driver {
    /* Display & Box model */
    padding-left: 3px;
    padding-right: 3px;

    /* Text */
    font-family: 'Segoe UI';
    letter-spacing: 1px;
    font-size: 12px;
    text-align: left;
    line-height: 51px;
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}


.tableHubOrder-driver {
    display: grid;
    grid-template-columns: 65% 20%;

}


.tableHubOrder-row-container .btn-disabled {
    line-height: 15px !important;
    font-size: 14px !important;
}