.gridWarehouse-item-info {
    /* Positioning */


    /* Display & Box model */
    padding: 7px;
    text-align: left;
    margin-top: 9px;
    margin-bottom: auto;

    /* Color */
    
    /* Text */
    text-align: left;
    /* Other */
}

.gridWarehouse-item-dots-row{
    /* Positioning */
   
    /* Display & Box model */
    display: grid;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row: 3/4;
    height: auto;

    /* Color */
    
    /* Text */

    /* Other */
}

.hub-warehouse-row-data {
    /* Positioning */
    margin-top: 5px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: calc(20%) calc(68%) calc(12%);
    justify-content: center;

    /* Color */
    
    /* Text */

    /* Other */
}
