.my-order-page-content {
    /* Positioning */
   
    /* Display & Box model */

    /* Color */
    
    /* Text */

    /* Other */
}

#dots-row {
    /* Positioning */
    width: 100%;
    height: 20px;

    /* Display & Box model */
    
    /* Color */
    background-image: linear-gradient( to right, #333 20%, rgba( 255, 255, 255, 0 ) 0% );
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;

    /* Text */

    /* Other */
}

.container-text-order {
    /* Positioning */
    margin-top: 30px;

    /* Display & Box model */
    display: grid;
    /*grid-template-columns: 1fr 1fr;*/

}

.submit-order-button {
    /* Positioning */
    width: 300px;
    height: 30px;
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 30px;

    /* Display & Box model */
    justify-self: center;

    /* Color */
    background-color: var( --default-blue-color );
    color: white;

    /* Text */
    font-family: 'Segoe UI Semibold';
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;

    /* Other */    
    cursor: pointer;
}

.button-container {
    /* Text */
    text-align: right;

}

.myOrders-dropdown-main {
    /* Display & Box model */
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: auto 300px;
}

.myOrders-dropdown-text {
    /* Positioning */
    justify-self: right;

    /* Display & Box model */
    padding-right: 20px;
    grid-column: 1 / 2;
}

.myOrders-dropdown-grid-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    width: 300px;
    background-color: var( --deafult-grey-color );
    border-radius: 25px;

    /* Color */

    /* Text */
    text-align: center;
}

.myOrders-dropdown {
    /* Display & Box model */
    grid-column: 1 / 2;
}

.myOrders-dropdown-data .select {
    /* Positioning */
    margin-left: 30px;

    /* Display & Box model */
    background-color: var( --deafult-grey-color ) !important;
}

.myOrders-dropdown-data .Triangle {
    /* Positioning */
    left: 75px !important;
}

.myOrders-dropdown .select {
    /* Positioning */
    margin-left: 30px;

    /* Display & Box model */
    background-color: var( --deafult-grey-color ) !important;
}

.myOrders-dropdown .Triangle {
    /* Positioning */
    left: 90px !important;
}

.myOrders-dropdown-data {
    /* Display & Box model */
    grid-column: 2 / 3;
    border-right: 2px solid var( --deafult-dark-grey-color );
    border-left: 2px solid var( --deafult-dark-grey-color );

    /* Color */
    color: var( --deafult-dark-grey-color );

    /* Text */
    font-size: 20px;
}

.myOrders-dropdown-image {
    /* Display & Box model */
    grid-column: 3 / 4;
    margin-top: -2px;

    /* Other */
    cursor: pointer;

}

.hub-name {
    /* Display & Box model */
    margin-top: 20px;
    margin-bottom: 20px;

    /* Text */
    font-weight: 500;
}