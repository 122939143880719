.shopping-list-provider-info-market,
.shopping-list-provider-info-market-full {
    /* Positioning */
    margin-top: 20px;

    /* Display & Box model */
    display: grid;
    grid-template-rows: 70px auto 70px;
    grid-template-columns: 320px auto 314px;
    grid-area: provider-info;
    border-bottom: 5px dotted #ececec;

    /* Color */

    /* Text */

    /* Other */
}

.back-to-markets {
    /* Positioning */
    align-self: center;

    /* Display & Box model */
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    width: fit-content;

    /* Other */
    cursor: pointer;
}

.back-to-markets .text {
    /* Display & Box model */
    padding-left: 5px;

    /* Text */
    font-family: 'Segoe UI', sans-serif;
    color: #565655;
    font-size: 12px;
    font-weight: bold;
}

.market-title {
    /* Positioning */
    justify-self: center;
    align-self: center;

    /* Display & Box model */
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    width: fit-content;
    /* Color */

    /* Text */
    color: #0e5fa6;
    font-size: 20px;

    /* Other */
}

.market-grade {
    /* Positioning */
    align-self: center;

    /* Display & Box model */
    grid-row: 1 / 2;
    grid-column: 3 / 4;
    width: fit-content;
}

.market-image {
    /* Positioning */
    justify-self: center;

     /* Display & Box model */
     grid-row: 2 / 3;
     grid-column: 1 / 2;
     width: fit-content;
}

.market-description {
    /* Positioning */

    /* Display & Box model */
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    padding-left: 50px;
    padding-right: 50px;
    
    /* Color */

    /* Text */
    color: grey;
    text-align: justify;

    /* Other */
}

.market-filters {
     /* Display & Box model */
     display: grid;
     grid-template-columns: 1fr 1fr;
     grid-template-rows: repeat(5, 1fr);
     grid-column-gap: 10px;
}

.shopping-list-provider-info-market .products-item,
.shopping-list-provider-info-market-full .products-item {
    /* Positioning */
    margin-bottom: 10px;

    /* Display & Box model */
    height: fit-content !important;
    padding-left: 5px;
    padding-right: 5px;
    border: 2px solid #0e5fa6;

    /* Text */
    text-transform: uppercase;
}

.shopping-list-provider-info-market .shopping-list-button-show-more,
.shopping-list-provider-info-market-full .shopping-list-button-show-more {
    /* Positioning */
    justify-self: center;
    align-self: center;


    /* Display & Box model */
    grid-row: 3 / 4;
    grid-column: 2 / 3;
    width: fit-content;
    height: 20px;
    padding: 5px 15px 5px 15px;
    border: 2px solid #ececec;
    border-radius: 20px;

    /* Text */
    color: grey;

    /* Other */
    cursor: pointer;
}

@media only screen and ( max-width: 640px ) {
    .shopping-list-provider-info-market-full {
        /* Display & Box model */
        display: none;
    }
}

@media only screen and ( max-width: 1120px ) {
    .shopping-list-provider-info-market,
    .shopping-list-provider-info-market-full {
        /* Positioning */
        margin-bottom: 20px;

        /* Display & Box model */
        grid-template-rows: repeat(5, auto) 70px;
        grid-template-columns: 1fr;
        padding-left: 10px;
        padding-right: 10px;
    }

    .back-to-markets {
        /* Display & Box model */
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        padding-bottom: 10px;
    }
    
    .market-title {
        /* Display & Box model */
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        padding-bottom: 10px;
    }
    
    .market-grade {
        /* Positioning */
        justify-self: center;

        /* Display & Box model */
        grid-row: 3 / 4;
        grid-column: 1 / 2;
        padding-bottom: 10px;
        width: fit-content;
    }
    
    .market-image {
         /* Display & Box model */
         grid-row: 4 / 5;
         grid-column: 1 / 2;
         width: fit-content;
    }
    
    .market-description {
        /* Display & Box model */
        grid-row: 5 / 6;
        grid-column: 1 / 2;
        padding-left: 10px;
        padding-right: 10px;
    }

    .market-filters {
        /* Display & Box model */
        grid-row: 6 / 7;
        grid-column: 1 / 2;
        margin-top: 20px;
   }
    
    .shopping-list-provider-info-market .shopping-list-button-show-more,
    .shopping-list-provider-info-market-full .shopping-list-button-show-more {
        /* Display & Box model */
        grid-row: 7 / 8;
        grid-column: 1 / 2;
        margin-top: 30px;
        margin-bottom: 20px;
    }
}

@media (min-width: 550px) and (max-width: 900px) {
    .market-filters {
        /* Display & Box model */
        grid-template-columns: 1fr 1fr 1fr;
   }
}

@media (min-width: 901px) and (max-width: 1280px) {
    .market-filters {
        /* Display & Box model */
        grid-template-columns: 1fr 1fr 1fr 1fr;
   }
}