.TableHubCurrentCycleHeader-container span {
    /* Display & Box model */
    display: inline-block;
}

.TableHubCurrentCycleHeader-container {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    height: 25px;
    grid-template-columns: repeat(5, 1fr);

    /* Color */

    /* Text */
    color: var( --deafult-dark-grey-color );
    letter-spacing: 1px;
    font-size: 12px;

    /* Other */
}