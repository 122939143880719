
#my-purchases-hader {
    /* Positioning */
    padding: 20px 0 0 0;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Color */
    
    /* Text */
    
    /* Other */
}

#my-purchases-text {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    
    /* Text */
    font-family: 'Segoe UI Semibold';
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 16px;
    color: #ffa731;

    /* Other */
}

#my-purchases-back-to-store {
    /* Positioning */
    
    /* Display & Box model */
    justify-self: end;
    /* Color */
    
    /* Text */
    font-size: 11px;
    color: #565655;
    font-weight: bold;

    /* Other */
}

#my-purchases-back-to-store::first-letter {
    text-transform: uppercase;
}

#my-purchases-click-back-to-store {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */

    /* Text */

    /* Other */
    cursor: pointer;
}

#my-purchases-click-back-to-store > span {
    margin-right: 5px;
}
