.side-menu-footer {
    /* Positioning */

    /* Display & Box model */
    padding: 30px 0 0 5px;
    display: grid;
    grid-template-rows: 25px 25px 25px auto;
    justify-items: start;
    align-items: center;
    /* Color */
    
    /* Text */

    /* Other */
    align-items: center;
}

.side-menu-footer > button {
    /* Positioning */
    /* Display & Box model */

    /* Color */
    
    /* Text */
    font-size: 15px;
    letter-spacing: 1px;

    /* Other */
    cursor: pointer;
}