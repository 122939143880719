:root {
    --default-blue-color: #0E5FA6;
    --default-orange-color: #f8a800;
    --deafult-grey-color: #ececec;
    --deafult-dark-grey-color: #737778;
    --default-light-red-color: #ff6666;
}

textarea::-webkit-input-placeholder {
    color: grey !important;
  }
  
  textarea:-moz-placeholder { /* Firefox 18- */
    color: grey !important;  
  }
  
  textarea::-moz-placeholder {  /* Firefox 19+ */
    color: grey !important;  
  }
  
  textarea:-ms-input-placeholder {
    color: grey !important;  
  }
  
  textarea::placeholder {
    color: grey !important;  
  }