.container {
    /* Positioning */
    height: 100%;

    /* Display & Box model */
    
    /* Color */
    background-color: white;

    /* Text */

    /* Other */
}

::placeholder {
    color: #feb361;
}

.input {
    /* Positioning */
    width: calc( 100% - 40px );
    height: 100%;
    padding-left: 20px;
    /* Display & Box model */

    /* Color */
    color: #feb361;

    /* Text */
    font-family: 'Segoe UI Light Italic';
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 13px;

    /* Other */
}

.suggestionsContainerOpen {
    /* Positioning */
    position: relative;
    
    /* Display & Box model */

    z-index: 11;
    /* Color */

    background-color: white;
    /* Text */

    /* Other */
}

.suggestionsList {
    /* Positioning */

    /* Display & Box model */
    border-top: 1px solid grey;
    /* Color */
    
    /* Text */

    /* Other */
    list-style-type: none;
}

.suggestion {
    /* Positioning */
    padding: 5px 20px 5px 30px;
    /* Display & Box model */

    /* Color */
    color: #feb361;
    /* Text */
    font-size: 14px;
    font-weight: bold;
    font-display: 'Segoe UI Semibold';
    letter-spacing: 1px;
    /* Other */
    cursor: pointer;
}

.suggestion:hover {
    /* Positioning */

    /* Display & Box model */

    /* Color */
    background-color: #ececec;

    /* Text */

    /* Other */
}