.pricelist-main-container-payment-page {
    /* Positioning */
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    
    /* Display & Box model */
    background-color: rgba( 0,0,0, 0.3);
    /* Text */
    color: var( --default-orange-color );

    /* Other */
    z-index: 2;
}

.pricelist-main-container-payment-page .pricelist-window-payment-page {
    /* Display & Box model */
    top: 50%;
    left: 50%;
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    width: 60%;
    display: grid;
    grid-template-rows: min-content auto min-content;
    grid-gap: 20px;
    color: black;
}

#hub-price-list-row-data-payment-page {
    /* Positioning */
    padding: 15px 0 15px 0;
    
    /* Display & Box model */
    display: grid;
    grid-template-columns: repeat( 4, 1fr );
    justify-items: center;

    /* Color */
    
    /* Text */

    /* Other */
    background-image: linear-gradient(to right, #333 20%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;
}


.hub-price-list-header-payment-page {
    /* Positioning */
    margin-top: 15px;
    
    /* Display & Box model */
    display: grid;
    grid-template-columns: repeat( 4, 1fr );
    justify-items: center;

    /* Color */
    
    /* Text */
    font-family: 'Segoe UI Semilight';
    letter-spacing: 1px;
    color: var( --default-orange-color );
    font-size: 13px;

    /* Other */
}

#close-price-list-payment-page {
    display: grid;
    justify-content: center;
    color: var( --default-orange-color );
}

#hub-price-list-not-available {
    color: var( --default-light-red-color);
    text-align: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .pricelist-main-container-payment-page .pricelist-window-payment-page {
        /* Display & Box model */
        margin: 150px auto;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .pricelist-main-container-payment-page .pricelist-window-payment-page {
        /* Display & Box model */
        margin: 200px auto;
    }
} 