.button-add-stand {
    /*Text*/
    text-align: right;
}

#stand-row-line {
    /* Positioning */
    width: 100%;
    margin-bottom: 30px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 180px 1fr 200px;
    grid-template-rows: 180px, 10px;
    grid-row-gap: 5px;
    grid-column-gap: 30px; 

    /* Color */

    /* Text */

    /* Other */
}

.stands-row-hub {
    /* Display & Box model */
    grid-column: 1 / 4;
    grid-row: 1 / 2;

    /* Text */
    font-family: 'Segoe UI Semibold';
    font-size: 18px;
    color: var( --default-blue-color );
}

.stand-dots-row {
    /* Positioning */
    width: 100%;
    height: 20px;

    /* Display & Box model */
    grid-column: 1 / 4;
    grid-row: 3 / 4;
    
    /* Color */
    background-image: linear-gradient( to right, var( --deafult-dark-grey-color ) 20%, rgba( 255, 255, 255, 0 ) 0% );
    background-position: bottom;
    background-size: 10px 2px;
    background-repeat: repeat-x;
}

.actions-container {
    /* Display & Box model */
    display: grid;
    grid-template-rows: 35px 35px auto;
}

@media only screen and (max-width: 1024px) {
    .button-add-stand {
        /* Text */
        text-align: center;
    }

    #stand-row-line {
        /* Display & Box model */
        grid-template-columns: auto !important;
        grid-template-rows: min-content min-content min-content min-content !important;
        grid-gap: 5px !important;
        justify-items: center;

    }

    .stands-row-hub,
    .icon {
        /* Display & Box model */
        grid-column: 1 / 2;
    }

    .actions-container {
        /* Display & Box model */
        grid-column: 1 / 2;
        grid-template-rows: 45px 30px 61px;
    }

    .stand-row-line-info {
        /* Positioning */
        justify-items: center !important;

        /* Display & Box model */
        grid-column: 1 / 2;
    }

    .stand-dots-row {
        /* Display & Box model */
        grid-column: 1 / 2;
        grid-row: 5 / 6;
    }
}

.stand-row-line-info {
    /* Positioning */
    padding-top: 18px;
    padding-bottom: 22px;

    /* Display & Box model */
    display: grid;

    /* Color */
    
    /* Text */

    /* Other */
}

.stand-row-text{
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    color: var( --default-blue-color );
    /* Text */

    /* Other */
}

.stand-row-text-name{
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    color: var( --default-blue-color );

    /* Text */
    font-size: 18px;
    font-family: 'Segoe UI Semibold';

    /* Other */
    text-transform: uppercase;
}

.stand-row-star {
    /* Positioning */
    padding-bottom: 15px;

    /* Display & Box model */
    
    /* Color */

    /* Text */

    /* Other */
}

.stand-row-description {
    --max-lines: 3;

    /* Positioning */
    position: relative;
    max-height: calc( 12px * var(--max-lines));
    max-width: 500px;
    align-self: end;

    /* Display & Box model */
    padding-right: 1rem; /* space for ellipsis */

    /* Display & Box model */
    align-self: end;

    /* Color */

    /* Text */
    font-size: 12px;

    /* Other */
    overflow: hidden;

}
.stand-row-description::before {
    position: absolute;
    content: "...";
    inset-block-end: 0; /* "bottom" */
    inset-inline-end: 0; /* "right" */
}
.stand-row-description::after {
    content: "";
    position: absolute;
    inset-inline-end: 0; /* "right" */
    width: 1rem;
    height: 1rem;
    background: white;
}

.stand-row-line-cycle {
    /* Positioning */
    padding-top: 18px;
    padding-bottom: 20px;

    /* Display & Box model */
    display: grid;
    justify-items: center;
    align-self: end;

    /* Color */

    /* Text */
    font-size: 12px;

    /* Other */
    white-space: nowrap;
}

.icon-map {
    /* Positioning */
    justify-self: right;
}

.icon-distance {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
}

.toggle-activate-deactivate {
    /* Positioning */
    justify-self: right;
}

.delete-stand {
    /* Display & Box model */
    display: grid;
    align-items: center;
    grid-template-columns: auto 30px;
}

.delete-icon {
    /* Positioning */
    margin-left: 10px;
}

.distance {
    /* Positioning */
    justify-self: left;
    /* Display & Box model */
    width: fit-content;

    /* Text */
    font-size: 12px;
    font-family: 'Segoe UI';
    color: var( --deafult-dark-grey-color );
}

.distance-text {
    /* Text */
    font-size: 9px;
}

.distance-text-value {
    /* Text */
    font-size: 16px;
}

.stand-row-line-show-stand {
    /* Positioning */
    padding-bottom: 20px;
    
    /* Display & Box model */
    align-self: end;
    align-items: center;
    display: grid;
    grid-template-columns: auto 30px;
    /* Color */

    /* Text */
    color: var( --default-orange-color );

    /* Other */
    cursor: pointer;
}

.button-text,
.delete-text {
    /* Positioning */
    justify-self: right;
}

.stand-row-line-arrow-right {
    /* Positioning */
    width: 10px;
    height: 10px;
    margin-left: 10px;

    /* Display & Box model */
    border-top: 2px solid var( --default-orange-color );
    border-right: 2px solid var( --default-orange-color );

    /* Color */

    /* Text */

    /* Other */
    transform: rotate( 45deg );
}

.toggle-activate-deactivate .button {
    /* Positioning */
    margin: 10px;
    
    /* Display & Box model */
    display: inline-block;
    height: 24px;
    width: 150px !important;
    background-color: var( --default-orange-color );
    border-radius: 16px;
    
    /* Text */
    padding-top: 3px;
    color: white;
    text-align: center;

    /* Other */
    cursor: pointer;
}

.stand-row-market-status {
    font-size: 12px;
    letter-spacing: 1px;
}

.deactive {
    font-size: 14px !important;
    color: red !important;
}

.stand-row-hub-deactive {
    color: gray;
}