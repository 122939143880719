.item-holder-grid {
    /* Positioning */
    padding: 5px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: auto;
    justify-items: center;
    
    /* Color */
    
    /* Text */

    /* Other */
}

.item-image{
	/* Positioning */
	
	/* Display & Box model */
	height: 145px;

	/* Color */
	
	/* Text */
	text-align: center;

	/* Other */
	
}

.item-seller{
	/* Positioning */
	
	/* Display & Box model */
	/* Color */
	color: #575756;
	
	/* Text */
	text-align: center;
	font-size: 12px;
	letter-spacing: 1px;
	font-family: 'Segoe UI';

	/* Other */
}

.item-product{
	/* Positioning */
	
	/* Display & Box model */
	/* Color */
	color: #0660ab;

	/* Text */
	text-align: center;
	font-size: 18px;
	font-family: 'Segoe UI Semibold';

	/* Other */

}

.item-data{
	/* Positioning */
	padding-top: 5px;
	width: 100%;

	/* Display & Box model */
	display: grid;
	justify-items: center;
	
	/* Color */
	
	/* Text */
	font-size: 12px;
	font-family: 'Segoe UI';
	letter-spacing: 0.5px;

	/* Other */
}

.dropdown-grid-container{
	/* Positioning */
	padding: 10px 0 0 0;

	/* Display & Box model */
	display: grid;
	width: 165px;
	grid-template-columns: 1fr 1fr 1fr;
	justify-items: center;
	/* Color */
	
	/* Text */

	/* Other */
	white-space: nowrap;
}

.dropdown-dropdown{
	/* Positioning */

	/* Display & Box model */
	border-right: 1px solid black;
	width: 66px;

	/* Color */
	
	/* Text */

	/* Other */

}


.dropdown-dropdown .Value {
	/* Positioning */
	position: absolute;
	margin-top: 0px;

	/* Display & Box model */
	padding-left: 0px !important;
	width: 52px;

	/* Text */
	color: black !important;
}


.dropdown-dropdown .dropdown-select {
	/* Display & Box model */
	padding-top: 0;

	/* Font */
	font-size: 14px;

	/* Other */
	cursor: pointer;
}

.dropdown-dropdown .dropdown-select .Triangle {
	/* Positioning */
	top: 13px;
	left: 36px;
}

.dropdown-dropdown .dropdown-item-list {
	/* Positioning */
   margin-top: 23px;
   margin-left: -10px;
 
   /* Display & Box model */
   padding-left: 0px;
   background-color: white;
   border: 1px solid black;
   width: 60px;
   max-height: 89px;
   min-height: 0px;
   box-shadow: 5px 5px 5px grey;
 
   /* Text */
   color: black;
   text-align: left;
   font-size: 15px;
 
   /* Other */
   z-index: 2;
   overflow-y: auto;
   margin-left: -10px;
 }

 .dropdown-dropdown .dropdown-item-list .item {
	padding-left: 10px;
 }

.dropdown-data{
	/* Positioning */
	padding: 5px 10px 0 10px;

	/* Display & Box model */
	
	/* Color */
	
	/* Text */

	/* Other */
}

.dropdown-image{
	/* Positioning */
	padding-top: 6px;
	padding-left: 6px;

	/* Display & Box model */
	justify-self: start !important;

	/* Color */
	
	/* Text */

	/* Other */
	cursor: pointer;
}

.dropdown-image-disabled{
	/* Positioning */
	padding-top: 3px;

	/* Display & Box model */
	justify-self: start !important;
	filter: grayscale( 1 );
	/* Color */
	
	/* Text */

	/* Other */
	cursor: pointer;
}

.basket-clicked {
	/* Positioning */
	padding-top: 6px;
	padding-left: 6px;

	/* Display & Box model */
	justify-self: start !important;
	filter: grayscale( 1 );
	/* Color */
	
	/* Text */

	/* Other */
	cursor: pointer;
}

.product-information {
	/* Other */
	cursor: pointer;
}