/* FOR TOOLTIPS */

:root {
    --item-tooltip-width: 80px;
    --item-tooltip-margin-left: 60px;
    --item-tooltipLogo-margin-left: 60px;
    --item-tooltipShoppingCart-margin-left: 60px;
    --item-tooltipNotifications-margin-left: 60px;
    --item-tooltipProfile-margin-left: 60px;
    --item-tooltipArrowShoppingList-margin-left: 60px;
    --item-tooltipArrowShoppingListOpen-margin-left: 60px;
    --item-tooltipSwitchButton-margin-left: 60px;
}

.react-tooltip-lite {
    /* Display & box model */
    width: fit-content;
    background: #828282;
    border-radius: 5px;

    /* Text */
    color: white;
    font-family: 'Segoe UI', sans-serif;
    font-size: 12px;
    white-space: nowrap;
}

.react-tooltip-lite-arrow {
    /* Positioning */
    top: 10px;

    /* Display & box model */
    border-color: #828282;
}


#tooltipLogo {
    margin-left: var( --item-tooltipLogo-margin-left ) !important;
}

#tooltipShoppingCart {
   margin-left: var( --item-tooltipShoppingCart-margin-left ) !important;
}

#tooltipNotifications {
    margin-left: var( --item-tooltipNotifications-margin-left ) !important;
}

#tooltipProfile {
    margin-left: var( --item-tooltipProfile-margin-left ) !important;
}

#tooltipArrowShoppingList {
    margin-left: var( --item-tooltipArrowShoppingList-margin-left ) !important;
}

#tooltipArrowShoppingListOpen {
    margin-left: var( --item-tooltipArrowShoppingListOpen-margin-left ) !important;
}

#tooltipSwitchButton {
    margin-left: var( --item-tooltipSwitchButton-margin-left ) !important;
}

@media only screen and (max-width: 1024px) {
    #tooltipLogo,
    #tooltipShoppingCart,
    #tooltipNotifications,
    #tooltipProfile,
    #tooltipArrowShoppingList,
    #tooltipArrowShoppingListOpen,
    #tooltipSwitchButton {
        display: none !important;
    }
}

/* --------------------------------- */

.main-container {
    /* Display & box model */
    width: 100%;
    height: 100%;
}

.content {
    /* Position*/
    position: absolute;
    top: 104px;
    bottom: 0px;

    /* Display & box model */
    width: 100%;
    
    /* Other */
    overflow-y: auto;
    z-index: -1;
}