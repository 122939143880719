
.message-handler-addToProduct {
    /* Positioning */
    position: absolute;
    left: 50px;
    width: calc( 100% - 150px );
    padding: 20px 10px 20px 10px;

    /* Display & Box model */
    display: inline-grid;
    grid-template-columns: 50px 1fr;
    border: 1px solid #f0f0f0;
    box-shadow: 0 0 15px #b4b3b3;

    /* Color */
    background: #f0f0f0;
   
   /* Text */
   color: white;
   font-size: 15px;

   /* Other */
}

.icon-item {
    /* Other */
    text-align: center;
}