#print-overlay-container {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: black;
    opacity: 0.8;
    color: white;
    text-align: center;
}

#custom-loader {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.print-overlay-close {
    font-size: 40px;
    position: absolute;
    top: 15px;
    right: 25px;
    cursor: pointer;
}

.print-overlay-content {
    position: absolute;
    top: 40%;
    width: 100%;
    letter-spacing: 1.4px;
}

.print-no-permissions {
    line-height: 200px;
    font-weight: bold;
    font-size: 22px;
    color: red;
}