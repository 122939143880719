.seller-grid-container {
	/* Positioning */
	padding: 5px;

    /* Display & Box model */
    display: grid;
	justify-items: center;

    /* Color */

    /* Text */

	/* Other */
	cursor: pointer;
}

.seller-image{
	/* Positioning */
	height: 145px;
	
    /* Display & Box model */
    
    /* Color */

    /* Text */

    /* Other */
}

.seller-name{
	/* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    color: #0660ab;

    /* Text */
	font-size: 18px;
	font-family: 'Segoe UI Semibold';
	
    /* Other */
}

.seller-type{
	/* Positioning */
    
    /* Display & Box model */
    
    /* Color */
	color: #575756;

    /* Text */
	font-size: 12px;
	letter-spacing: 1px;
	
    /* Other */    
}

.seller-description{
	/* Positioning */
	max-width: 200px;
	
    /* Display & Box model */

	/* Color */

    /* Text */
	font-size: 12px;
	text-overflow: ellipsis;
	overflow: hidden; 

	/* Other */
	white-space: nowrap;
}

#seller-products {
	/* Positioning */
	width: 80%;
	margin-top: 10px;

	/* Display & Box model */
	display: grid;
	grid-gap: 10px;
	
	/* Color */

	/* Text */

	/* Other */
}

.seller-products-more{

	/* Positioning */

	/* Display & Box model */
	grid-template-columns: auto auto auto auto;

	/* Color */

	/* Text */

	/* Other */
}

.seller-products-less{

	/* Positioning */

	/* Display & Box model */
	grid-template-columns: auto auto;

	/* Color */

	/* Text */

	/* Other */
}

.products-item{
	/* Positioning */

	/* Display & Box model */
	border-radius: 20px;
	border: 1px solid;

	/* Color */

	/* Text */
	text-align: center;

	/* Other */
}

.products-item::first-letter{
	/* Positioning */

	/* Display & Box model */

	/* Color */

	/* Text */
	text-transform: uppercase;

	/* Other */
}

@media only screen and ( max-width: 760px ) {
	#seller-products {
		/* Positioning */
		width: 100% !important;
	
		/* Display & Box model */

		/* Color */
	
		/* Text */
	
		/* Other */
	}

	.seller-products-more{

		/* Positioning */
	
		/* Display & Box model */
		grid-template-columns: auto auto auto !important;
	
		/* Color */
	
		/* Text */
	
		/* Other */
	}
}