strong {
    /* Text */
    color: var( --default-blue-color );
}

#header {
	/* Positioning */
	width: 100%;
    height: 70px;

	/* Display & Box model */
	display: grid;
	grid-template-columns: 200px auto 340px;
	align-items: center;
    justify-items: center;
    
	/* Color */
    background-color: #0E5FA6;
    
   	/* Text */
   	/* Other */
}

.auto-fill {
    height: 31px;
    background-color: white;
    border-radius: 16px;
    color: black;
    font-size: 11px;
    padding-right: 25px;
    padding-left: 25px;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.input-box-dropdown {
    /* Positioning */
    position: relative;
    margin-bottom: 20px;
    left: -25px;
   
    /* Display & Box model */
    display: block;
    width: calc(100% - 49px);
    height: 31px;
    background-color: #ffffff00;
    border-radius: 16px;

    /* Text */
    color: black;
    font-size: 11px;
    padding-right: 25px;
    padding-left: 25px;
    letter-spacing: 1px;

    /* Other */
    z-index: 2;
}

.input-box-placeholder-dropdown {
    /* Positioning */
    position: absolute;
    margin-bottom: -31px;
   
    /* Display & Box model */
    display: block;

     /* width: calc(100% - 10px); */
    height: 31px;
    width: 57%;
    background-color: white;
    border-radius: 16px;

    /* Text */
    text-align: left;
    letter-spacing: 1px; 
}

.placeholder-top-dropdown {
    /* Positioning */
    position: absolute;
    margin-top: -25px;

    /* Text */
    font-size: 12px !important;
    text-align: left;

    /* Other */
    z-index: 3;
}

.placeholder-text-dropdown {
    /* Positioning */
    padding-top: 7px;

    /* Text */
    color: black;
    font-size: 11px;
}

.input-white-background-dropdown {
    /* Positioning */
    left: -25px;
}

#postCode {
    height: 31px;
    width: 100%;
    letter-spacing: 1px;
    font-size: 12px;
    font-family: Segoe UI, sans-serif;
}

.choose-company-subtitle {
    /* Display & box model */
    margin-bottom: 40px;

    /* Text */
    text-align: center;
    font-size: 18px;
    color: var( --default-blue-color );  
}

.ok-message {
    /* Display & box model */
    grid-column: 1 / 4;
    margin-bottom: 40px;

    /* Text */
    text-align: center;
    font-size: 18px;
    color: var( --default-blue-color );  
}

.step-content .user-companies {
    /* Display & box model */
    grid-column: 1/4
}

.company {
    /* positioning */
    margin-bottom: 10px;

    /* Display & box model */
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 3px 3px 10px grey;

    /* Text */
    text-align: left;
    padding-left: 50px;
    font-size: 15px;

    /* Other */
    cursor: pointer;
}

.company:hover {
    /* Display & box model */
    background-color: var( --default-orange-color );
}

.company-row {
    /* Display & box model */
    padding-top: 3px;
    padding-bottom: 3px;
    display: block;
    width: 100%;
}


.content-registration {
    /* Position*/
    position: absolute;
    top: 70px;
    bottom: 0px;

    /* Display & box model */
    width: 100%;

    /* Other */
    overflow-y: auto;
    z-index: -3;
}

.registration-container {
    /* Positioning */
    position: relative;
    top: 0px;
    bottom: 0px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    grid-template-rows: 78px 92px auto-fill auto;
    min-height: 100%;
    width:  100%;

    /* Color */
    background-color: #ececec;
}

.title {
    /* Positioning */
    padding-top: 40px;

    /* Display & Box model */
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    /* Text */
    color: #0a60a6;
    font-size: 25pt;
    text-align: center;
}

.provider-mode-registration {
    /* Positioning */
    padding-top: 20px;
    padding-bottom: 20px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    grid-column-gap: 30px;

    /* Text */
    text-align: center;
}

.button-registration-mode-inactive {
    /* Positioning */
    justify-self: center;

    /* Display & Box model */
    padding-top: 10px;
    height: 35px;
    width: 85%;
    border-radius: 0px;
    background-color: var( --deafult-dark-grey-color );

    /* Other */
    cursor: pointer;

}

.button-registration-mode-active,
.button-registration-mode-inactive:hover {
    /* Positioning */
    justify-self: center;

    /* Display & Box model */
    padding-top: 10px;
    height: 35px;
    width: 85%;
    border-radius: 0px;
    background-color: var( --default-orange-color );

     /* Text */
     color: var( --default-blue-color );
     
     /* Other */
     cursor: pointer;

}

.step-content {
    /* Position */
    position: relative;
    /*padding-top: 40px;*/

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    width: 100%;
    /* Text */
    text-align: center;
}

.form {
    /* Display & Box model */
    grid-column: 2 / 3; 
}

.buttons-container {
    /* Display & Box model */
    grid-column: 2 / 3;
    grid-row: 4 / 5;

    /* Text */
    text-align: center;
}

.disabled {
     /* Position */
     margin: 10px;

     /* Display & Box model */
    display: inline-block;
    height: 70px;
    width: 25%;
    background-color: grey;

    /* Text */
    color: gray;
}

/* Targeting Edge */
@supports (-ms-ime-align: auto) {
    @media only screen and (max-width: 700px) {
        .registration-container { 
            /* Display & Box model */
            grid-template-rows: 207px 287px auto auto;
        }
    }

}

@media only screen and (max-width: 700px) {
    /* .registration-container {
        /* Display & Box model */
        /*grid-template-rows: 207px 287px auto auto;
    } */

    .form {
        /* Display & Box model */
        grid-column: 1 / 2; 
    }

    .step-content {
        /* Display & Box model */
        grid-template-columns: 1fr;
    }

    .content-registration .input-box-placeholder {
        /* Display & Box model */
        width: 96%;
    }

    .button-registration-mode-inactive {
        /* Positioning */
        justify-self: center;
    
        /* Display & Box model */
        padding-top: 10px;
        padding-bottom: 10px;
        height: 60px;
    }
    
    .button-registration-mode-active,
    .button-registration-mode-inactive:hover {
        /* Positioning */
        justify-self: center;
    
        /* Display & Box model */
        padding-top: 10px;
        padding-bottom: 10px;
        height: 60px;

    }
}

#postcode {
    height: 31px;
    width: 100%;
    letter-spacing: 1px;
    font-size: 12px;
    font-family: Segoe UI, sans-serif;
}