.btn-hub {
    /* Positioning */
    position: relative;
    height: 30px;
    width: 30px;
    /* Display & Box model */
    grid-area: btn-hub;
    border-radius: 0 20px 20px 0;

    /* Color */
    background-color: #f8a800;
    /* Text */

    /* Other */
    cursor: pointer;
}

#white-arrow {
    /* Positioning */
    position: absolute;
    height: 10px;
    width: 10px;
    top: 8px;
    left: 8px;
    /* Display & Box model */
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    /* Color */

    /* Text */

    /* Other */
    transform: rotate( 135deg );
}

.white-arrow-right {
    /* Positioning */
    left: 4px !important;
    /* Display & Box model */
    
    /* Color */

    /* Text */

    /* Other */
    transform: rotate( -45deg ) !important;
}

.white-arrow-left {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */

    /* Text */

    /* Other */
}