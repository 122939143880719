.shopping-cart-summary-main-container {
     /* Display & Box model */
     display: grid;
     grid-template-rows: auto auto 195px auto;

}

.delivery-summary-title {
    /* Display & Box model */
    grid-row: 1 / 2;
    padding-bottom: 15px;

    /* Text */
    color: #0E5FA6;
    font-family: 'Segoe UI Bold';
    font-size: 16px; 
    font-weight: bold;
}

.payment-guarantee-title {
    /* Display & Box model */
    grid-row: 7 / 8;
    padding-bottom: 15px;

    /* Text */
    color: #0E5FA6;
    font-family: 'Segoe UI Bold';
    font-size: 16px; 
    font-weight: bold;
}

.delivery-summary-form {
    /* Display & Box model */
    padding: 60px 25px 10px 25px;
    display: grid;
    grid-template-rows: 1fr auto auto auto auto;
    grid-row: 2 / 3;
    background-color: #ebebeb;
}

.product-list {
    /* Display & Box model */
    grid-row: 1 / 2;
    padding-bottom: 40px;
}

.quantity {
    /* Text*/
    color: grey;
    font-size: 14px;
}

.provider-name {
    /* Text*/
    letter-spacing: 1px;
}

.quantity,
.provider-name,
.product-quantity-weight {
    /* Text*/
    color: grey;
}

.choosen-product-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 20px 40% 20% 80px 70px;
    grid-row: 1 / 2;
    grid-column-gap: 7px;
    width: 70%;
}

.choosen-product-name {
    /* Font */
    color: #0E5FA6;
    font-size: 14px; 
    font-weight: bold;
}

.delivery-date {
    /* Display & Box model */
    grid-row: 2 / 3;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 2px dotted #d6d6d6;
    border-bottom: 2px dotted #d6d6d6;

    /* Text */
    letter-spacing: 1px;
}

.delivery-cost {
    /* Positioning */
    justify-self: right;

    /* Display & Box model */
    padding-top: 10px;
    padding-bottom: 10px;
    width: 50%;
    border-bottom: 2px dotted #d6d6d6;
}

.delivery-type-hub {
    /* Display & Box model */
    grid-column: 1 / 2;

    /* Text */
    color:#565655;
    letter-spacing: 2px;
}
.delivery-text {
    /* Positioning */
    justify-self: right;

    /* Display & Box model */
    padding-top: 20px;
    padding-bottom: 20px;
    width: 50%;
    grid-column: 1 / 2;

    /* Text */
    color:#565655;
    letter-spacing: 2px;
}

.delivery-price {
    /* Display & Box model */
    grid-column: 2 / 3;

    /* Text */
    text-align: right;
    color:#565655;
    letter-spacing: 2px;
}

.delivery-cost-container {
    /* Display & Box model */
    display: grid;
    grid-row: 4 / 5;
}

.delivery-total-container {
    /* Positioning */
    justify-self: end;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-row: 5 / 6;
    padding-top: 20px;
    padding-bottom: 40px;
    width: 50%;

    /* Text */
    font-size: 14px; 
}

.delivery-type-text,
.delivery-no-tax-text,
.delivery-total {
    /* Display & Box model */
    padding: 5px 5px 5px 0px;

    /* Text */
    color: #565655;
    letter-spacing: 2px;
}

.delivery-type {
    /* Display & Box model */
    padding: 5px 5px 5px 0px;

    /* Text */
    color: grey;
    font-size: 14px;
    text-align: right;
}

.delivery-no-tax-text {
    /* Display & Box model */
    border-top: 1px solid #ababab;
    border-bottom: 1px solid #ababab;
}

.delivery-no-tax-value {
    /* Display & Box model */
    padding: 5px 0px 5px 5px;
    border-top: 1px solid #ababab;
    border-bottom: 1px solid #ababab;

    /* Text */
    color: #565655;
    font-size: 14px;
    letter-spacing: 2px;
    text-align: right;
}

.delivery-total-value {
    /* Display & Box model */
    padding: 5px 0px 5px 5px;

    /* Text */
    color: #565655;
    font-size: 14px;
    letter-spacing: 2px;
    text-align: right;
}

.separator {
    /* Display & Box model */
    grid-row: 6 / 7;
    border-bottom: 2px dotted #d6d6d6;
}

.delivery-data {
    /* Positioning */
    justify-self: end;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 150px 150px 150px 150px;
    grid-row: 7 / 8;
    padding-top: 40px;
}

.address-to-deliver-text,
.account-data-text {
    /* Display & Box model */
    padding: 3px 10px 0px 0px;

    /* Text */
    letter-spacing: 1px;
} 

.payment-guarantee {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row: 8 / 9;
    height: 221px;
    background-color: #838383;
}

.button-next-step {
    /* Positioning */
    align-self: center;
    justify-self: end;

     /* Display & Box model */
     grid-row: 3 / 4;
     width: fit-content;
     height: fit-content; 

     /* Text */
     color: white;
     font-weight: bold;
     text-align: center;
     text-transform: uppercase;
}

.button {
    /* Display & Box model */
    width: 264px; 
}

.delivery-hub {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.fee-cost-container{
    display: grid;
    grid-row: 3 / 4;
}

@media only screen and (max-width: 400px) {
    .delivery-summary-form {
        /* Display & Box model */
        grid-template-rows: auto auto 230px 2px 1fr;
    }
    .choosen-product-container {
        /* Positioning */
        margin-bottom: 20px;

        /* Display & Box model */
        grid-template-columns: 1fr 5fr 5fr;
        width: 100%;
    }

    .quantity {
        /* Display & Box model */
        grid-row: 1/2;
        grid-column: 1/2;
        padding: 5px;
    }

    .choosen-product-name {
        /* Display & Box model */
        grid-row: 1/2;
        grid-column: 2/3;
        padding: 5px;
    }

    .provider-name {
        /* Display & Box model */
        grid-row: 1/2;
        grid-column: 3/4;
        padding: 5px;
    }

    .product-quantity-weight {
        /* Display & Box model */
        grid-row: 2/3;
        grid-column: 2/3;
        padding: 5px;
    }

    .product-price {
        /* Display & Box model */
        grid-row: 2/3;
        grid-column: 3/4;
        padding: 5px;

        /* Text */
        text-align: right !important;
    }
}

@media only screen and (max-width: 700px) {
    .delivery-summary-form {
        /* Display & Box model */
        grid-template-rows: 1fr auto auto auto auto;
    }
    .choosen-product-container {
        /* Positioning */
        margin-bottom: 20px;

        /* Display & Box model */
        width: 100%;
    }

    .delivery-total-container {
        /* Positioning */
        justify-self: start;

        /* Display & Box model */
        width: 100%;
        grid-template-rows: repeat(3, 67px);
        grid-template-columns: 2fr 1fr;
    }

    .delivery-data {
        /* Positioning */
        justify-self: start;

        /* Display & Box model */
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 30px 130px 30px 130px;
        padding-top: 40px;
    }

    .delivery-summary-title,
    .payment-guarantee-title {
        /* Display & Box model */
        padding-left: 15px;
    }
}