.TableHubCycleHeader-container span {
    /* Display & Box model */
    display: inline-block;
}

.TableHubCycleHeader-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    /* Text */
    color: var( --deafult-dark-grey-color );
    letter-spacing: 1px;
    font-size: 12px;
}

.TableHubCycleHeader-container .icon-slider-arroow-06::before {
    /* Display & Box model */
    width: 12px !important;
    height: 12px !important;

    /* Text */
    color: var( --deafult-dark-grey-color );
}

.TableHubCycleHeader-container .sort-arrow-up  {
   /* Display & Box model */
   width: 21px;
   height: 10px;

   /* Other */
   transform: rotate(270deg);
}

.TableHubCycleHeader-container .sort-arrow-down {
    /* Display & Box model */
    width: 20px;
    height: 28px;

    /* Other */
    transform: rotate(90deg);
}