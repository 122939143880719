.gridPackages-item-header {
    /* Positioning */
    text-align: left;

    /* Display & Box model */
    padding: 3px;
    
    /* Color */
    color: var( --deafult-dark-grey-color );

    /* Text */
    font-size: 12px;

    /* Other */
    cursor: pointer;
}

.hub-packages-header {
    /* Positioning */
    margin-top: 5px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    justify-content: center;

    /* Color */
    
    /* Text */

    /* Other */
}

.hub-packages-header .icon-slider-arroow-06::before {
    /* Display & Box model */
    width: 12px !important;
    height: 12px !important;

    /* Text */
    color: var( --deafult-dark-grey-color );
}

.hub-packages-header .sort-arrow-up  {
   /* Display & Box model */
   width: 21px;
   height: 10px;
   transform: rotate(270deg);
}

.hub-packages-header .sort-arrow-down {
    /* Display & Box model */
    width: 20px;
    height: 28px;
    transform: rotate(90deg);
}
.hub-packages-header span {
    /* Display & Box model */
    display: inline-block;
}