
.shopping-row {
    /* Positioning */
    margin-bottom: 30px;
    margin-left: 15px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 45px calc( 100% - 90px ) 45px;
    grid-template-rows: 22px 365px;
    grid-template-areas: ". row-title ." "arrow-left slider arrow-right";

    /* Color */

    /* Text */

    /* Other */
}

.shopping-row-title {
    /* Positioning */
    
    /* Display & Box model */
    grid-area: row-title;
    justify-self: center;

    /* Color */
    color: #0060a2;

    /* Text */
    font-family: 'Segoe UI Bold';
    font-size: 14px;
    letter-spacing: 1px;

    /* Other */
}

.arrow-left {
    grid-area: arrow-left;
    position: relative;
}
.slider {
    grid-area: slider;
    /* width: 100%; */
    background-image: linear-gradient(to right, #333 20%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.arrow-right {
    grid-area: arrow-right;
    position: relative;
}

#btn-swiper-prev {
    /* Positioning */
    position: absolute;
    top: calc( 50% - 20px );
    left: 10px;
    width: 30px;
    height: 30px;

    /* Display & Box model */
    border-top: 4px solid #0060a2;
    border-left: 4px solid #0060a2;

    /* Color */

    /* Text */

    /* Other */
    cursor: pointer;
    transform: rotate( -45deg );
}

#btn-swiper-prev:hover {
    border-top: 4px solid aqua;
    border-left: 4px solid aqua;
}

#btn-swiper-next {
     /* Positioning */
    position: absolute;
    top: calc( 50% - 20px );
    right: 10px;
    width: 30px;
    height: 30px;

     /* Display & Box model */
    border-top: 4px solid #0060a2;
    border-right: 4px solid #0060a2;

     /* Color */

     /* Text */

     /* Other */
    cursor: pointer;
    transform: rotate( 45deg );
}

#btn-swiper-next:hover {
    border-top: 4px solid aqua;
    border-right: 4px solid aqua;
}

.item-holder {
    /* Positioning */
    height: 330px;
    
    /* Display & Box model */
    
    /* Color */

    /* Text */

    /* Other */
}

@media only screen and (max-width: 700px) {
    .shopping-row {
        /* Positioning */
        margin-left: 0px;
    }
}