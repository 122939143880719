
.image-slider-row {
    /* Positioning */
    margin-bottom: 30px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 45px calc( 100% - 90px ) 45px;
    grid-template-rows: 400px;

    /* Color */

    /* Text */

    /* Other */

}

.image-slider-row .swiper-container {
    /* Display & Box model */
    max-width: 450px;
    height: 400px;

    /* Text */
    text-align: center;
}

.image-slider-row .swiper-pagination-bullet {
    /* Display & Box model */
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    background: var( --default-blue-color );
    opacity: 1;
}

.image-slider-row .swiper-pagination-bullet-active {
    /* Display & Box model */
    background: var( --default-orange-color );
}

.image-slider-shopping-row-title {
    /* Positioning */
    
    /* Display & Box model */
    grid-area: row-title;
    justify-self: center;

    /* Color */
    color: #0060a2;

    /* Text */
    font-family: 'Segoe UI Bold';
    font-size: 14px;
    letter-spacing: 1px;

    /* Other */
}

.image-slider-arrow-left {
    /* Positioning */
    position: relative;
    margin-left: 20px;

    /* Display & Box model */
    grid-column: 1 / 2;
}
.image-slider {
    /* Positioning */
    margin-bottom: 20px;

    /* Display & Box model */
    grid-column: 2/3;
    padding-bottom: 10px;
}

.image-slider-image {
    width: 100%;
}

.image-slider-arrow-right {
    /* Positioning */
    position: relative;
    margin-right: 20px;

    /* Display & Box model */
    grid-column: 3/4;

}

#image-slider-btn-swiper-prev {
    /* Positioning */
    position: absolute;
    top: calc( 50% - 20px );
    left: 10px;
    width: 30px;
    height: 30px;

    /* Display & Box model */
    border-top: 4px solid #0060a2;
    border-left: 4px solid #0060a2;

    /* Color */

    /* Text */

    /* Other */
    cursor: pointer;
    transform: rotate( -45deg );
}

#image-slider-btn-swiper-prev:hover {
    border-top: 4px solid aqua;
    border-left: 4px solid aqua;
}

#image-slider-btn-swiper-next {
     /* Positioning */
    position: absolute;
    top: calc( 50% - 20px );
    right: 10px;
    width: 30px;
    height: 30px;

     /* Display & Box model */
    border-top: 4px solid #0060a2;
    border-right: 4px solid #0060a2;

     /* Color */

     /* Text */

     /* Other */
    cursor: pointer;
    transform: rotate( 45deg );
}

#image-slider-btn-swiper-next:hover {
    border-top: 4px solid aqua;
    border-right: 4px solid aqua;
}

.image-slider-item-holder {
    /* Positioning */
    height: 330px;
    
    /* Display & Box model */
    
    /* Color */

    /* Text */

    /* Other */
}

/*@media only screen and (max-width: 700px) {
    .image-slider-shopping-row {
        /* Positioning */
       /* margin-left: 0px;
    }
}*/

@media screen and (max-width: 1400px) and (min-width: 320px) {
    .image-slider-row .swiper-container {
        /* Display & Box model */
        max-width: 220px;
        height: 250px;
    
        /* Text */
        text-align: center;
    }
    .image-slider-image {
        width:100%;
    }
}