.popupTenant-main-container {
    /* Positioning */
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;

    /* Display & Box model */
    background-color: rgba( 0,0,0, 0.3);
    /* Text */
    color: var( --default-orange-color );

    /* Other */
    z-index: 2;
}

.popupTenant-main-container .popup-window {
    /* Display & Box model */
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    width: 30%;
    display: grid;
    grid-template-rows: min-content auto min-content;
    grid-gap: 0px;
}

.popup-window-title {
    font-size: 24px;
    color: var( --default-blue-color );
    text-align: center;
    margin: 15px 0 20px 0;
}

.popup-window-input-container {
    justify-self: center;
}

.popup-windows-close-btn {
    justify-self: center;
}

#input-btn {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    justify-self: center;
    align-self: center;
}

.popup-window .button {
    /* Positioning */
    justify-self: center;

    /* Display & Box model */
    width: 120px;
}

.popupTenant-main-container .popup-buttons {
    /* Display & Box model */
    display: block;

    /* Text */
    text-align: center;
}

.popup-select {
    /* Positioning */
    margin-top: 20px;
    margin-bottom: 40px;

    /* Display & Box model */
    width: 350px;
    height: 30px;
    background-color: var( --deafult-grey-color );
}

option {
    /* Display & Box model */
    background-color: white;

    /* Text */
    font-size: 14px;
    font-family: 'Segoe UI';
}
.popup-window-dropdown-container {
    justify-self: center;
}
