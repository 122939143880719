.TableHubCycleOrderHeader-container span {
    /* Display & Box model */
    display: inline-block;
}

.TableHubCycleOrderHeader-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 120px repeat(11, calc( calc( 100% / 12 ) - 6px) );
    column-gap: 3px;

    /* Text */
    color: var( --deafult-dark-grey-color );
    letter-spacing: 1px;
    font-size: 12px;
}

.TableHubCycleOrderHeader-container .icon-slider-arroow-06::before {
    /* Display & Box model */
    width: 12px !important;
    height: 12px !important;

    /* Text */
    color: var( --deafult-dark-grey-color );
}

.TableHubCycleOrderHeader-container .sort-arrow-up  {
   /* Display & Box model */
   width: 13px;
   height: 19px;
   transform: rotate(270deg);
}

.TableHubCycleOrderHeader-container .sort-arrow-down {
    /* Display & Box model */
    width: 13px;
    height: 19px;

    /* Other */
    transform: rotate(90deg);
}

.code-hub-orders,
.aggregation-hub-orders,
.product-hub-orders,
.provider-hub-orders,
.header-quantity-hub-orders,
.unit-hub-orders,
.header-price-hub-orders,
.delivery-hub-orders,
.packaging-hub-orders,
.deliverability-hub-orders,
.status-hub-orders,
.storageLocation-hub-orders {
    /* Display & Box model */
    display: grid;
    grid-template-columns: calc( 100% - 19px ) 19px;
    column-gap: 3px;
    background-color: #f0efef;

    /* Text */
    font-size: 12px;
}

.code-text-hub-orders,
.aggregation-text-hub-orders,
.product-text-hub-orders,
.provider-text-hub-orders,
.header-quantity-text-hub-orders,
.unit-text-hub-orders,
.header-price-text-hub-orders,
.delivery-text2-hub-orders,
.packaging-text-hub-orders,
.deliverability-text-hub-orders,
.status-text2-hub-orders,
.storageLocation-text-hub-orders {
    /* Display & Box model */
   grid-column: 1 / 2;

    /* Text */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    font-size: 12px;
}