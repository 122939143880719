#categories-menu-container {
    /* Positioning */
    width: 100%;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 32px 1fr 1fr 1fr 1fr 1fr 1fr auto max-content;
    justify-items: center;
    align-items: center;
    border-bottom: 1px solid grey;

    /* Color */
    background-color: white;

    /* Text */
    font-size: 14px;
    /* Other */
}

#category-dropdown {
    /* Positioning */
    width: 100%;
    height: 100%;
    position: relative;

    /* Display & Box model */
    display: inline-block;
}

#appsIcon {
    justify-self: start;
    background-color: #ffa731;
    border-right: 1px solid #ffa731;
}

.login-register-container {
    /* Positioning */
    justify-self: end;
    padding-right: 50px;

    /* Display & Box model */
    
    /* Color */

    /* Text */
    text-transform: uppercase;
    font-family: 'Segoe UI Semilight';
    font-size: 14px;
    letter-spacing: 1px;

    /* Other */
    cursor: pointer;
}

.authenticated-container {
    /* Positioning */
    justify-self: end;
    height: 100%;
    line-height: 32px;
    padding-right: 50px;
    padding-left: 50px;

    /* Display & Box model */
    border-left: 2px solid grey;
    /* Color */

    /* Text */
    font-family: 'Segoe UI Semilight';
    font-size: 14px;
    letter-spacing: 1px;

    /* Other */
    cursor: default;
}

.btn-category {
    /* Positioning */
    height: 100%;
    width: 100%;
    
    /* Display & Box model */
    
    /* Color */

    /* Text */
    font-family: 'Segoe UI Semilight';
    font-size: 14px;
    letter-spacing: 1px;

    /* Other */
}

.btn-category:hover {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    background-color: #ececec;
    /* Text */

    /* Other */
    cursor: pointer;
}

@media only screen and (max-width: 1024px) {
    .login-register-container {
        /* Positioning */
        padding-right: 10px !important;

        /* Display & Box model */
        
        /* Color */
    
        /* Text */
        font-size: 10px !important;

        /* Other */
    }

    .authenticated-container {
        /* Positioning */
        padding-right: 10px !important;
        padding-left: 10px !important;

        /* Display & Box model */
        
        /* Color */
    
        /* Text */
        font-size: 10px !important;
    
        /* Other */
        cursor: default !important;
    }

    .btn-category {
        /* Positioning */
        
        /* Display & Box model */
        
        /* Color */
    
        /* Text */
        font-size: 11px;
        
        /* Other */
    }
}

.categories-menu-overlay {
	/* Positioning */
    position: fixed;
    top: 70px;
    bottom: 0;
    right: 0;
    left: 0;

    /* Display & Box model */
    height: 35px;
    display: grid;
    background-color: rgba( 0,0,0,0.3);

    /* Other */
    z-index: 1;
}