:root {
    --item-grid-columns: 4;
}
@media only screen and ( max-width: 1080px ) {
    :root {
        --item-grid-columns: 3; 
    }
}
@media only screen and ( max-width: 760px ) {
    :root {
        --item-grid-columns: 2;
    }
}
@media only screen and ( max-width: 640px ) {
    :root {
        --item-grid-columns: 2;
    }
}
@media only screen and ( max-width: 480px ) {
    :root {
        --item-grid-columns: 1;
    }
}

#shopping-list-items-grid {
    /* Positioning */
    padding: 10px 0 0 20px;
    
    /* Display & Box model */
    display: grid;
    grid-template-columns: repeat( var( --item-grid-columns ), 1fr );

    /* Color */
    
    /* Text */

    /* Other */
}