.filter-code-container {
    position: absolute;
    top: 0px;
    left: -24px;

    width: 250px;
    height: 350px;
    border: 1px solid #7e8182;
    background-color: white;
    box-shadow: 0 0 5px rgba(126,129,130,0.65);

    z-index: 6;
    cursor: default;
}

.arrow {
    position: absolute;
    top: -5px;
    left: 20px;

    width: 15px;
    height: 15px;
    border: 1px solid #7e8182;
    background-color: white;
    box-shadow: 0 0 5px rgba(126,129,130,0.65);

    transform: rotate(45deg);
    z-index: -1;
}

.filter-content {
    padding: 12px;
    background-color: white;

    font-size: 14px;
    font-weight: 500;

    z-index: 6;
}

.header {
    height: 33px;

    color: grey;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.bottom-line {
    border-bottom: 1px solid var( --deafult-dark-grey-color );
}

.search-bar {
    display: grid;
    padding: 5px;
    grid-template-columns: 22px 173px 19px;
    width: 218px;
    background-color: var( --deafult-grey-color );
    border-radius: 20px;
}

.search-bar .icon-baseline-clear-24px {
    color: inherit;

    cursor: pointer;
}

.search-bar .icon-search-icon-05 {
    color: inherit !important;
}

.content-list {
    padding-top: 10px;
    height: 232px !important;
    overflow: auto;
}

.filter-status,
.filter-logistic {

    height: 282px !important;
}

.filter-content-row {
    margin-bottom: 20px;

    display: grid;
    grid-template-columns: 1fr 32px;
    grid-column-gap: 10px;

    cursor: pointer;
}

.code-row {
    margin-bottom: 11px !important;
}

.row-text {
    grid-column: 1 / 2;

    color: black;
}

.filter-checkbox {
    grid-column: 2 / 3;
}

.filter-checkbox .container {
    padding-left: 0px;
}

.filter-checkbox .container .checkmark::after {
    left: 7px;
    top: 0;

    width: 5px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;

    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
}

.filter-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;

    height: 20px;
    width: 20px;
    border: 1px solid grey;
    background-color: white;
}

.filter-checkbox .container input:checked ~ .checkmark {
    background-color: grey;
}

.button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.ok-button,
.cancel-button {
    justify-self: center
}

.button-container .button {
    margin: 0px;
    padding-top: 0px;

    width: 100px
}

.visible {
    position: absolute;

    visibility: visible;
}

.hidden {
    position: absolute;

    visibility: hidden;
}

.filter-date-container {
    position: absolute;
    top: 0px;
    left: -24px;

    width: 200px;
    height: 290px;
    border: 1px solid #7e8182;
    background-color: white;
    letter-spacing: 0px;
    box-shadow: 0 0 5px rgba(126,129,130,0.65);

    letter-spacing: 0px;

    z-index: 6;
}

.filter-date-arrow {
    position: absolute;
    top: -5px;
    left: 20px;

    width: 15px;
    height: 15px;
    border: 1px solid #7e8182;
    background-color: white;
    box-shadow: 0 0 5px rgba(126,129,130,0.65);

    transform: rotate(45deg);
    z-index: -1;
}

.filter-date-header {
    height: 50px;
    padding-left: 15px;

    color: grey;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    letter-spacing: 1px;
}

.filter-date-content {
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: white;

    font-size: 14px;
    font-weight: 500;
    text-align: center;

    z-index: 6;
}

.filter-date-textStatus {
    padding-top: 6px;

    font-weight: bold;
}

.filter-date-buttonsContainer {
    position: absolute;
    bottom: 12px;

    width: 94.2%;

    text-align: center;
}

.filter-date-buttonsContainerLeft {
    width: auto;

    text-align: left;
}

.filter-date-buttonsContainerRight {
    width: auto;

    text-align: right;
}

.filter-date-date {
    margin-top: 25px;

    height: 30px;

    color: #7e8182;
}

@media only screen and (max-width: 1024px) {
    @media only screen and (orientation: portrait) {
        .filter-code-container {
            display: hidden;
        }
    }

    @media only screen and (orientation: landscape) {
        .filter-code-container {
            display: hidden;
        }
    }
}