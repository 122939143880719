.cyclePopup-main-container {
    /* Positioning */
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: auto;

    /* Display & Box model */
    background-color: rgba( 0,0,0, 0.3);
    /* Text */
    color: var( --default-orange-color );

    /* Other */
    z-index: 2;
}

.cyclePopup-main-container .popup-window {
    /* Display & Box model */
    padding: 40px;
    background-color: #fff;
    border-radius: 20px;
    display: grid;
    width: 600px;
    height: 200px;
    grid-template-rows: min-content auto min-content;
    grid-gap: 20px;
}

.cyclePopup-main-container .popup-window .popup-window-title {
    font-size: 24px;
    color: var( --default-blue-color );
    text-align: center;
    margin: 0 0 10px 0;
    grid-column: 1/3;

}

.popup-window-input-container {
    justify-self: center;

}

.popup-windows-close-btn {
    justify-self: center;
}

.popup-windows-close-btn .button{
    padding-top: 5px;
}

#input-btn {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 8px 12px;
    cursor: pointer;
    justify-self: center;
    align-self: center;

    /*visibility: hidden;*/
}

.popup-window .button {
    /* Positioning */
    justify-self: center;

    /* Display & Box model */
    width: 140px;
}
/*test*/
.cyclePopup-main-container .popup-buttons {
    /* Display & Box model */
    display: block;

    /* Text */
    text-align: center;
}
.cyclePopup-main-container input {
    width: 150px;
    line-height: 24px;
    border: 1px solid var( --default-orange-color );
    background-color: grey;
    text-align: center;
    color: white;
    cursor: pointer;
    margin-left: 10px;
}

.cyclePopup-main-container .popup-window .buttons-container {
    height: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 1;
    grid-row: 3;
    text-align: center;

}

.hub-view-cycle-dropdown {
    margin-left: 10px;
}

.cyclePopup-main-container .popup-window .inputs-container {
    
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.datePicker-hub-cycle{
    align-self: center;
    justify-self: center;
}

.cyclePopup-main-container .popup-window .buttons-container .button{
    margin-right: 0px !important;
    align-self: center;
    justify-self: center;
}


