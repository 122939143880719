.shopping-filter-item {
    /* Positioning */

    /* Display & Box model */
    display: grid;
    grid-template-columns: 20px min-content 30px;
    justify-items: center;
    align-items: center;
    /* Color */

    /* Text */

    /* Other */
    cursor: pointer;
}

#filter-item-name {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */

    /* Text */
    font-family: 'Segoe UI';
    font-size: 14px;
    letter-spacing: 1px;

    /* Other */
    user-select: none;
    white-space: nowrap;
}
#filter-item-name::first-letter { 
    text-transform: uppercase;
}

#filter-item-dot {
    /* Positioning */
    height: 5px;
    width: 5px;
    /* Display & Box model */
    border-radius: 50%;
    /* Color */
    background-color: #feb361;
    /* Text */

    /* Other */
    user-select: none;
}

#filter-item-remove {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */

    /* Text */

    /* Other */
    user-select: none;
}

.filter-item-disabled {
    color: #a2a2a2 !important;
}

.filter-dot-disabled {
    background-color: #a2a2a2 !important;
}