.shopping-cart-address-main-container {
     /* Display & Box model */
     display: grid;
     grid-template-rows: auto 120px 50px 40px auto;
}

.delivery-forms-container {
     /* Display & Box model */
     display: grid;
     grid-template-rows: 40px auto;
     grid-auto-columns: 1fr 1fr;
     column-gap: 50px;
}

.address-title {
    /* Display & Box model */
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    padding-bottom: 15px;

    /* Text */
    color: #0E5FA6;
    font-family: 'Segoe UI Bold';
    font-size: 16px; 
    font-weight: bold;
}

.address-form-delivery,
.address-form-account {
    /* Display & Box model */
    padding: 30px 25px 10px 25px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-row: 2 / 3;
    background-color: #ebebeb;
}

.name {
    /* Display & Box model */
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    padding: 4px 0px 4px 0px;

    font-weight: bold;
    letter-spacing: 2px;

}

.address {
    /* Display & Box model */
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    padding: 4px 0px 4px 0px;

    /* Text */
    letter-spacing: 2px;
}

.zip {
    /* Display & Box model */
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    padding: 4px 0px 4px 0px;

    /* Text */
    letter-spacing: 2px;
}
 
.telephone {
    /* Display & Box model */
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    padding: 4px 0px 4px 0px;
    /* Text */
    letter-spacing: 2px;
}

.add-new-address {
    /* Positioning */
    align-self: end;
    
    /* Display & Box model */
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    /* Text */
    color: #0E5FA6;
    font-family: 'Segoe UI Bold', sans-serif;
    font-size: 14px;
    font-weight: bold; 
    text-align: right;
    text-transform: uppercase;

    /* Other */
    cursor: pointer;
}

.delivery-title {
    /* Display & Box model */
    grid-row: 4 / 5;
    padding-bottom: 15px;

    /* Text */
    color: #0E5FA6;
    font-family: 'Segoe UI Bold';
    font-size: 16px; 
    font-weight: bold;
}


.choose-address {
    /* Positioning */
    justify-self: end;
    align-self: end;
    
    /* Display & Box model */
    grid-column: 2 / 3;
    grid-row: 4 / 5;
    width: fit-content;
    height: fit-content;

    /* Text */
    color: #0E5FA6;
    font-family: 'Segoe UI Bold', sans-serif;
    font-size: 14px;
    font-weight: bold; 
    text-align: right;
    text-transform: uppercase;

    /* Other */
    cursor: pointer;
}

.address-title-account {
    /* Display & Box model */
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    /* Text */
    color: #0E5FA6;
    font-family: 'Segoe UI Bold';
    font-size: 16px; 
    font-weight: bold;
}

.delivery-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row: 5 / 6;
    height: 221px;
    background-color: #838383;
}

.button-next-step {
    /* Positioning */
    align-self: center;
    justify-self: end;

     /* Display & Box model */
     grid-row: 3 / 4;
     width: fit-content;
     height: fit-content;

     /* Text */
     color: white;
     font-weight: bold;
     text-align: center;
     text-transform: uppercase;
}

@media only screen and (max-width: 700px) {
    .shopping-cart-address-main-container {
        /* Display & Box model */
        display: grid;
        grid-template-rows: auto 30px 80px 40px auto;
    }

	.delivery-forms-container {
        /* Display & Box model */
        display: block;
    }

   .address-title {
        /* Display & Box model */
        grid-row: 1 / 2;
        padding-left: 15px;
    }

    .address-form-delivery {
        /* Positioning */
        margin-bottom: 40px;

        /* Display & Box model */
        grid-row: 2 / 3;
    }

    .address-title-account {
        /* Display & Box model */
        grid-row: 3 / 4;
        grid-column: 1 / 2;
        padding-left: 15px;
        padding-bottom: 15px;
    }

    .address-form-account {
        /* Display & Box model */
        grid-row: 4 / 5;
    }

    .delivery-title {
        /* Display & Box model */
        padding-left: 15px;
    }

    .button-next-step {
        /* Positioning */
        margin: auto;
    
        /* Display & Box model */
         width: 100%;
    }
    
    .button {
        /* Positioning */
        margin: auto auto 5px auto;
        
        /* Display & Box model */
        width: 264px; 
    }
}