
.container input {
    /* Positioning */
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.container {
    /* Positioning */
    padding-left: 40px !important;
    font-size: 15px !important;
}

.choose-user-dropdown {
    /* Positioning */
    position: relative;

    /* Display & Box model */
    display: inline-block;
    align-self: center;
}

.choose-user-dropdown-content { 
    /* Positioning */
    width: 100%;
    
    position: absolute;

    /* Color */
    background-color: #f9f9f9;

    /* Other */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);  
    z-index: 1;
}

.dropdown-title {
    /* Positioning */
    height: 35px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 5px;
    align-content: center;   
    align-self: center;

    /* Other */
    border: 1px solid grey;
    border-radius: 10px;
}


#choose-user-arrow {
    /* Positioning */
    margin-right: 5px;
    
    /* Display & Box model */
    align-self: center;
}

#choose-user-text {
     /* Positioning */
    margin-left: 5px;

    /* Text */
    font-weight: normal !important;
}

.user-info-dropdown:hover {
    /* Color */
    background-color: var( --default-orange-color );
}

.user-info-text{
    /* Positioning */
    margin-left: 10px;
}

#update-user-container-box{
    display: grid;
}

@media only screen and (max-width: 600px) {
    .choose-user-dropdown{
        padding-bottom: 10px;
    }

    .user-profile-container{
        grid-column: 1;
        grid-template-columns: auto;
    }

    .name-settings{
        text-align: left;
    }

    .user-info-dropdown:hover {
        /* Color */
        background-color: var( --default-orange-color );
    }

} 
