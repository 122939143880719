#hub-view-container {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "hub-view-picture-slider hub-view-hub-conatent";

    /* Color */

    /* Text */

    /* Other */
}

.hub-view-hub-title {
    /* Positioning */
    margin-right: 2px;

    /* Display & Box model */
    
    /* Color */

    /* Text */
    font-size: 15px;
    color: var( --default-blue-color );

    /* Other */
}

.hub-view-hub-title-editable {
    /* Positioning */

    /* Display & Box model */
    
    /* Color */

    /* Text */
    font-family: 'Segoe UI Semibold' !important;
    font-size: 16px !important;
    color: var( --default-blue-color );

    /* Other */
}

#hub-view-container .gallery-button {
    /* Text */
    text-align: right;
}

#hub-view-container .button {
    /* Positioning */
    margin-right: 60px;

    /* Display & Box model */
    width: 217px;

    /* Text */
    font-weight: bold;
}

@media only screen and (max-width: 1024px) { 
    #hub-view-container {
        /* Positioning */
        
        /* Display & Box model */
        grid-template-columns: auto !important;
        grid-template-rows: 1fr 1fr !important;
        grid-template-areas: "hub-view-hub-conatent" "hub-view-picture-slider"  !important;

        /* Color */
    
        /* Text */
    
        /* Other */
    }

    #hub-view-container .gallery-button {
        /* Text */
        text-align: center;
    }

    #hub-view-container .button {
        /* Positioning */
        margin-right: 0px;
    }
}

#hub-view-picture-slider {
    grid-area: hub-view-picture-slider;
}

.hub-view-cycle-setup {
    /* Positioning */
    
    /* Display & Box model */

    /* Color */

    /* Text */

    /* Other */
}

.hub-view-cycle-length {
    /* Positioning */
    display: grid;
    grid-template-columns: max-content auto;
    grid-gap: 10px;

    /* Text */
    letter-spacing: 0.5px;
}

#hub-view-info {
    /* Positioning */
    
    /* Display & Box model */
    grid-area: hub-view-hub-conatent;
    display: grid;
    grid-gap: 5px;
    grid-template-rows: 41px 120px min-content 20px auto auto;
    
    /* Color */

    /* Text */

    /* Other */
}

#hub-view-first-row {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-columns: auto min-content min-content min-content;
    align-items: center;
    /* Color */

    /* Text */

    /* Other */
}

.hub-view-first-row-name {
    /* Positioning */
    padding-left: 5px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;

    /* Color */

    /* Text */

    /* Other */
    overflow: hidden;
    white-space: nowrap;
}

#hub-view-distance {
    /* Positioning */
    padding-left: 5px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: min-content auto;

    /* Color */

    /* Text */

    /* Other */
}

#hub-view-base-info {
    /* Positioning */
    margin-top: 20px;

    /* Display & Box model */
    display: grid;
    grid-template-rows: repeat(8, 41px);
    align-items: center;

    /* Color */

    /* Text */

    /* Other */
}

.hub-view-one-line {
    padding-left: 5px;
    /* height: 32px; */
    display: grid;
    grid-template-columns: 180px auto;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
}

.hub-view-delivery-days {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-columns: repeat( 7, min-content );
    grid-gap: 5px;

    /* Color */

    /* Text */

    /* Other */
}

#delivery-day-chip {
    /* Positioning */
    padding: 4px;

    /* Display & Box model */
    border-radius: 15px;
    border: 1px solid var( --default-orange-color );
    /* Color */

    /* Text */
    font-size: 12px;

    /* Other */
    cursor: pointer;
}

.delivery-day-chip-enabled {
    background-color: var( --default-orange-color );
}

.hub-view-start-cycle-button {
    /* Positioning */
    margin-top: 20px;
    line-height: 30px;
    padding: 3px;
    width: 180px;

    /* Display & Box model */
    border-radius: 5px;

    /* Color */
    background-color: var( --default-blue-color );

    /* Text */
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    color: white;
    text-align: center;

    /* Other */
    cursor: pointer;
}