.hub-packages-container {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-rows:39px 39px auto auto auto 39px;
    
    /* Color */
    
    /* Text */

    /* Other */
}

.gridPackages-title {
    /* Positioning */
    text-align: left;

    /* Display & Box model */
    grid-row: 1/2;
    
    /* Color */
    color: #0E5FA6;
    
    /* Text */
    font-size: 15px;
    font-weight: 600;

    /* Other */
}

.hub-packages-rows-main {
     /* Positioning */
    
    /* Display & Box model */
    grid-row: 4/5;
    margin-bottom: 10px;

    /* Color */
    
    /* Text */

    /* Other */
}

.hub-section-edit-button {
    /* Positioning */
    width: 180px;
    height: 30px;

    /* Display & Box model */
    border-radius: 15px;
    justify-self: center;

    /* Color */
    background-color: var( --default-blue-color );
    color: white;

    /* Text */
    font-family: 'Segoe UI Semibold';
    letter-spacing: 1px;
    text-transform: uppercase;

    /* Other */
    cursor: pointer;
}

.hub-packages-buttons {
    /* Positioning */
    
    /* Display & Box model */
    padding: 20px;
    display: grid;
    grid-row: 5/6;
    justify-content: right;
    grid-template-columns: 190px 190px 190px;

    /* Color */
    
    /* Text */

    /* Other */
}

.hub-packages-row-input {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-row: 5/6;
    grid-template-columns: calc(20%) calc(68%) calc(12%);
    justify-content: center;
    padding: 7px;

    /* Color */
    background-color: #ededed;

    /* Text */

    /* Other */
}

.gridPackages-input-box-cell{
    /* Positioning */
    
    /* Display & Box model */
    margin-top: auto;
    margin-bottom: auto;
    padding: 7px;
    text-align: left;

    /* Color */

    /* Text */

    /* Other */
}

.gridPackages-input-box{
    /* Positioning */
    
    /* Display & Box model */
    margin-top: auto;
    margin-bottom: auto;
    width: calc(100%);

    /* Color */

    /* Text */

    /* Other */
}

::placeholder{
    /* Positioning */
    
    /* Display & Box model */

    /* Color */
    color: #3b3b3b;

    /* Text */
    font-style: italic;

    /* Other */
}
.gridPackages-header {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-row: 3/4;

    /* Color */

    /* Text */

    /* Other */
}
.gridPackages-cycle {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-row: 2/3;
    grid-template-columns: 50px 70px;
    padding: 7px;

    /* Color */

    /* Text */
    text-align: center;
    font-weight: bold;
    
    /* Other */
}
.gridPackages-cycle-inputbox {
    /* Positioning */
    margin-left: 10px;
    
    /* Display & Box model */
    justify-content: center;

    /* Color */
    background-color: var( --deafult-grey-color );
    /* Text */
    
    /* Other */

}
.gridPackages-cycle-inputbox input{
    /* Positioning */
    margin-left: 3px;
    margin-bottom: auto;
    margin-top: auto;

    /* Display & Box model */
    width: calc(100%);
}