.footer-bottom-main {
     /* Display & box model */
    display: grid;
    grid-template-columns: 5fr 2fr 25fr;
    grid-column-gap: 20px;
    min-height: 80px;

    /* Color */
    background-color: #0a60a6;

    /* Text */
    color: white;
}

.logo {
    /* Positioning */
    align-self: center;
    justify-self: right;

    /* Display & box model */
    padding-left: 10px;
    max-width: 200px;
    height: 50px;

     /* Color */

     /* Text */
     line-height: 50px;
}

.contact-info {
    /* Positioning */
    align-self: center;
}

.copyright-info {
     /* Positioning */
    align-self: end;

    /* Display & box model */
    padding-bottom: 20px;

    /* Text */
    font-size: 8pt;
}

@media only screen and (max-width: 800px) { 
    .footer-bottom-main {
        /* Display & box model */
        padding: 10px;
        grid-template-columns: 1fr 1fr;
    }

    .copyright-info {
        /* Positioning */
       align-self: end;
   
       /* Display & box model */
       grid-column: 1 / 3;
       padding-top: 10px;
       padding-bottom: 20px;
   
       /* Text */
       font-size: 8pt;
       text-align: justify;
   }

   .contact-info {
        /* Positioning */
        justify-self: center;
    }
}