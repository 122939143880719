
.provider-info-line {
    /* Positioning */
    width: 100%;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 180px 20% 35% auto;
    grid-gap: 30px;
}

.provider-info-line-info {
    /* Positioning */
    padding-top: 18px;
    padding-bottom: 22px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
}

.provider-info-name,
.provider-info-code {
    /* Color */
    color: var( --default-blue-color );
    /* Text */

    /* Other */
}

.provider-info-name-value,
.provider-info-code-value {
    /* Color */
    color: var( --default-blue-color );

    /* Text */
    font-family: 'Segoe UI bold';
    font-weight: bold;

    /* Other */
    text-transform: uppercase;
}

.provider-info-star {
    /* Positioning */
    padding-bottom: 15px;

    /* Display & Box model */
    grid-column: 1 / 3;
}

.provider-info-details {
    /* Positioning */
    padding-top: 18px;
    padding-bottom: 20px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 20px; 
    justify-items: left;
    align-self: end;

    /* Text */
    font-size: 12px;

    /* Other */
    white-space: nowrap;
}

.provider-info-address, 
.provider-info-telephone,
.provider-info-mobile-phone,
.provider-info-SLA-signed,
.provider-info-active {
    /* Positioning */
    margin-bottom: 10px;

    /* Display & Box model */
    grid-column: 1 / 2;

    /* Text */
    color: var( --deafult-dark-grey-color )
}

.provider-info-address {
    /* Display & Box model */
    grid-row: 1 / 2;
} 
.provider-info-telephone {
    /* Display & Box model */
    grid-row: 2 / 3;
}
.provider-info-mobile-phone {
    /* Display & Box model */
    grid-row: 3 / 4;
}
.provider-info-SLA-signed {
    /* Display & Box model */
    grid-row: 4 / 5;
}
.provider-info-active {
    /* Display & Box model */
    grid-row: 5 / 6;
}

.provider-info-address-value,
.provider-info-telephone-value,
.provider-info-mobile-phone-value,
.provider-info-SLA-signed-value,
.provider-info-active-value {
    /* Display & Box model */
    grid-column: 2 / 3;
}

.provider-info-address-value {
    /* Display & Box model */
    width: min-content;
    grid-row: 1 / 2;
} 
.provider-info-telephone-value {
    /* Display & Box model */
    grid-row: 2 / 3;
}
.provider-info-mobile-phone-value {
    /* Display & Box model */
    grid-row: 3 / 4;
}
.provider-info-SLA-signed-value {
    /* Display & Box model */
    grid-row: 4 / 5;
}
.provider-info-active-value {
    /* Display & Box model */
    grid-row: 5 / 6;
}

.provider-info-line-show-SLA,
.provider-info-line-show-provider {
    /* Positioning */
    padding-bottom: 20px;
    
    /* Display & Box model */
    align-self: end;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;

    /* Text */
    color: var( --default-orange-color );

    /* Other */
    cursor: pointer;
}

.provider-info-line-show-SLA {
    /* Positioning */
    justify-self: right;

    /* Text */
    color: black;
}

.provider-info-line-show-provider {
    /* Positioning */
    justify-self: right;

    /* Text */
    color: var( --default-orange-color );
}

.provider-info-actions-container,
.provider-info-actions-container-only-look-more-button {
    /* Display & Box model */
    padding-top: 18px;
    display: grid;
}

.provider-info-actions-container {
    /* Display & Box model */
    grid-template-rows: 35px 35px auto;
}

.provider-info-actions-container-only-look-more-button {
    /* Display & Box model */
    grid-template-rows: auto;
}

.provider-info-line-arrow-right,
.provider-info-line-arrow-right-SLA {

    /* Positioning */
    width: 10px;
    height: 10px;
    margin-left: 10px;

    /* Other */
    transform: rotate( 45deg );
}

.provider-info-line-arrow-right {
    /* Display & Box model */
    border-top: 2px solid var( --default-orange-color );
    border-right: 2px solid var( --default-orange-color );
}

.provider-info-line-arrow-right-SLA {
    /* Display & Box model */
    border-top: 2px solid black;
    border-right: 2px solid black;

    /* Other */
    transform: rotate( 45deg );
}

#provider-info-dots-row {
    /* Positioning */
    width: 100%;
    height: 2px;

    /* Display & Box model */
    grid-column: 1 / 5;
    
    /* Color */
    background-image: linear-gradient( to right, var( --deafult-dark-grey-color ) 20%, rgba( 255, 255, 255, 0 ) 0% );
    background-position: bottom;
    background-size: 10px 2px;
    background-repeat: repeat-x;
}

@media only screen and (max-width: 1024px) {
    #provider-info-line {
        /* Display & Box model */
        grid-template-columns: auto !important;
        grid-template-rows: min-content min-content min-content min-content !important;
        grid-gap: 5px !important;
        justify-items: center;

    }

    .provider-info-actions-container {
        /* Display & Box model */
        grid-template-rows: min-content min-content auto;
    }
}