.tableHubCycle-row-container {
    /* Display & Box model */
    height: 51px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    background-image: linear-gradient(to right, var( --deafult-dark-grey-color ) 20%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 2px;
    background-repeat: repeat-x;

    /* Text */
    font-size: 14px;

    /* Other */
    cursor: pointer;
}

.tableHubCycle-numCycle {
    /* Display & Box model */
    padding-left: 20px;
    
    /* Text */
    font-family: 'Segoe UI Bold';
    font-size: 17px;
    color: var( --default-blue-color );
    font-weight: 600;
    text-align: left;
    line-height: 51px;
}

.tableHubCycle-duration,
.tableHubCycle-numOffers,
.tableHubCycle-numOrders {
    /* Display & Box model */
    padding-left: 20px;
    
    /* Text */
    text-align: left;
    line-height: 51px;
}

.tableHubCycle-startDate,
.tableHubCycle-endDate {
    /* Text */
    text-align: left;
    line-height: 51px;
}