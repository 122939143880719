#hub-page {
    /* Positioning */
    width: 100%;
    height: 100%;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 7fr 1fr;
    grid-template-rows: 140px 60px min-content auto 100px;
    
    /* Color */

    /* Text */

    /* Other */
}

#hub-page-title-container {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 2 / 3;
    grid-row: 2;

    /* Color */

    /* Text */

    /* Other */
}

.picture-banner-hub {
    /* Display & Box model */
    grid-column: 1 / 4;
   /* Other */
   background: url('/img/marketing-banner-small.png') no-repeat;
   background-size: cover;
}

.hub-page-title {
    /* Positioning */
    align-self: end;

    /* Display & Box model */
    width: fit-content;
    height: fit-content;

    /* Text */
    color: #f8a800;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#hub-page-navigation-menu {
    /* Positioning */
    padding-top: 40px;

    /* Display & Box model */
    grid-column: 2 / 3;
    grid-row: 3;
    display: grid;
    grid-template-columns: repeat( 8, 1fr );

    /* Color */

    /* Text */
    text-align: center;

    /* Other */
}

#hub-page-navigation-menu .tabs-bar {
    grid-column: 1/10;
    width: 100%;
}

#hub-page-navigation-menu .tabs-bar .tab {
    min-width: calc(100% / 10 );
}

#hub-page-navigation-menu .tabs-bar .tab-blue {
    min-width: calc(100% / 10 );
}

#hub-page .footer-bottom-main {
    /* Display & Box model */
    margin-top: 20px;
    grid-column: 1 / 4;
    grid-row: 5; 
}

@media only screen and (max-width: 1024px) {
    #hub-page-navigation-menu {
        /* Positioning */
    
        /* Display & Box model */
        grid-template-columns: auto !important;
    
        /* Color */
    
        /* Text */
    
        /* Other */
    }

    #hub-page-navigation-menu .tabs-bar {
        grid-column: 1/10;
        width: 100%;
    }
    
    #hub-page-navigation-menu .tabs-bar .tab {
        min-width: 100%;
    }
    
    #hub-page-navigation-menu .tabs-bar .tab-blue {
        min-width: 100%;
    }

    #hub-page .footer-bottom-main {
        /* Display & Box model */
        margin-top: 20px;
        grid-column: 1 / 4;
        grid-row: 5; 
    }

}

#hub-page-content-container {
    /* Positioning */
    
    /* Display & Box model */
    padding-top: 40px;
    grid-column: 2 / 3;
    grid-row: 4;

    /* Color */

    /* Text */

    /* Other */
}
