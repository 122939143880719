
.dropdown-select-my-orders {
  /* Positioning */
  margin-top: 0px;
  margin-bottom: 7px;

   /* Display & Box model */
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  line-height: 36px !important;
  height: 48px;

    /* Text */
  color: black;
  text-align: left;
  font-size: 13px;
}

.dropdown-item-list-my-orders {
   /* Positioning */
  margin-top: 2px !important;
  margin-left: 0px;
  position: absolute;

  /* Display & Box model */
  padding-left: 0px;
  padding-right: 0px;
  background-color: white;
  border: 1px solid black;
  width: 7%;
  max-height: 40vh;
  min-height: 0px;
  box-shadow: 5px 5px 5px grey;

  /* Text */
  color: black;
  text-align: left;
  font-size: 13px;

  /* Other */
  z-index: 2;
  overflow-y: auto;
}

.dropdown-item-list-my-orders .item {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
}

.Value {
    /* Positioning */
    margin-top: -26px;

    /* Display & Box model */
    padding-left: 12px;

    /* Text */
    color: white !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.dropdown-select-my-orders .Triangle {
  /* Positioning */
  right: 2px !important;
  left: 86%;
  top: 8px !important;
  position: relative;

  /* Display & Box model */
  width: 0;
  height: 0;
  display: inline-block;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgb(255, 255, 255);
  /* Color */
  
  /* Text */

  /* Other */
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Safari 3-8 */
  transform: rotate(180deg);
}