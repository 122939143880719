.side-menu-body {
    /* Positioning */
    padding: 30px 0 30px 5px;
    /* Display & Box model */
    display: grid;
    /* grid-template-rows: auto 40px 40px; */
    border-bottom: 2px solid black;
    /* Color */
    
   /* Text */

   /* Other */
    /* align-items: center; */
}