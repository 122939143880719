.popupSLA-main-container {
    /* Positioning */
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    
    /* Display & Box model */
    background-color: rgba( 0,0,0, 0.3);
    /* Text */
    color: var( --default-orange-color );

    /* Other */
    z-index: 2;
    overflow-y: auto;
}

.popupSLA-main-container .popup-window {
    /* Display & Box model */
    padding: 40px;
    background-color: #fff;
    border-radius: 20px;
    width: 60%;
    display: grid;
    grid-template-rows: min-content auto min-content;
    grid-gap: 20px;
}

.popup-window-title {
    font-size: 24px;
    color: var( --default-blue-color );
    text-align: center;
    margin: 20px 0 40px 0;
}

.popup-window-input-container {
    justify-self: center;
    
}

.popup-windows-close-btn {
    justify-self: center;
}

.popup-windows-close-btn .button{
    padding-top: 5px;
}

#input-btn {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 8px 12px;
    cursor: pointer;
    justify-self: center;
    align-self: center;

    /*visibility: hidden;*/
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.popup-window .button {
    /* Positioning */
    justify-self: center;

    /* Display & Box model */
    width: 140px;
}

.popupSLA-main-container .popup-buttons {
    /* Display & Box model */
    display: block;

    /* Text */
    text-align: center;
}
