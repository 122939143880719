.my-current-orders-text {
    /* Positioning */
    margin-top: 0px;

    /* Display & Box model */
    border-bottom: 2px solid #0E5FA6;

    /* Color */
    
    /* Text */
    color: var( --default-blue-color );
    font-family: 'Segoe UI bold';
    font-weight: bold;
    font-size: 18px;

    /* Other */
}

.my-past-orders-text {
    /* Positioning */
    margin-top: 40px;

    /* Display & Box model */
    border-bottom: 2px solid #0E5FA6;

    /* Color */
    
    /* Text */
    color: var( --default-blue-color );
    font-family: 'Segoe UI bold';
    font-weight: bold;
    font-size: 18px;

    /* Other */
}

#order-row-line {
    /* Positioning */
    width: 100%;
    margin-top: 30px;
    
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    
}

.order-row-line-info{
    /* Positioning */
    padding-top: 18px;
    padding-bottom: 22px;
    
    /* Display & Box model */
    display: grid;
}

#my-purchase-order-qr-image {
    /* Positioning */
    padding: 0 50px 0 0;
}

#QR-image-background {  
    /* Positioning */
    height: 120px;
    width: 120px;
    
    /* Other */
    background-image: url('/img/QR_code_example.png');
    background-size: cover;
}

.order-blue-text {   
    /* Positioning */
    padding-bottom: 10px;
    
    /* Text */
    color: #0E5FA6;
    letter-spacing: 1px;
    font-size: 13px;

    /* Other */
}

.order-blue-bold-text {  
    /* Color */
    color: #0E5FA6;
    
    
    /* Text */
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 12px;
    
}

.order-normal-text {   
    /* Text */
    color: black;
    letter-spacing: 1px;
    font-size: 12px;
}

.ordercode-style {
    /* Positioning */
    margin-bottom: 10px;
}

.img-qr-code{
    /* Positioning */
    margin-top: 9px;
    height: 140px;
    width: 140px;

    /* Other */    
    background-image: url('/img/QR_code_example.png');
    background-size: cover;
}

#dots-row {
    /* Positioning */
    width: 100%;
    height: 20px;

    /* Other */
    background-image: linear-gradient( to right, #333 20%, rgba( 255, 255, 255, 0 ) 0% );
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;    
}

.order-row-line-show-order {
    /* Positioning */
    padding-bottom: 20px;
    
    /* Display & Box model */
    align-self: end;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: end;
    /* Color */

    /* Text */
    color: var( --default-orange-color );

    /* Other */
    cursor: pointer;
}

.order-row-line-arrow-right {
    /* Positioning */
    width: 10px;
    height: 10px;
    margin-left: 20px;
    border-top: 2px solid var( --default-orange-color );
    border-right: 2px solid var( --default-orange-color );
    -webkit-transform: rotate( 45deg );
    transform: rotate( 45deg );
}

.toggle-activate-deactivate {
    /* Positioning */
    justify-self: right;
}

.toggle-activate-deactivate .button {
    /* Positioning */
    margin: 10px;
   
    /* Display & Box model */
    display: inline-block;
    height: 24px;
    width: 150px !important;
    background-color: var( --default-orange-color );
    border-radius: 16px;
   
   /* Text */
   padding-top: 3px;
   color: white;
   text-align: center;

   /* Other */
   cursor: pointer;
}

.order-delivery-info {
    /* Color */
    color: #0E5FA6;

    /* Text */
    letter-spacing: 1px;
    font-size: 13px;
}

.order-status-info {
     /* Display & Box model */
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
    
}

.order-availability {
    align-items: center;
}

.toggle-button-1 {
    /* Positioning */    
    margin-top: 10px;

    /* Display & Box model */
    justify-self: end !important;

    /* Text */
    text-transform: uppercase;
}

@media only screen and (max-width: 1024px) {
    #order-row-line {
        /* Display & Box model */
        grid-template-columns: auto !important;
        grid-template-rows: min-content min-content min-content min-content !important;
        grid-gap: 5px !important;
        justify-items: center;

    }
    .order-row-line-info {
        justify-items: center !important;
    }
}
