.red-circle {
    /* Positioning */
    position: absolute;
    top: -8px;
    right: -9px;
    line-height: 22px;
    
    /* Display & Box model */
    height: 22px;
    width: 22px;
    border-radius: 22px;

    /* Text */
    text-align: center;

    /* Color */
    background-color: red;
    color: white;
}