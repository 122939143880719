.order-section-page-container {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-rows: auto auto auto;
    /* Color */

    /* Text */
    color: var( --default-dark-grey-color );

    /* Other */
}

.data-range-selector {
    /* Positioning */
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: 4% 11% 3% 9% 3% 8% 3% 11% 48%;
}

.cycle-num-text {
    /* Text */
    font-weight: bold;
}

.pickup-at-hub-text,
.deliver-to-address-text,
.deliverable-orders-text{
    font-weight: bold;
    margin-left: 4px;

}

#cycleListNum {
    /* Positioning */
    /* Display & Box model */
    padding-left: 10px;
    padding-right: 10px;
    height: 24px;
    width: 50px;
    background-color: #ebebeb;
}

.current-hub-order {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-rows: 51px 25px 51px;
}

#pickup,
#deliver,
#deliverableOrders{
    /* positioning */
    float: right;
    vertical-align: -2px;
    margin-top: 4px;
}


.hub-orders {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    /* Color */

    /* Text */

    /* Other */
}

.current-hub-order-title,
.hub-orders-title {
    /* Text */
    font-size: 15px;
    color: var( --default-blue-color );
    font-weight: 600;
}

.current-hub-order .tableHubOrder-row-container {
    /* Display & Box model */
    grid-template-columns: repeat(6, 1fr);
    background-image: none;
}

.warning {
    /* Display & Box model */
    height: 80px;
    background-color: var( --default-light-red-color );

    /* Text */
    color: white;
    line-height: 80px;
    text-align: center;
}

#btn-send-to-print {
    justify-self: right;
    padding-right: 10px;
    line-height: 20px;
    background-color: var( --default-blue-color );
    width: 250px;
    text-align: center;
    color: var( --default-orange-color );
    font-size: 16px;
    padding: 5px;
    border-radius: 20px;
    cursor: pointer;
}