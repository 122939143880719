
#shopping-list {
    /* Positioning */
    position: sticky;
    height: 200px;
    width: 90%;
    padding: 20px 5% 0 5%;
    top: 0;
    z-index: 11;
    /* Display & Box model */
    display: grid;
    grid-template-columns: auto auto auto 190px;
    grid-template-rows: 30px 45px auto auto 20px;
    grid-template-areas: 
        "autosuggest-input autosuggest-input autosuggest-input autosuggest-input"
        "shopping-list-title shopping-list-title shopping-list-title shopping-list-title"
        "shopping-list-filters shopping-list-filters shopping-list-filters shopping-list-actions"
        "shopping-list-filters shopping-list-filters shopping-list-filters shopping-list-actions"
        "shopping-list-toggle shopping-list-toggle shopping-list-toggle shopping-list-toggle";

    /* Color */
    background-color: #ececec;

    /* Text */

    /* Other */
}

@media only screen and (max-width: 1024px) {
	#shopping-list {
        height: auto !important;
        min-height: 200px !important;
		/* Display & Box model */
		grid-template-columns: auto auto auto auto !important;
        grid-template-rows: 30px 30px 40px auto auto 20px !important;
        grid-template-areas: 
            "shopping-list-actions shopping-list-actions shopping-list-actions shopping-list-actions"
            "autosuggest-input autosuggest-input autosuggest-input autosuggest-input"
            "shopping-list-title shopping-list-title shopping-list-title shopping-list-title"
            "shopping-list-filters shopping-list-filters shopping-list-filters shopping-list-filters"
            "shopping-list-filters shopping-list-filters shopping-list-filters shopping-list-filters" 
            ". shopping-list-toggle shopping-list-toggle ." !important;

    }

    #shopping-list-filters {
        /* Positioning */
        padding-bottom: 20px;
        
        /* Display & Box model */
        grid-template-rows: 50% 50% !important;
        grid-template-columns: auto auto !important;
        /* Color */
        
        /* Text */
    
        /* Other */
    }

    #shopping-list-title {
        line-height: 40px !important;
    }

    #shopping-list-actions {
        display: grid !important;
        grid-template-columns: auto auto !important;
    }

    #shopping-list-actions > div{
        padding: 0 !important;
    }
}

#autosuggest-input {
    /* Positioning */
    grid-area: autosuggest-input;

    /* Display & Box model */
    border-bottom: 1px solid grey;

    /* Color */
    
    /* Text */
    font-size: 12px;
    
    /* Other */
}

#shopping-list-title {
    /* Positioning */
    line-height: 45px;
    /* padding-left: 59%; */

    /* Display & Box model */
    grid-area: shopping-list-title;
    /* Color */
    color: #feb361;

    /* Text */
    font-family: 'Segoe UI';
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;

    /* Other */
    justify-self: center;    
}

#shopping-list-actions {
    grid-area: shopping-list-actions;
}

#shopping-list-filters {
    /* Positioning */
    padding-bottom: 20px;

    /* Display & Box model */
    grid-area: shopping-list-filters;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 50% 50%;
    /* Color */
    
    /* Text */

    /* Other */
}

#warning-cant-add-more-items {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    color: red;
    /* Text */
    font-size: 11px;
    /* Other */
}

#shopping-list .tooltip-bottom {
    /* Positioning */
    top: 28px !important;
}

#shopping-list-open {
    /* Positioning */
    position: sticky;
    width: 100%;
    height: 20px;
    top: 0;
    z-index: 11;

    /* Display & Box model */
    display: grid;

    /* Color */
    background-color: #ececec;

    /* Text */
    text-align: center;

    /* Other */
    cursor: pointer;
}

#shopping-list-open > div {
    margin: auto;
}

#shopping-list-close {
    /* Positioning */

    /* Display & Box model */
    grid-area: shopping-list-toggle;

    /* Color */

    /* Other */
    justify-self: center;
    cursor: pointer;
}

#toggle-arrow-shopping-list {
    /* Positioning */
    height: 10px;
    width: 10px;

    /* Display & Box model */
    border-bottom: 3px solid #f8a800;
    border-right: 3px solid #f8a800;
    /* Color */

    /* Text */

    /* Other */
    transform: rotate( 45deg );
}

.toggle-arrow-shopping-list-down {
    /* Positioning */
    
    /* Display & Box model */

    /* Color */

    /* Text */

    /* Other */
    transform: rotate( 225deg ) !important;
}

#toggle-arrow-shopping-list-left {
    /* Positioning */
    height: 10px;
    width: 10px;
    margin-top: 3px;

    /* Display & Box model */
    border-bottom: 3px solid #f8a800;
    border-right: 3px solid #f8a800;
    /* Color */

    /* Text */

    /* Other */
    transform: rotate( 315deg ) !important;
}

#toggle-arrow-shopping-list-right {
    /* Positioning */
    height: 10px;
    width: 10px;
    margin-top: 3px;

    /* Display & Box model */
    border-bottom: 3px solid #f8a800;
    border-right: 3px solid #f8a800;
    /* Color */

    /* Text */

    /* Other */
    transform: rotate( 135deg ) !important;
}

.shopping-list-name {
    /* Display & Box model */
    display: grid;
    grid-column-gap: 5px;
    grid-template-columns: 1fr auto 1fr;

    /* Text */
    font-weight: 800;
    text-transform: uppercase;
}