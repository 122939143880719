/* Copied from  https://medium.com/front-end-weekly/creating-a-toggle-switch-in-css-2d23e496d035*/

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    transition: all 0.3s;
    cursor: pointer;
  }
  .switch::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius:50%;
    background-color: white;
    top: 1px;
    left: 1px;
    transition: all 0.3s;
  }
  
  .checkbox:checked + .switch::after {
    left : 20px;
  }
  .checkbox:checked + .switch {
    background-color:var( --default-blue-color );
  }
  .checkbox {
    display : none;
  }