.providerDetails-main-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 3fr 2fr 5fr;
    border-bottom: 3px dotted var( --deafult-dark-grey-color );
}

.providerDetails-name {
    /* Positioning */
    margin-top: 40px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 1 / 2;

    /* Text */
    color: var( --default-blue-color );
    font-size: 18px;
    font-weight: 600;
}

.providerDetails-rating {
    /* Display & Box model */
    grid-column: 2 / 3;
}

.providerDetails-description {
    /* Positioning */
    margin-top: 20px;

    /* Display & Box model */
    grid-column: 1 / 4;

    /* Text */
    text-align: justify;
}

.providerDetails-typeOfProducts {
    /* Positioning */

    /* Display & Box model */
    grid-column: 1 / 3;

    /* Text */
    color: var( --default-blue-color );
    font-weight: bold;
}

.providerDetails-filters {
    /* Positioning */
    margin-top: 10px;
    padding-bottom: 20px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 1 / 2;
    column-gap: 15px;
}

.providerDetails-products-item {
    /* Positioning */
    margin-top: 10px;

    /* Display & Box model */
    padding: 0px 5px 0px 5px;
    border: 2px solid var( --default-blue-color );
    border-radius: 20px;

    /* Text */
    text-align: center;
    text-transform: uppercase;
}

@media only screen and (max-width: 950px) {
    .providerDetails-main-container {
        /* Display & Box model */
        grid-template-columns: 1fr;
    }
    
    .providerDetails-name {
        /* Positioning */
        margin-top: 40px;
        justify-self: center;

        /* Display & Box model */
        grid-template-columns: 1fr;
        grid-column: 1 / 2;

        /* Text */
        text-align: center;
    }
    
    .providerDetails-rating {
        /* Positioning */
        justify-self: center;
    
        /* Display & Box model */
        grid-column: 1 / 2;
    }
    
    .providerDetails-description {
        /* Display & Box model */
        grid-column: 1 / 4;
    }

    .providerDetails-filters {
         /* Display & Box model */
         width: 310px;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 951px) {
    .providerDetails-main-container {
        /* Display & Box model */
        grid-template-columns: 1fr 1fr 1fr;
    }
    
    .providerDetails-name {
        /* Positioning */
        margin-top: 40px;

        /* Display & Box model */
        grid-column: 1 / 2;
    }
    
    .providerDetails-rating {
        /* Positioning */
    
        /* Display & Box model */
        grid-column: 2 / 3;
    }
    
    .providerDetails-description {
        /* Display & Box model */
        grid-column: 1 / 4;
    }

    .providerDetails-filters {
        /* Display & Box model */
        width: 310px;
    }
}