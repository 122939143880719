.main-keycloak-not-running {
    height: 100vh;
    padding-top: calc( 50vh - 50px );
}
.main-keycloak-not-running div {
    margin: auto;
    text-align: center;
    /*height: 100px;*/
    line-height: 100px;
    /*width: 70%;*/
    font-size: 25px;
    border-radius: 20px;
}