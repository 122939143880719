#dots-row {
    /* Positioning */
    width: 100%;
    height: 20px;

    /* Display & Box model */
    
    /* Color */
    background-image: linear-gradient( to right, #333 20%, rgba( 255, 255, 255, 0 ) 0% );
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;

    /* Text */

    /* Other */
}

.my-stands-text {
    /* Positioning */
    margin-top: 5px;
    
    /* Display & Box model */
    
    /* Color */
    color: var( --default-blue-color );

    /* Text */

    /* Other */
}

#my-stands-stand {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-rows: 20px auto;

    /* Color */

    /* Text */

    /* Other */
}

.back-to-stands {
    /* Positioning */
    
    /* Display & Box model */
    justify-self: end
    
    /* Color */

    /* Text */

    /* Other */
}

#my-stands-actions-container {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;

    /* Color */

    /* Text */

    /* Other */
}

@media only screen and (max-width: 1024px) { 
    #my-stands-actions-container {
        /* Positioning */
        
        /* Display & Box model */
        display: grid;
        grid-template-columns: auto !important;
    
        /* Color */
    
        /* Text */
    
        /* Other */
    }
}

#my-stands-actions {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;

    /* Color */

    /* Text */

    /* Other */
}

#my-providers-actions {
    /* Positioning */
    justify-self: center;
    margin-top: 20px;
}

.my-stand-actions-title {
    /* Positioning */
    padding-bottom: 10px;

    /* Display & Box model */

    /* Color */
    color: var( --default-blue-color );

    /* Text */
    font-family: 'Segoe UI Bold'

    /* Other */
}

.my-stand-actions-sla {
    /* Positioning */

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;

    /* Color */

    /* Text */

    /* Other */
}

.my-stand-actions-sla-template {
    justify-self: end;
    cursor: pointer;
}
.my-stands-actions-upload-button {
    /* Positioning */
    margin-top: 20px;
    width: 230px;
    height: 30px;

    /* Display & Box model */
    border-radius: 15px;
    justify-self: center;

    /* Color */
    background-color: var( --default-blue-color );
    color: white;

    /* Text */
    font-family: 'Segoe UI Semibold';
    letter-spacing: 1px;

    /* Other */
    text-transform: uppercase;
    cursor: pointer;
}

.button-add-stand .button {
    /* Positioning */
    margin: 10px;
   
    /* Display & Box model */
    display: inline-block;
    height: 24px;
    width: 150px !important;
    background-color: var( --default-orange-color );
    border-radius: 16px;
   
   /* Text */
   padding-top: 3px;
   color: white;
   text-align: center;

   /* Other */
   cursor: pointer;
}