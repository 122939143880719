#previous-orders-header {
    /* Positioning */
    
    margin: 30px 0 30px 0;

    /* Display & Box model */
    display: grid;
    grid-template-columns: auto auto;
    /* Color */
    
    /* Text */
    
    /* Other */
}

#previous-orders-filter-container {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;
    /* Color */
    
    /* Text */

    /* Other */
}

.previous-orders-text {
    /* Positioning */

    /* Display & Box model */
    
    /* Color */
    
    /* Text */
    color: #0E5FA6;
    letter-spacing: 1px;

    /* Other */
}

.previous-orders-filter-text {
    /* Positioning */
    padding-right: 10px;

    /* Display & Box model */
    
    /* Color */

    /* Text */
    font-size: 12px;
    letter-spacing: 1px;

    /* Other */
}

#previous-order-filter {
    /* Positioning */
    height: 25px;
    padding: 5px;

    /* Display & Box model */
    border-radius: 20px;

    /* Color */
    background-color: #ececec;

    /* Text */


    /* Other */
}