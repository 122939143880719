.edit-area {
    /* Positioning */
    width: calc( 100% - 14px );
    height: calc( 100% - 14px );
    padding: 4px;

    /* Display & Box model */
    
    /* Color */

    /* Text */
    font-size: 13px;
    font-family: 'Segoe UI';
    letter-spacing: 1px;

    /* Other */
    resize: none;
    overflow: auto;
    outline: none;
}

.edit-area-editable {
    border: 1px solid grey;
    font-family: 'Segoe UI';
    border-radius: 10px;
}

.validation-failed {
    /* Text */
    color: red;
}

.edit-area:focus {
    border: 1px solid #f8a800;
}

.validation-failed {
    color: red !important;
}