.side-menu-header {
    /* Positioning */

    /* Display & Box model */
    display: grid;
    grid-template-rows: auto 60px 40px;
    border-bottom: 2px solid black;
    /* Color */
    
   /* Text */

   /* Other */
    align-items: center;
}
.side-menu-header > div {
    text-align: center;
    cursor: default;
}

#btn-profile-logout {
    /* Positioning */

    /* Display & Box model */
    
    /* Color */
    
   /* Text */

   /* Other */
    cursor: pointer;
}

#btn-profile-logout > a {
    text-decoration: none;
    color: black;
}

#btn-profile-logout > a:visited {
    text-decoration: none;
    color: black;
}