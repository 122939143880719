.input-box {
    /* Positioning */
    position: relative;
    margin-bottom: 20px;
   
    /* Display & Box model */
    display: block;
    width: calc(100% - 49px);
    height: 31px;
    background-color: #ffffff00;
    border-radius: 16px;

    /* Text */
    color: black;
    font-size: 11px;
    padding-right: 25px;
    padding-left: 25px;
    letter-spacing: 1px;

    /* Other */
    z-index: 2;
}

.input-white-background {
    /* Display & Box model */
    background-color: white;
}

.input-box-placeholder {
    /* Positioning */
    position: absolute;
    margin-bottom: -31px;
   
    /* Display & Box model */
    display: block;

     /* width: calc(100% - 10px); */
    height: 31px;
    width: 59%;
    background-color: white;
    border-radius: 16px;

    /* Text */
    padding-left: 10px;
    text-align: left;
    letter-spacing: 1px; 
}

.placeholder-top {
    /* Positioning */
    position: absolute;
    margin-top: -25px;

    /* Text */
    padding-left: 10px;
    font-size: 12px !important;
    text-align: left;

    /* Other */
    z-index: 3;
}

.placeholder-bottom {
    /* Positioning */
    position: absolute;
    margin-top: 7px;

    /* Display & Box model */
    display: block;
    height: 31px;
    border-radius: 10px;
    background-color: white;

    /* Text */
    padding-left: 10px;
    text-align: left;
}

.placeholder-text {
    /* Positioning */
    padding-top: 7px;
    padding-left: 15px;

    /* Text */
    color: black;
    font-size: 11px;
}

.placeholder-top-text {
    /* Text */
    font-size: 11px;
}

.error {
    /* Positioning */
    padding-top: 7px;
    padding-left: 15px;

    
   /* Text */
   color: red;
   font-size: 11px;
}

.input-white-background-error {
    /* Display & Box model */
    background-color: white;
    
    /* Text */
   color: red;
}