
#offer-row-line {
    /* Positioning */
    width: 100%;
    margin-bottom: 30px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 180px 10px;
    grid-row-gap: 5px;
    grid-column-gap: 30px; 
}

.offer-row-dots-row {
    /* Positioning */
    width: 100%;
    height: 20px;

    /* Display & Box model */
    grid-column: 1 / 4;
    
    /* Color */
    background-image: linear-gradient( to right, var( --deafult-dark-grey-color ) 20%, rgba( 255, 255, 255, 0 ) 0% );
    background-position: bottom;
    background-size: 10px 2px;
    background-repeat: repeat-x;
}

#offer-row-line .actions-container {
    /* Display & Box model */
    display: grid;
    grid-template-rows: 35px 35px auto;
}

#offer-row-line .toggle-activate-deactivate {
    /* Positioning */
    justify-self: right;

    /* Display & Box model */
    grid-row: 2 / 3;
}


.disabled-product {
    /* Display & Box model */
    opacity: 0.2;
    filter: grayscale(1);

    /* Text */
    color: grey;
}

.icon {
    /* Display & Box model */
    grid-column: 1 / 2;
    width: 180px;
}

.product-info-row-text {
    /* Text */
    color: black;
}

@media only screen and (max-width: 1024px) {
    #offer-row-line {
        /* Display & Box model */
        grid-template-columns: 1fr !important;
        grid-template-rows: min-content min-content min-content min-content !important;
        grid-gap: 5px !important;
        justify-items: center;

    }
    .icon {
        /* Display & Box model */
        grid-column: 1 / 2;
    }

    #offer-row-line .actions-container {
        /* Display & Box model */
        display: grid;
        grid-template-rows: 45px 30px 61px;
    }

    .offer-row-line-info {
        /* Positioning */
        justify-items: center !important;

        /* Display & Box model */
        grid-column: 1 / 2;
    }

    .offer-row-product-price {
        /* Display & Box model */
        grid-column: 1 / 2;
    }

    .offer-row-line-show-offer {
        /* Display & Box model */
        grid-column: 1 / 2;
    }
}

.offer-row-line-info {
    /* Positioning */
    padding-top: 23px;
    padding-bottom: 54px;

    /* Display & Box model */
    display: grid;
}

.offer-row-text{
    /* Color */
    color: var( --default-blue-color );
}

.offer-row-text-name{
    /* Color */
    color: var( --default-blue-color );

    /* Text */
    font-family: 'Segoe UI Semibold';

    /* Other */
    text-transform: uppercase;
}

.offer-row-star {
    /* Positioning */
    padding-bottom: 15px;
}

.offer-row-description {
    /* Positioning */
    max-width: 100%;

    /* Display & Box model */
    align-self: end;

    /* Text */
    font-size: 12px;

    /* Other */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.offer-row-product-price {
    /* Positioning */
    padding-top: 18px;
    padding-bottom: 20px;

    /* Display & Box model */
    display: grid;
    justify-items: center;
    align-self: end;

    /* Text */
    font-size: 12px;

    /* Other */
    white-space: nowrap;
}

.offer-row-line-show-offer {
    /* Positioning */
    padding-bottom: 20px;
    
    /* Display & Box model */
    align-self: end;
    align-items: center;
    display: grid;
    grid-row: 3 / 4;

    grid-template-columns: auto 30px;
    /* Color */

    /* Text */
    color: var( --default-orange-color );

    /* Other */
    cursor: pointer;
}

#offer-row-line .button-text {
    /* Positioning */
    justify-self: right;
}

#offer-row-line .offer-row-line-arrow-right {
    /* Positioning */
    width: 10px;
    height: 10px;
    margin-left: 10px;

    /* Display & Box model */
    border-top: 2px solid var( --default-orange-color );
    border-right: 2px solid var( --default-orange-color );

    /* Other */
    transform: rotate( 45deg );
}
