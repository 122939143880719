.shopping-cart-login-main-container {
    /* Display & Box model */
    display: grid;
    grid-template-rows: 37px 137px 88px 69px 90px 88px auto;
}

.already-registered-title {
    /* Display & Box model */
    padding-bottom: 15px;
    grid-row: 1 / 2;

    /* Text */
    color: #0E5FA6;
    font-family: 'Segoe UI Bold';
    font-size: 16px; 
    font-weight: bold;
}

.already-registered-form {
    /* Display & Box model */
    padding: 30px 25px 10px 25px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr 1fr 1fr;
    grid-row: 2 / 3;
    row-gap: 20px;
    background-color: #ebebeb;
}

.first-time-shopping-title {
    /* Display & Box model */
    grid-row: 3 / 4;
}

.already-registered-form .input {
    /* Display & Box model */
    grid-column: 1 / 2;
}

.already-registered-form .input-box-placeholder,
.first-time-shopping-form .input-box-placeholder {
    /* Display & Box model */
    width: 33%;
}

.already-registered-form .input-box {
    /* Positioning */
    margin-bottom: 0px;
    
    /* Display & Box model */
    width: calc(100% - 89px);
}

.already-registered-login-signIn,
.first-time-shopping-register {
    /* Positioning */
    justify-self: start;

    /* Display & Box model */
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    width: fit-content;
    height: fit-content;

    /* Text */
    color: #0E5FA6;
    font-family: 'Segoe UI Bold', sans-serif;
    font-size: 14px;
    font-weight: bold; 
    text-align: right;
    text-transform: uppercase;

    /* Other */
    cursor: pointer;
}

.forgotten-password {
    /* Positioning */
    justify-self: end;

    /* Display & Box model */
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    width: fit-content;
    height: fit-content;
    padding-right: 47px;

    /* Color */
    color: #838383;
    font-size: 12px;

    /* Text */
    text-align: right;
}

.first-time-shopping-title,
.shopping-pros-registration-title {
    /* Display & Box model */
    padding-top: 50px;
    padding-bottom: 15px;

    /* Text */
    color: #0E5FA6;
    font-family: 'Segoe UI Bold';
    font-size: 16px; 
    font-weight: bold;
}

.first-time-shopping-form {
    /* Display & Box model */
    padding: 30px 25px 20px 25px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row: 4 / 5;
    row-gap: 20px;
    background-color: #ebebeb;
}

.first-time-shopping-skip-register {
    /* Positioning */
    justify-self: end;
    
    /* Display & Box model */
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    width: fit-content;
    height: fit-content;

    /* Text */
    color: #0E5FA6;
    font-size: 14px;
    font-family: 'Segoe UI Bold', sans-serif;
    text-align: right;
    text-transform: uppercase;

    /* Other */
    cursor: pointer;
}

.shopping-pros-registration {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row: 7 / 8;
    height: 221px;
    background-color: #838383;
}

.shopping-cart-login-main-container .button-next-step {
    /* Positioning */
    margin-top: 40px;

    /* Display & Box model */
    grid-row: 5 / 6;

}

.shopping-pros-registration-title {
    /* Display & Box model */
    grid-row: 6 / 7;
}

@media only screen and (max-width: 700px) {
    .already-registered-form,
    .first-time-shopping-form {
        /* Display & Box model */
        grid-template-columns: 60% 40%;
    }

    .already-registered-form .input-box-placeholder,
    .first-time-shopping-form .input-box-placeholder {
        /* Display & Box model */
        width: 49%;
    }

    .already-registered-form .input-box {
        /* Display & Box model */
        width: 72%
    }

    .already-registered-title,
    .first-time-shopping-title,
    .shopping-pros-registration-title {
        /* Positioning */
        margin-left: 15px;
    }

}

@media only screen and (max-width: 360px) {
    .first-time-shopping-register {
        /* Display & Box model */
        width: 105px;
    }
}