.shopping-cart-success-main-container {
     /* Display & Box model */
     display: grid;
     grid-template-rows: auto auto 120px;
}

.message-container {
     /* Display & Box model */
     display: grid;
     grid-template-rows: auto 60px 120px;
}

.Error_icon {
    /* Positioning */
    justify-self: center;
    margin-bottom: 35px;

    /* Display & Box model */
    width: 120px;
    height: 120px;
    border: 13px solid #d14949;
    border-radius: 100%;
    padding: 46px;
}

.Error_icon .icon-baseline-clear-24px::before {
    font-size: 128px;
}

.Error_message {
    /* Text */
    font-size: 30px;
    text-align: center;
}

.success-main-container {
    /* Positioning */
    justify-self: center;

    /* Display & Box model */
    width: fit-content;
    
    /* Text */
    text-align: center;
}

.QR_code_main_container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 221px;
    padding: 20px 40px 43px 40px;
    background-color: #ebebeb;

    /* Text */
    text-align: center;
}

.QR_code_container {
     /* Positioning */
     justify-self: center;

     /* Display & Box model */
     grid-column: 2 / 3;
     width: fit-content;
}

.QR_code {
    /* Display & Box model */
    background-color: white;

}

.print {
    /* Positioning */
    justify-self: end;
    align-self: center;

    /* Display & Box model */
    grid-column: 3 / 4;
    width: fit-content;

    /* Text */
    color: #0E5FA6;
    font-family: 'Segoe UI Bold', sans-serif;
    font-size: 14px;
    font-weight: bold; 
    text-align: right;
    text-transform: uppercase;

    /* Other */
    cursor: pointer;
}

.button-next-step {
     /* Text */
     text-transform: uppercase;
     
     /* Other */
     cursor: pointer;
}

@media only screen and (max-width: 700px) {
    .message-container {
        /* Display & Box model */
        grid-template-rows: auto auto 120px;
    }

    .Error_icon {
        /* Display & Box model */
        width: 80px;
        height: 80px;
    }

    .Error_icon .icon-baseline-clear-24px {
        /* Text */
        font-size: 87px !important;
    }
    .Error_icon .icon-baseline-clear-24px::before {
        font-size: 87px !important;
    }

    .Error_message {
        /* Text */
        font-size: 23px;
    }

    .QR_code_main_container {
         /* Display & Box model */
         display: block;

         /* Text */
         text-align: center;
    }

    .QR_code_container {
        /* Display & Box model */
        width: 100%;
    }

   .print {
        /* Display & Box model */
        width: 100%;

        /* Text */
        text-align: center;
    }
}