.HubDetails-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 3fr 2fr 5fr;
}

.HubDetails-name {
    /* Positioning */
    margin-top: 40px;

    /* Display & Box model */
    grid-column: 1 / 2;

    /* Text */
    color: var( --default-blue-color );
    font-size: 18px;
    font-weight: 600;
}

.HubDetails-rating {
    /* Positioning */
    margin-top: 40px;

    /* Display & Box model */
    grid-column: 3 / 4;
}

.HubDetails-description {
    /* Positioning */
    margin-top: 20px;

    /* Display & Box model */
    grid-column: 1 / 4;
}

.HubDetails-distance-from-hub {
     /* Positioning */
     margin-top: 20px;
}

.HubDetails-icons {
    /* Positioning */
    margin-top: 20px;

    /* Display & Box model */
    width: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 3 / 4;
}

@media only screen and (max-width: 950px) {
    .HubDetails-container {
        /* Display & Box model */
        grid-template-columns: 1fr;
    }
    
    .HubDetails-name {
        /* Positioning */
        margin-top: 40px;
        justify-self: center;
    }
    
    .HubDetails-rating {
        /* Positioning */
        margin-top: 20px;
        justify-self: center;
    
        /* Display & Box model */
        grid-column: 1 / 2;
    }
    
    .HubDetails-description {
        /* Display & Box model */
        grid-column: 1 / 2;
    }
    
    .HubDetails-distance-from-hub {
         /* Positioning */
         justify-self: center;
    }
    
    .HubDetails-icons {
        /* Positioning */
        justify-self: center;
    
        /* Display & Box model */
        grid-column: 1 / 2;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 951px) {
    .HubDetails-container {
        /* Display & Box model */
        grid-template-columns: 1fr 1fr 1fr;
    }
    
    .HubDetails-name {
        /* Positioning */
        margin-top: 40px;
    }
    
    .HubDetails-rating {
        /* Positioning */
        margin-top: 40px;
    
        /* Display & Box model */
        grid-column: 3 / 4;
    }
    
    .HubDetails-description {
        /* Display & Box model */
        grid-column: 1 / 4;
    }
    
    .HubDetails-distance-from-hub {
         /* Positioning */
         justify-self: center;

        /* Display & Box model */
        grid-column: 2 / 3;
    }
    
    .HubDetails-icons {
        /* Display & Box model */
        grid-column: 3 / 4;
    }
}