.button {
    /* Positioning */
    margin: 10px;

    /* Display & Box model */
    display: inline-block;
    height: 24px;
    width: 25%;
    background-color: #0a60a6;
    border-radius: 16px;

    /* Text */
    padding-top: 7px;
    color: white;
    text-align: center;

    /* Other */
    cursor: pointer;
}

.btn-disabled {
    /* Display & Box model */
    background-color: grey;

    /* Text */
    color: black;

    /* Other */
    cursor: default;
}