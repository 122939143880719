.basket-main-container {
     /* Display & Box model */
     display: grid;
     grid-template-rows: auto 130px 120px auto;
}

.basket-item-row {
     /* Display & Box model */
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 100px 1fr 1fr;
     border-bottom: 2px dotted #ebebeb;
     height: 170px;
}

.product-image {
    /* Positioning */
    justify-self: center;
    
    /* Display & Box model */
    padding-bottom: 12px;
    grid-column: 1 / 2;
    height: 150px;
}

.product-supplier {
    /* Positioning */
    align-self: center;
    
    /* Display & Box model */
    grid-column: 2 / 3;
    width: fit-content;
    height: fit-content;
}

.supplier-name {
    /* Text */
    color: #565655;
    font-size: 12px;
    letter-spacing: 2px;
}

.product-name {
    /* Text */
    color: #0E5FA6;
    font-size: 18px;
    font-weight: bold;
}

.quantity-selector {
    /* Positioning */
    align-self: end;
    justify-self: center;

    /* Display & Box model */
    padding-bottom: 16px;
    grid-column: 3 / 4;
    width: fit-content;

    /* Text */
    color: #565655;
    font-size: 14px;
    letter-spacing: 2px;
}

.quantity-selector-text {
    /* Positioning */
    margin-right: 40px;

    /* Display & Box model */
    padding-bottom: 5px;
}

.dropdown {
    /* Display & Box model */
    padding-bottom: 5px;
}


.quantity-selector-text,
.dropdown {
    /* Display & Box model */
    display: inline-block
}

.price,
.withTax {
    /* Positioning */
    align-self: end;
    justify-self: right;

    /* Display & Box model */
    padding-bottom: 20px;
    grid-column: 4 / 5;

    /* Text */
    font-size: 18px;
    letter-spacing: 2px;
}

.withTax {
    /* Positioning */
    align-self: end;
    justify-self: center;

    /* Display & Box model */
    padding-bottom: 20px;
    grid-column: 5 / 6;

    /* Text */
    font-size: 18px;
    letter-spacing: 2px;
}

.basket-buttons-container {
    /* Positioning */
    align-self: end;
    justify-self: end;

    /* Display & Box model */
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 15px 15px;
    grid-column: 6 / 7;
    column-gap: 20px;
}

.heart-icon,
.delete-icon {
    /* Other */
    cursor: pointer;
}

.delete-icon-disabled {
    /* Display & Box model */
    opacity: 0.5;
}

.account-total-container {
    /* Display & Box model */
    padding-top: 40px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 30px 30px 30px 30px;
    grid-row: 2 / 3;
    width: fit-content;
    justify-self: end; 
}

.sum-total {
     /* Display & Box model */
     padding: 5px 5px 5px 15px;
     grid-column: 1 / 2;
     grid-row: 2 / 3;
     border-bottom: 1px solid #cfcbcb; 

     /* Text */
     font-size: 14px;
     color: #565655;
     letter-spacing: 2px;
     /*text-align: right;*/
}

.sum-total-neto {
     /* Display & Box model */
     padding: 5px 0px 5px 5px;
     grid-column: 2 / 3;    
     grid-row: 2;
     border-bottom: 1px solid #cfcbcb;  
 
     /* Text */
     font-size: 17px;
     letter-spacing: 2px;
     text-align: right;
}

.commission-fee-basket-text {
    /* Display & Box model */
    padding: 5px 5px 5px 15px;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    border-bottom: 1px solid #cfcbcb; 

    /* Text */
    font-size: 14px;
    color: #565655;
    letter-spacing: 2px;
    /*text-align: right;*/
}

.commission-fee-basket {
    /* Display & Box model */
    padding: 5px 0px 5px 5px;
    grid-column: 2;    
    grid-row: 1 / 2;
    border-bottom: 1px solid #cfcbcb; 

    /* Text */
    font-size: 17px;
    letter-spacing: 2px;
    text-align: right;
}

/*.tax {
     padding: 5px 5px 5px 15px;
     grid-column: 1 / 2;
     grid-row: 2 / 3;
     border-bottom: 1px solid #cfcbcb;

     font-size: 14px;
     color: #565655;
     letter-spacing: 2px;
}

.tax-percentage {
    padding: 5px 5px 5px 15px;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    border-bottom: 1px solid #cfcbcb;

    font-size: 14px;
    color: #565655;
    letter-spacing: 2px;
}*/

/*.tax-value {
    
     padding: 5px 0px 5px 5px;
     grid-column: 2 / 3;
     grid-row: 2 / 3;
     border-bottom: 1px solid #cfcbcb; 

     font-size: 17px;
     letter-spacing: 2px;
     text-align: right;
}

.tax-value-percentage {
    padding: 5px 0px 5px 5px;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    border-bottom: 1px solid #cfcbcb; 

    font-size: 17px;
    letter-spacing: 2px;
    text-align: right;
}*/

.total {
     /* Display & Box model */
     padding: 5px 5px 5px 15px;
     grid-column: 1 / 2;
     grid-row: 3 / 4;
 
     /* Text */
     font-size: 14px;
     color: #565655;
     letter-spacing: 2px;
}

.total-bruto {
     /* Display & Box model */
     padding: 5px 0px 5px 5px;
     grid-column: 2 / 3;
     grid-row: 3 / 4; 
 
     /* Text */
     font-size: 17px;
     font-weight: bold;
     letter-spacing: 2px;
     text-align: right;
}

.button-next-step {
    /* Positioning */
    align-self: center;
    justify-self: end;

     /* Display & Box model */
     grid-row: 3 / 4;
     width: fit-content;

     /* Text */
     color: white;
     font-weight: bold;
     text-align: center;
     text-transform: capitalize;

     /* Other */
     cursor: pointer;
}

.button {
    /* Display & Box model */
    width: 264px; 
}

.clear-basket {
     /* Display & Box model */
     grid-row: 4 / 5; 
     width: fit-content;
     justify-self: end;  
     /* Text */
     font-size: 12px;
     color: #565655;

     /* Other */
     cursor: pointer;
}

.Triangle {
    /* Positioning */
    top: 18px;
}

.select {
    /* Positioning */
    bottom: 0px;
}

@media only screen and (max-width: 700px) {
    .basket-main-container {
        /* Display & Box model */
        grid-template-rows: auto 170px 120px auto;
    }
	.basket-item-row {
        /* Display & Box model */
        display: block;
        border-bottom: 2px dotted #ebebeb;
        height: auto;

        /* Text */
        text-align: center
   }

   .product-supplier {  
        /* Display & Box model */
        width: 100%;
        height: fit-content;

        /* Text */
        text-align: center
    }

    .quantity-selector {
        /* Display & Box model */
        padding-bottom: 10px;
        width: 100%;
    }

    .basket-buttons-container {
        /* Display & Box model */
        display: block;
    }

    .heart-icon,
    .delete-icon {
        /* Display & Box model */
        display: inline;

        /* Other */
        cursor: pointer;
    }

    .heart-icon {
        /* Positioning */
        margin-right: 10px;
    }

    .delete-icon {
        /* Positioning */
        margin-left: 10px;
    }

    .account-total-container {
        /* Display & Box model */
        grid-template-rows: repeat(3, auto);
    }

    .sum-total {
        /* Text */
        text-align: left;
    }

    .sum-total-value,
    .tax-value,
    .total-value {
        /* Display & Box model */
        padding-right: 20px;
    }

    .button-next-step {
        /* Positioning */
        margin: auto;
    
        /* Display & Box model */
         width: 100%;
    }
    
    .button {
        /* Positioning */
        margin: auto;
        
        /* Display & Box model */
        width: 264px; 
    }

    .clear-basket {
        /* Positioning */
        margin-right: 15px;
   }
}