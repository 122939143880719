#product-view-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "product-view-picture-slider product-view-hub-conatent";
}

#product-view-picture-slider {
    /* Display & Box model */
    grid-area: product-view-picture-slider;
}

#product-view-info {
    /* Display & Box model */
    grid-area: product-view-hub-conatent;
    display: grid;
    grid-gap: 5px;
    grid-template-rows: 41px auto min-content 20px 80px 10px;
}

#product-view-first-row {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 320px min-content;
    align-items: center;
}

.product-view-first-row-name {
    /* Positioning */
    padding-left: 5px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;
    
    /* Other */
    overflow: hidden;
    white-space: nowrap;
}

.product-view-dropdown .dropdown-select {
     /* Display & Box model */
     padding-top: 5px;

     /* Other */
     cursor: pointer;
}

.product-view-dropdown .dropdown-select .Triangle {
    /* Positioning */
    top: -3px;
    left: 84px !important;

    /* Display & Box model */
    display: inline-block;
}

.product-view-dropdown .dropdown-select .Value {
    /* Positioning */
    position: absolute !important;
    margin-top: -1px;

    /* Display & Box model */
    display: inline-block;
    width: 86px;
    padding-top: 3px;

    /* Text */
    color: black !important;
}

.product-view-dropdown .dropdown-item-list {
    /* Positioning */
    margin-top: 6px;
    margin-left: -10px;
    
    /* Display & Box model */
    position: absolute;
    padding-left: 0px;
    background-color: white;
    border: 1px solid black;
    width: 113px;
    max-height: 40vh;
    min-height: 0px;
    box-shadow: 5px 5px 5px grey;
    
    /* Text */
    color: black;
    text-align: left;
    font-size: 15px;
    
    /* Other */
    z-index: 2;
    overflow-y: auto;
    margin-left: -10px;
 }

 .product-view-dropdown .dropdown-item-list .item {
     /**/
     padding-left: 10px;
 }

.product-view-title-editable {
    /* Display & Box model */
    background-color: #fff;
    height: 34px;

    /* Text */
    font-family: 'Segoe UI Semibold' !important;
    font-size: 25px !important;
    color: var( --default-blue-color );
}

.product-view-title {
    /* Positioning */
    margin-right: 2px;

    /* Text */
    font-size: 20px;
    color: var( --default-blue-color );
    font-weight: 600;
}

.product-view-quality {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.product-view-item-data {
    /* Display & Box model */
    grid-column: 1 / 2;

    /* Text */
    text-align: left;
}

.title-actions {
    /* Display & Box model */
    display: grid;
    grid-template-columns: min-content min-content, min-content;
    align-items: center;
}

.product-comments {
    /* Display & Box model */
    grid-column: 1 / 2;
}

.product-nakupi {
    /* Display & Box model */
    grid-column: 2 / 3;
}

.product-starGrading {
    /* Display & Box model */
    grid-column: 3 / 4;
}

.product-view-one-line {
    /* Display & Box model */
    height: 31px;
    display: grid;
    grid-template-columns: 77px 91px 100px;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
}

.product-view-one-line .edit-area {
    /* Display & Box model */
    width: 75px;
    height: 19px;
}

.product-view-dropdown-main {
    /* Positioning */
    margin-top: 40px;

    /* Display & Box model */
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: auto 300px;
}

.product-view-dropdown-text {
    /* Positioning */
    justify-self: right;

    /* Display & Box model */
    padding-right: 20px;
    grid-column: 1 / 2;
}

.product-view-dropdown-grid-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    width: 300px;
    height: 35px;
    background-color: var( --deafult-grey-color );
    border-radius: 25px;

    /* Color */

    /* Text */
    text-align: center;
}

.product-view-dropdown {
    /* Display & Box model */
    grid-column: 1 / 2;
}

.product-view-dropdown .select {
    /* Positioning */
    margin-left: 30px;

    /* Display & Box model */
    background-color: transparent;
    border: 2px solid darkgray;
    padding-right: 18px;
}

.product-view-dropdown-data {
    /* Display & Box model */
    grid-column: 2 / 3;
    padding-top: 5px;
    border-right: 2px solid var( --deafult-dark-grey-color );
    border-left: 2px solid var( --deafult-dark-grey-color );

    /* Color */
    color: var( --deafult-dark-grey-color );

    /* Text */
    font-size: 20px;
}

.product-view-dropdown-image {
    /* Display & Box model */
    padding-top: 5px;

    /* Other */
    cursor: pointer;

}

#product-view-container #dots-row {
    /* Display & Box model */
    grid-column: 2/ 3;
}

.product-view-hubs-to-edit {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 2 / 3;
}

.hub-to-edit {
    /* Positioning */
    margin-top: 30px;
    margin-bottom: 30px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 25px 1fr;
}

.hub-checkpoint {
    /* Positioning */
    margin-top: 5px;
}

.hub-checkpoint .checkmark {
    /* Display & Box model */
    height: 15px;
    width: 15px;
}

.hub-checkpoint .container .checkmark:after {
    left: 5px;
    top: 0;
    width: 4px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    transform: rotate(35deg);
}

.hub-checkpoint .container input:checked ~ .checkmark {
    background-color: var( --deafult-dark-grey-color );
} 

.hub-tag,
.hub-name {
    /* Text */
    color: var( --default-blue-color );
}

.hub-name {
    /* Positioning */
    margin-bottom: 20px;

    /* Text */
    font-family: 'Segoe UI bold';
    font-size: 16px;
    letter-spacing: 1px
}

#product-view-container .gallery-button {
    /* Text */
    text-align: right;
}

#product-view-container .button {
    /* Positioning */
    margin-right: 60px;

    /* Display & Box model */
    width: 217px;

    /* Text */
    font-weight: bold;
}

.product-view-info-dropdown {
    /* Display & Box model */
    widows: 80px;
    display: grid;
    grid-template-columns: 67px auto;
    column-gap: 10px;
}


.product-view-info-dropdown .dropdown-select-my-orders {
    /* Display & Box model */
    width: 83px !important;
    height: 29px;
    background-color: white !important;
    border: 1px solid grey;
    border-radius: 10px;

    /* Text */
    color: black !important;

    /* Other */
    cursor: pointer;
}

.product-view-info-dropdown .Value {
    /* Positioning */
    position: absolute;
    margin-top: 0px !important;

    /* Display & Box model */
    width: 67px;
    height: 29px;
    display: inline-block;
    line-height: 29px;

    /* Text */
    color: black !important;
    font-family: 'Segoe UI';
}

.product-view-info-dropdown .Triangle {
    /* Display & Box model */
   top: -7px !important;
   left: 78%;
   border-bottom: 5px solid darkgrey !important;
}

.product-view-info-dropdown .dropdown-item-list-my-orders {
    /* Positioning */
    margin-top: -6px !important;
    /* Display & Box model */
    width: 78px !important;
}

.no-markets-message {
    /* Display & Box model */
    padding: 10px;
    background-color: var( --default-light-red-color );

    /* Text */
    color: white;
}

@media only screen and (max-width: 1024px) { 
    #product-view-container {
        /* Display & Box model */
        grid-template-columns: auto !important;
        grid-template-rows: 1fr 1fr !important;
        grid-template-areas: "product-view-hub-conatent" "product-view-picture-slider"  !important;
    }


    #product-view-container #dots-row {
        /* Display & Box model */
        grid-column: 1/ 2;
    }

    .title-actions {
        /* Display & Box model */
        grid-row: 2 / 3;
        width: 320px;
    }

    #product-view-first-row {
        /* Positioning */
        margin-top: 25px;
        
        /* Display & Box model */
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        align-items: center;
    }

    #product-view-info {
        /* Display & Box model */
        grid-template-rows: 94px auto 28px 28px 28px auto;
    }

    .product-view-dropdown-grid-container {
        /* Positioning */
        justify-self: center;
    }

    .product-view-dropdown-main {
        /* Display & Box model */
        grid-template-rows: 1fr 1fr ;
        grid-template-columns: 1fr ;
    }
    
    .product-view-dropdown-text {
        /* Positioning */
        justify-self: center;
    
        /* Display & Box model */
        grid-column: 1 / 2;
    }

    .product-view-hubs-to-edit {
        /* Display & Box model */
        grid-column: 1 / 2;
    }

    #product-view-container .gallery-button {
        /* Text */
        text-align: center;
    }
    
    #product-view-container .button {
        /* Positioning */
        margin-right: 0px;
    }
}

.product-view-first-row-name {
    /* Positioning */
    padding-left: 5px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;

    /* Other */
    overflow: hidden;
    white-space: nowrap;
}