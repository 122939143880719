
#marketing-banner {
    /* Positioning */
    height: 300px;
    width: 100%;
    /* Display & Box model */
    display: inline-block;
    background: url('/img/marketing-banner.png') no-repeat;
    background-size: cover;
    /* Color */
    background-color: #ececec;
    /* Text */

    /* Other */
}