.SLA-section-page-container{
    /* Display & Box model */
    display: grid;
    grid-template-rows: auto auto auto;

    /* Text */
    color: var( --default-dark-grey-color );
}

.SLA-title-row {
    /* Positioning */
    margin-bottom: 40px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.SLA-title {
    /* Display & Box model */
    grid-column: 1 / 2;
    align-self: center;

    /* Text */
    font-size: 16px;
    font-weight: 500;
    color: var( --default-blue-color );
    font-weight: 600;
}

.SLA-button-container {
    /* Positioning */
    justify-self: end !important;

    /* Display & Box model */
    grid-column: 2 / 3 !important;
}

.warning {
    /* Display & Box model */
    height: 80px;
    background-color: var( --default-light-red-color );

    /* Text */
    color: white;
    line-height: 80px;
    text-align: center;
}

.no-sla {
    margin-top: 40px;

    /* Text */
    text-align: center;
    font-size: 18px;
    color: red;
}