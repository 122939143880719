.gs1-header {
    /* Positioning */
    margin-top: 5px;
    padding: 7px;
    /* Display & Box model */
    display: grid;  
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    /* Color */

    /* Text */
    font-size: 14px;
    letter-spacing: 1px;
    text-align: left;
    color: gray;
    /* Other */
    
    
}

#gs1-header-items {
    
}
