.gs1-row-data {
    /* Positioning */
    margin-top: 5px;
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
}

.gs1-item-info{
    /* Positioning */
    padding: 7px;
    text-align: left;
    margin-top: 9px;
    margin-bottom: auto;

    /* Text */
    text-align: left;
}

.gs1-item-dots-row {
    /* Positioning */
    height: auto;

    /* Display & Box model */
    display: grid;
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row: 3/4;
    
}

#dots-row {
    /* Positioning */
    width: 100%;
    height: 20px;

    /* Other */
    background-image: -webkit-gradient( linear, left top, right top, color-stop(20%, #333), color-stop(0%, rgba( 255, 255, 255, 0 )) );
    background-image: linear-gradient( to right, #333 20%, rgba( 255, 255, 255, 0 ) 0% );
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;
}