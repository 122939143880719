.hub-warehouse-container {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-rows:39px 39px auto 50px;
    
    /* Color */
    
    /* Text */

    /* Other */
}

.gridWarehouse-title {
    /* Positioning */
    text-align: left;

    /* Display & Box model */
    grid-row: 1/2;
    
    /* Color */
    font-size: 16px;
    color: #0E5FA6;
    font-weight: bold;
    display: grid;
    grid-template-columns: auto auto;
    
    /* Text */

    /* Other */
}

.gridWarehouse-title-text {
    justify-self: start;
}

.gridWarehouse-title-btn {
    justify-self: end;
    border-radius: 25px;
    padding: 5px;
    width: 200px;
    background-color: var( --default-blue-color );
}

.gridWarehouse-title-btn > button {
    color: var( --default-orange-color );
    width: 100%;
    font-weight: bold;
    height: 100%;
    cursor: pointer;
}

.gridWarehouse-item-header {
    /* Positioning */
    text-align: left;

    /* Display & Box model */
    padding: 7px;
    
    /* Color */
    color: gray;

    /* Text */

    /* Other */
}

.hub-warehouse-header {
    /* Positioning */
    margin-top: 5px;

    /* Display & Box model */
    display: grid;
    grid-row: 2/3;
    grid-template-columns: calc(20%) calc(67%) calc(12%);
    justify-content: center;

    /* Color */
    
    /* Text */

    /* Other */
}

.hub-warehouse-row-data {
    /* Positioning */
    margin-top: 5px;
    /* Display & Box model */
    display: grid;
    grid-template-columns: calc(20%) calc(68%) calc(12%);
    justify-content: center;

    /* Color */
    
    /* Text */

    /* Other */
}

.hub-warehouse-rows-main {
     /* Positioning */
    
    /* Display & Box model */
    grid-row: 3/4;
    margin-bottom: 10px;
    /* Color */
    
    /* Text */

    /* Other */
}

.hub-section-edit-button {
    /* Positioning */
    width: 180px;
    height: 30px;

    /* Display & Box model */
    border-radius: 15px;
    justify-self: center;

    /* Color */
    background-color: var( --default-blue-color );
    color: white;

    /* Text */
    font-family: 'Segoe UI Semibold';
    letter-spacing: 1px;
    text-transform: uppercase;

    /* Other */
    cursor: pointer;
}

.hub-warehouse-buttons {
    /* Positioning */
    
    /* Display & Box model */
    padding: 20px;
    display: grid;
    grid-row: 5/6;
    justify-content: right;
    grid-template-columns: 190px 190px 190px 190px;

    /* Color */
    
    /* Text */

    /* Other */
}

.hub-warehouse-row-input {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-row: 4/5;
    grid-template-columns: calc(20%) calc(68%) calc(12%);
    justify-content: center;
    padding: 7px;

    /* Color */
    background-color: #ededed;

    /* Text */

    /* Other */
}

.gridWarehouse-input-box-cell{
    /* Positioning */
    
    /* Display & Box model */
    margin-top: auto;
    margin-bottom: auto;
    padding: 7px;
    text-align: left;

    /* Color */

    /* Text */

    /* Other */
}

.gridWarehouse-input-box{
    /* Positioning */
    
    /* Display & Box model */
    margin-top: auto;
    margin-bottom: auto;
    width: calc(100%);

    /* Color */

    /* Text */

    /* Other */
}

::placeholder{
    /* Positioning */
    
    /* Display & Box model */

    /* Color */
    color: #3b3b3b;

    /* Text */
    font-style: italic;

    /* Other */
}

