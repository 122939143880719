.cycle-section-page-container {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-rows: auto 137px auto;
    /* Color */

    /* Text */
    color: var( --default-dark-grey-color );

    /* Other */
}

.date-range-selector {
    /* Positioning */
    margin-bottom: 40px;
}

.current-hub-cycle {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-rows: 51px 25px 51px;
    /* Color */

    /* Text */

    /* Other */
}

.hub-cycles {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-rows: 1fr 25px 1fr;
    /* Color */

    /* Text */

    /* Other */
}

.current-hub-cycle-title,
.hub-cycles-title {
    /* Text */
    font-size: 15px;
    color: var( --default-blue-color );
    font-weight: 600;
}

.current-hub-cycle .tableHubCycle-row-container {
    /* Display & Box model */
    grid-template-columns: repeat(5, 1fr);
    background-image: none;
}

.warning {
    /* Display & Box model */
    height: 80px;
    background-color: var( --default-light-red-color );

    /* Text */
    color: white;
    line-height: 80px;
    text-align: center;
}