
#side-menu {
    /* Positioning */
    position: fixed;
    right: 0;
    width: 0;
    height: calc( 100% - 140px );
    /* Display & Box model */
    display: grid;
    grid-template-rows: 180px auto 140px;
    z-index: 111;
    transition: 0.5s;
    box-shadow: 0 0 7px black;
    /* Color */
    background-color: white;
    overflow-y: auto;
    /* Text */

    /* Other */
}

.side-menu-open {
    /* Positioning */
    width: 25% !important;
    
    /* Display & Box model */
    padding: 20px;
    /* Color */

    /* Text */

    /* Other */
}

@media only screen and ( max-width: 1024px ) {
    .side-menu-open {
        /* Positioning */
        width: 250px !important;
        /* Display & Box model */
        padding: 20px;
        /* Color */

        /* Text */

        /* Other */
    }
}