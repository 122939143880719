.shopping-cart-payment-main-container {
     /* Display & Box model */
     display: grid;
     grid-template-rows: auto auto 40px auto auto 120px 40px 221px auto;
}

.delivery-payment-title {
    /* Display & Box model */
    grid-row: 1 / 2;
    padding-bottom: 15px;

    /* Text */
    color: #0E5FA6;
    font-family: 'Segoe UI Bold';
    font-size: 16px; 
    font-weight: bold;

}

.delivery-title {
    /* Display & Box model */
    grid-row: 4 / 5;
    padding-bottom: 15px;

    /* Text */
    color: #0E5FA6;
    font-family: 'Segoe UI Bold';
    font-size: 16px; 
    font-weight: bold;
}

.payment-works-title {
    /* Display & Box model */
    grid-row: 7 / 8;
    padding-bottom: 15px;

    /* Text */
    color: #0E5FA6;
    font-family: 'Segoe UI Bold';
    font-size: 16px; 
    font-weight: bold;
    /* Other */
}

.delivery-payment-form {
    /* Display & Box model */
    padding: 30px 25px 10px 25px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row: 2 / 3;
    background-color: #ebebeb;
}

.order-hub {
    display: grid;
    grid-template-rows: 40px auto;
    letter-spacing: 2px;
}

.buyer-pickup-options {
    display: grid;
    grid-template-columns: 350px min-content;
    grid-gap: 20px;
    justify-self: center;
}

.hub-name-text {
    color: var( --default-blue-color );
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}

.payment-page-delivery-cost {
    /* Positioning */
    margin-top: 10px;
    
    /* Text */
    text-align: left;
}

.option-one-checkbox {
    /* Display & Box model */
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.option-two {
    /* Display & Box model */
    grid-column: 1 / 2;
    grid-row: 2 / 3;

    /* Text */
    letter-spacing: 2px;
}

.option-two-checkbox {
    /* Display & Box model */
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

.where-are-we {
    /* Position */
    justify-self: end;

    /* Display & Box model */
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    width: fit-content;
    height: fit-content;

    /* Text */
    color: grey;
    font-size: 12px;
    text-align: right;

    /* Other */
    cursor: pointer;
}

.where-are-we a {
    text-decoration: none;
    color: grey;
    font-size: 12px;
    text-align: right;
}

.where-are-we-inactive a {
    text-decoration: none;
    font-size: 12px;
    color: #bfbfbf;
}

.prices {
    /* Position */
    justify-self: end;

    /* Display & Box model */
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    height: fit-content;

    /* Text */
    color: grey;
    font-size: 12px;
    text-align: right;
    text-align: left;

    /* Other */
    cursor: pointer;
}

.delivery-form {
    /* Display & Box model */
    padding: 30px 25px 10px 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 26px 26px 26px;
    row-gap: 35px;
    background-color: #ebebeb;
}

.option-three {
    /* Display & Box model */
    grid-column: 1 / 2;
    grid-row: 1 / 2;

    /* Text */
    letter-spacing: 2px;
}

.option-three-checkbox {
    /* Display & Box model */
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.option-four {
    /* Display & Box model */
    grid-column: 1 / 2;
    grid-row: 2 / 3;

    /* Text */
    letter-spacing: 2px;
}

.option-four-checkbox {
    /* Display & Box model */
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

.option-five {
    /* Display & Box model */
    grid-column: 1 / 2;
    grid-row: 3 / 5;

    /* Text */
    letter-spacing: 2px;
}

.option-five-checkbox {
    /* Display & Box model */
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}

.free-one {
    /* Position */
    justify-self: end;

    /* Display & Box model */
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    width: fit-content;
    height: fit-content;

    /* Other */
    cursor: pointer;
}

.free-two {
    /* Position */
    justify-self: end;

    /* Display & Box model */
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    width: fit-content;
    height: fit-content;

    /* Other */
    cursor: pointer;
}

.free-three {
    /* Position */
    justify-self: end;
    
    /* Display & Box model */
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    width: fit-content;
    height: fit-content;

    /* Other */
    cursor: pointer;
}

.prices-inactive,
.where-are-we-inactive {
    /* Text */
    font-size: 12px;
    color: #bfbfbf;
}


.inactive {
    /* Text */
    font-size: 14px;
    letter-spacing: 2px;
    color: #bfbfbf;
}

.free-one,
.free-two,
.free-three {
    /* Text */
    color: grey;
    font-size: 12px;
    text-align: right;
}

.payment-works {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row: 8 / 9;
    height: 221px;
    background-color: #838383;
    padding-bottom: 15px;
}

.shopping-cart-payment-main-container .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: var( --default-blue-color );
    border-radius: 20px;
    transition: all 0.3s;
  }
  
.shopping-cart-payment-main-container .checkbox:checked + .switch {
    background-color: var( --default-blue-color );
}

.shopping-cart-payment-main-container .button-next-step {
    /* Positioning */
    align-self: center;
    justify-self: end;

     /* Display & Box model */
     grid-row: 6 / 7;
     width: fit-content;
     height: fit-content;

     /* Text */
     color: white;
     font-weight: bold;
     text-align: center;
     text-transform: uppercase;
}

@media only screen and (max-width: 700px) {
    .delivery-form,
    .delivery-payment-form {
        /* Display & Box model */
        grid-template-columns: 1fr;
    }

    .delivery-payment-title,
    .delivery-title,
    .payment-works-title {
        /* Display & Box model */
        padding-left: 15px;
    }
}