.HubInfoDetail-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 7fr 1fr;
    grid-template-rows: 1fr;
}

.HubInfoDetail-content {
    /* Display & Box model */
    padding-top: 40px;
    padding-bottom: 20px;
    display: grid;
    grid-column: 2 / 3;
    grid-template-rows: 50px auto auto auto auto;
    border-bottom: 3px dotted var( --deafult-dark-grey-color );
}

.HubInfoDetail-product-sliders {
    /* Positioning */
    margin-top: 40px;
}

.HubInfoDetail-main-container .shopping-row {
    /* Positioning */
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;

}

@media only screen and (max-width: 700px) {
    .HubInfoDetail-container {
        /* Display & Box model */
        padding: 5px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}