#gs1-container {
    /* Display & Box model */
    display: grid;
}

.grid_gs1_title {
    /* Positioning */
    text-align: left;

    /* Display & Box model */
    grid-row: 1/2;

    /* Text */
    color: var( --default-blue-color );
    font-size: 15px;
    font-weight: 600;
}

/* .gs1-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
} */
