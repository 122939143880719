

#settings-page {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-rows: 70px auto; 
    /* Color */

    /* Text */

    /* Other */
}

.settings-text {
    /* Display & Box model */
    justify-self: center;
    align-self: center;

    /* Text */
    font-size: 26px;
    color: var( --default-blue-color ); 
}

.settings-page-container {
    /* Positioning */
    padding-top: 50px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-auto-rows: minmax(50px, auto);   
}

.setting-page-box {
    /* Positioning */
    line-height: 60px;
    padding: 0 20px 0 20px;
    margin-bottom: 30px;
    

    /* Display & Box model */
    display: grid; 
    align-items: center;
    grid-column: 2;
    border-radius: 8px;

    /* Color */
    background-color:  var( --deafult-grey-color );

    /* Other */
    cursor: pointer;   
}

.settings-page-row {
    /* Display & Box model */
    display: grid; 
    grid-template-columns: auto min-content; 
    align-items: center;
}

p.row-title {
    /* Text */
    font-size: 18px;
    font-weight: 500;
    color: var( --default-blue-color ); 

    /* Other */
    white-space: nowrap;
}

.arrow-style-up {
    /* Positioning */
    height: 8px;
    width: 8px;
    border-left: 2px solid var( --default-orange-color );
    border-top: 2px solid var( --default-orange-color );
    /* Display & Box model */
    justify-self: end;
    -ms-transform: rotate(20deg); /* IE 9 */
    -webkit-transform: rotate(20deg); /* Safari prior 9.0 */
    transform: rotate(45deg); 

}

.arrow-style-down {
    /* Positioning */
    height: 8px;
    width: 8px;
    border-left: 2px solid var( --default-orange-color );
    border-top: 2px solid var( --default-orange-color );
    /* Display & Box model */
    justify-self: end;
    -ms-transform: rotate(20deg); /* IE 9 */
    -webkit-transform: rotate(20deg); /* Safari prior 9.0 */
    transform: rotate(225deg);  

}

.update-user-box {
    margin-bottom: 50px;
}
@media only screen and (max-width: 1024px) {
    .settings-page-container {
        /* Display & Box model */
        grid-template-columns: 5px auto 5px !important;
    }

   .edit-area-settings {
       /* Positioning */
       width: 99% !important;
       min-width: 20px !important;
   }
}
