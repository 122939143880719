#category-dropdown-content {
    /* Positioning */
    position: absolute;
    width: 100%;
    /* padding: 12px 16px; */

    /* Display & Box model */
    display: none;
    border: 1px solid grey;
    border-top: none !important;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;

    /* Color */
    background-color: #f9f9f9;

    /* Text */

    /* Other */
}

#category-dropdown:hover #category-dropdown-content {
    display: block;
}

#category-dropdown-content > div {
    /* Positioning */
    padding: 5px 10px 5px 10px;
    line-height: 28px;

    /* Display & Box model */
    
    /* Color */
    
    /* Text */
    font-size: 14px;
    letter-spacing: 1px;

    /* Other */
}

#category-dropdown-content > div:hover {    
    /* Color */
    background-color: var( --default-orange-color );

    /* Text */
    color: black !important;

    /* Other */
    cursor: pointer;
}

.mobile {
     /* Positioning */
     position: relative !important;
     margin-top: 12px !important;
     width: 100%;
     /* padding: 12px 16px; */
 
     /* Display & Box model */
     display: none;
     border: none !important;
     box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2) !important;
     z-index: 0 !important;
 
     /* Color */
     background-color: #f9f9f9;
 
     /* Text */
     text-align: left !important;
     color: #ffa731 !important;
 
     /* Other */
}

.buttonMobile {
    /* Positioning */
    padding: 5px 10px 5px 54% !important;
    line-height: 28px;

    /* Display & Box model */
    
    /* Color */
    
    /* Text */
    font-size: 14px;
    letter-spacing: 1px;

    /* Other */
}

@media only screen and (max-width: 768px) {
    #category-dropdown-content-open {
        /* Positioning */
        position: absolute;
        width: 100%;
        /* padding: 12px 16px; */
    
        /* Display & Box model */
        display: block;
        border: 1px solid grey;
        border-top: none !important;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    
        /* Color */
        background-color: #f9f9f9;
    
        /* Text */
    
        /* Other */
    }
}