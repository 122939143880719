#uhub-header {
	/* Positioning */
	width: 100%;
    height: 70px !important;
	/* Display & Box model */
	display: grid !important;
	grid-template-columns: 200px 1fr 340px;
	align-items: center;
	justify-items: center;
	/* Color */
	background-color: var( --default-blue-color ) !important;
   	/* Text */
   	/* Other */
}

.uhub-icon {
	/* Positioning */
	
    /* Display & Box model */
	justify-self: start;
	padding-left: 40px;
	
    /* Color */

    /* Text */
	color: white;
    font-weight: bold;
	/* Other */
	cursor: pointer;
}

.uhub-title {
	font-family: 'Segoe UI Semibold';
	font-size: 42px;
}

.uhub-icon .tooltip-bottom {
	top: 60px important!;
}

@media only screen and (max-width: 1024px) {
	#header {
		/* Display & Box model */
		grid-template-columns: auto auto !important;
	}

	.uhub-icon {
		/* Display & Box model */
		grid-column: 1/2;
		grid-row: 1/2;
	}
}

.react-tooltip-lite {
	background: #333;
	color: white;
	font-size: 12px;
  }
  
.react-tooltip-lite-arrow {
	border-color: #333;
}

.header-overlay {
	/* Positioning */
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    /* Display & Box model */
    height: 70px;
    display: grid;
    background-color: rgba( 0,0,0,0.3);

    /* Other */
    z-index: 1;
}