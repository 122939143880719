.tableMyOrder-row-container {
    /* Display & Box model */
    /* height: 51px; */
    padding-top: 5px;
    padding-bottom: 5px;
    display: grid;
    grid-template-columns: 120px repeat(10, calc( calc( 100% / 10 ) - 12px) );
    background-image: linear-gradient(to right, var( --deafult-dark-grey-color ) 20%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 2px;
    background-repeat: repeat-x;
    align-items: center;

    /* Text */
    font-size: 14px;

    /* Other */
    cursor: pointer;
}

.tableHubCycle-code {
    /* Display & Box model */
    /* padding-left: 20px; */
    
    /* Text */
    font-family: 'Segoe UI Bold';
    font-size: 17px;
    color: var( --default-blue-color );
    font-weight: 600;
    text-align: left;
    /* line-height: 51px; */
}

.tableHubCycle-aggregation,
.tableHubCycle-product,
.tableHubCycle-provider, 
.tableHubCycle-delivery, 
.tableHubCycle-packaging,
.tableHubCycle-deliverability,
.tableHubCycle-status,
.tableHubCycle-storageLocation {
    /* Text */
    text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    line-height: 51px;
}

.tableHubCycle-quantity,
.tableHubCycle-unit,
.tableHubCycle-price {
     /* Text */
     text-overflow: ellipsis;
     /* Required for text-overflow to do anything */
       white-space: nowrap;
       overflow: hidden;
       text-align: center;
       line-height: 51px;
}