.shopping-card-button {
	/* Positioning */
	position: relative;
  	/* Display & Box model */
	/* padding: 5px 15px; */
  	/* Color */
	/* Text */
	text-decoration: none;
   	/* Other */
	cursor: pointer;
}