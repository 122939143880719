.tabs-bar {
    /* Display and box model */
    display: contents;
}

.tabs-bar .tab-blue {
    /* Position */
    position: relative;

    /* Display and box model */
    min-width: 100%;
    height: 50px;
    border-right: 2px solid white;

    /* Color */
    background-color: #0a60a6;
}

.tabs-bar .tab {
    /* Position */
    position: relative;

    /* Display and box model */
    min-width: 100%;
    height: 50px;
    border-right: 2px solid white;

    /* Color */
    background-color: #ebebeb;
    color: black;

    /* Other */
    cursor: pointer;
}

.tabs-bar .tab-disable {
    /* Position */
    position: relative;

    /* Display and box model */
    min-width: 100%;
    height: 50px;
    border-right: 2px solid white;

    /* Color */
    background-color: #f6f6f6;
    color: black;
}

.tabs-bar .text {
    /* Position */
    z-index: 1;

    /* Text */
    font-size: 12px;
    letter-spacing: 1px;
}

.tabs-bar .text-white {
    /* Text */
    color: white;
    font-size: 12px;
    letter-spacing: 1px;
}

.tabs-bar .text-disable {
    /* Text */
    color: #c3c3c3;
    font-size: 12px;
    letter-spacing: 1px;
}