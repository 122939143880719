.DayPicker {
  display: inline-block;
  background-color: white;
  font-size: 12px;
}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 1em;

  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  height: 245px;
  justify-content: justify;
  padding: 0px;
  overflow: auto;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;

  user-select: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: center;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8B9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}


.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #D0021B;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8B9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color:black;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #2e64b8;
  color: #black;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51A0FA;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #ececeb;
}

.Range .DayPicker-Day {
    border-radius: 0 !important;
}

.Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #ececeb !important;
    color: #2e64b8;
}

/* DayPickerInput */

.DayPickerInput {
  /* Display & Box model */
  display: inline-block;
  background-color: var( --deafult-grey-color );
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  margin-top: 20px;
  left: 0;
  z-index: 1;

  background: white;
  border: 1px solid var( --deafult-dark-grey-color );
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.DayPicker-button {
  /* Display & Box model */
  border-top: 1px solid var( --deafult-dark-grey-color );

  /* Text */
  text-align: center;
}

.DayPicker-button .button {
   /* Positioning */
   margin: 10px;
 
   /* Display & Box model */
   height: 15px;
   max-width: 110px;
   border-radius: 16px;
 
   /* Text */
   padding-bottom: 3px;
   font-size: 14px;
   letter-spacing: 1px;
}

.InputFromTo .button,
.InputFromTo-to .button {
  /* Positioning */
  margin: 10px;
 
  /* Display & Box model */
  height: 15px;
  max-width: 150px;
  border-radius: 16px;

  /* Text */
  padding-bottom: 3px;
  font-size: 12px;
}

.DayPickerInput input {
/* Display & Box model */
padding-left: 5px;
height: 25px;
}

.DayPickerInput-bold-text {
/* Display & Box model */
display: inline;

/* Text */
font-family: 'segoe UI bold';
}
