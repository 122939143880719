.shopping-list-info-hub,
.shopping-list-info-hub-full {
    /* Positioning */
    margin-bottom: 30px;
    /* Display & Box model */
    display: grid;
    grid-template-rows: 70px auto 70px;
    grid-template-columns: 320px auto 314px;
    grid-area: hub-info;
    border-bottom: 5px dotted #ececec;
    /* Color */

    /* Text */

    /* Other */
}

.hubInfo-back-to-hubs {
    /* Positioning */
    align-self: center;
    justify-self: start;
    /* Display & Box model */
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    width: fit-content;

    /* Other */
    cursor: pointer;
}

.hubInfo-back-to-hubs .text {
    /* Display & Box model */
    padding-left: 5px;

    /* Text */
    color: #565655;
    font-size: 12px;
    font-weight: bold;
}


.hub-title {
    /* Positioning */
    justify-self: center;
    align-self: center;

   /* Display & Box model */
   grid-row: 1 / 2;
   grid-column: 2 / 3;
   width: fit-content;
   /* Color */

   /* Text */
   color: #0e5fa6;
   font-size: 20px;

   /* Other */
}

.hub-grade {
    /* Positioning */
    align-self: center;

    /* Display & Box model */
    grid-row: 1 / 2;
    grid-column: 3 / 4;
    width: fit-content;
}

.shopping-list-info-hub .cycle-data,
.shopping-list-info-hub-full .cycle-data {
    /* Positioning */
    margin-bottom: 10px;

    /* Text */
    font-size: 13px;
    font-family: 'Segoe UI', sans-serif; 
    color: grey;
    text-align: center
}

.shopping-list-info-hub .delivery-prices,
.shopping-list-info-hub-full .delivery-prices {
    /* Positioning */
    margin: auto;

    /* Display & Box model */
    width: fit-content;

    /* Text */
    color: grey;
    text-align: center;
}

.shopping-list-info-hub .bottom-data,
.shopping-list-info-hub-full .bottom-data {
     /* Text */
     color: grey;
     text-align: left;
}

.hub-description {
    /* Positioning */

    /* Display & Box model */
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    padding-left: 50px;
    padding-right: 50px;
    /* Color */

    /* Text */
    color: grey;
    text-align: justify;

    /* Other */
}

.shopping-list-info-hub .shopping-list-button-show-more,
.shopping-list-info-hub-full .shopping-list-button-show-more {
    /* Positioning */
    justify-self: center;
    align-self: center;

    /* Display & Box model */
    grid-row: 3 / 4;
    grid-column: 2 / 3;
    width: fit-content;
    height: 20px;
    padding: 5px 15px 5px 15px;
    border: 2px solid #ececec;
    border-radius: 20px;

    /* Text */
    color: grey;

    /* Other */
    cursor: pointer;
}

.shopping-list-info-hub .hub-distance-container,
.shopping-list-info-hub-full .hub-distance-container {
    /* Display & Box model */
    grid-row: 2 / 3;
    grid-column: 3 / 4;
    width: 84px;
    height: 37px;
    border-radius: 10px;
}

@media only screen and ( max-width: 640px ) {
    .shopping-list-info-hub-full {
        /* Display & Box model */
        display: none;
    }
}

@media only screen and ( max-width: 1024px ) {
    .shopping-list-info-hub,
    .shopping-list-info-hub-full {
        /* Display & Box model */
        grid-template-rows: 20px 45px 35px 107px auto 70px;
        grid-template-columns: 1fr;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
    }

    .hubInfo-back-to-hubs {
        /* Display & Box model */
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        padding-bottom: 10px;
    }
    
    .hub-title {
        /* Display & Box model */
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        padding-bottom: 10px;
    }
    
    .hub-grade {
        /* Positioning */
        justify-self: center;

        /* Display & Box model */
        grid-row: 3 / 4;
        grid-column: 1 / 2;
        padding-bottom: 10px;
        width: fit-content;
    }

    .hub-cycle-info {
        /* Display & Box model */
        grid-row: 4 / 5;
    }
    
    .shopping-list-info-hub .hub-distance-container,
    .shopping-list-info-hub-full .hub-distance-container {
        /* Positioning */
        justify-self: center;

        /* Display & Box model */
        grid-row: 5 / 6;
        grid-column: 1 / 2;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    
    .hub-description {
        /* Display & Box model */
        grid-row: 6 / 7;
        grid-column: 1 / 2;
        padding-left: 10px;
        padding-right: 10px;
    }
    
    .shopping-list-info-hub .shopping-list-button-show-more,
    .shopping-list-info-hub-full .shopping-list-button-show-more {
        /* Positioning */
        margin-bottom: 20px;

        /* Display & Box model */
        grid-row: 7 / 8;
        grid-column: 1 / 2;

        /* Other */
        cursor: pointer;
    }
}