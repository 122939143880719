
.hub-view-cycle-dropdown {
    /* Positioning */
    position: relative;
    display: inline-block;
    width: 150px;

    /* Display & Box model */

    /* Color */

    /* Text */

    /* Other */
}

.hub-view-dropdown-value {
    /* Positioning */
    width: 100%;
    line-height: 24px;

    /* Display & Box model */
    border: 1px solid var( --default-orange-color );
    
    /* Color */
    background-color: grey;

    /* Text */
    text-align: center;
    color: white;

    /* Other */
    cursor: pointer;
}

.hub-view-cycle-dropdown-content {
    /* Positioning */
    width: 100%;
    position: absolute;
    z-index: 1;

    /* Display & Box model */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

    /* Color */
    background-color: white;
    
    /* Text */
    text-align: center;

    /* Other */
    cursor: pointer;
}

.hub-view-cycle-dropdown-content > div {
    /* Positioning */
    line-height: 24px;

    /* Display & Box model */
    border-bottom: 1px solid #e2e2e2;
    /* Color */

    /* Text */

    /* Other */
}

.hub-view-cycle-dropdown-content > div:hover {
    /* Color */
    background-color: var( --default-orange-color );
}


.hub-cost-for-intervention-container{
    display: grid;
    grid-template-columns: 35% 35%;
    margin: 50px 0 10px 0;
    padding-top: 20px;
    height: 110px;
    position: relative;

}

.hub-cost-for-intervention-dropdown-value{
    border: 1px solid gray  ;
}

.hub-cost-for-intervention-dropdown{
    width: 100px;
    text-align: center;
}

.intervention-cost-values{
    cursor: pointer;
}


.hub-cost-for-intervention-dropdown-content{

    background-color: wheat ;
    max-height:100px;
   overflow:scroll; 
}

.hub-price-list-buttons-add-commision{
    display: grid;
    grid-column: 1;
} 

.hub-cost-for-intervention-dropdown-box{
    display: grid;
    grid-column: 2;
    width: 80px;
    text-align: center;
    align-items: center;
    position: absolute;
    top: 15px;
    left: 0;
}

.hub-cost-for-intervention-dropdown-value{
    display: grid;
    grid-template-columns: 80% 20%;
    cursor: pointer;

}

.hub-cost-for-intervention-text{
   
}

#arrow-down {
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 6px solid rgb(0, 0, 0);
    align-self: center;
  }

