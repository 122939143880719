#hub-price-list-row-data {
    /* Positioning */
    padding: 15px 0 15px 0;
    
    /* Display & Box model */
    display: grid;
    grid-template-columns: repeat( 5, 1fr ) 40px;
    justify-items: center;

    /* Color */
    
    /* Text */

    /* Other */
    background-image: linear-gradient(to right, #333 20%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x;
}

.price-area-pricelist{
    display: grid;
    grid-template-columns: repeat(2, auto );
    grid-column-gap: 3px;
}

#hub-price-list-row-data .edit-area {
    text-align: center;
}

.currency-box-center {
    align-self: center;
}

#delete-pricelist-row-button{
    cursor: pointer;
}
