#hub-component-holder {
    /* Positioning */
    width: calc( 100% - 20px );
    padding: 10px;

    /* Display & Box model */
    display: grid;
    grid-gap: 15px;
    justify-items: center;

    /* Color */
    
    /* Text */

    /* Other */
    cursor: pointer;
}

#hub-component-hub-info {
    /* Positioning */
    width: 100%;

    /* Display & Box model */
    display: grid;
    grid-template-columns: auto auto;
    align-content: center;
    /* Color */

    /* Text */

    /* Other */
}

.hub-selected {
    cursor: default !important;
}

.hub-selected .hub-base-info > p { 
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    color: #0660ab !important;

    /* Text */

    /* Other */
}

.hub-component-bottom-icons {
    /* Positioning */
    margin-right: 20px;

    /* Display & Box model */
    justify-self: end;
    display: grid;
    grid-template-columns: min-content min-content;
    grid-gap: 10px;

    /* Color */
    
    /* Text */

    /* Other */
}

.hub-text {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    color: #6c6b6b;

    /* Text */
    font-size: 16px;

    /* Other */
}

.distance-text {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    color: #737778;

    /* Text */
    font-size: 9px;
    letter-spacing: 0.5px;

    /* Other */
}

.distance-value-text {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    color: #6c6b6b;

    /* Text */
    font-size: 18px;
    letter-spacing: 0.5px;

    /* Other */ 
}

.hub-location-text {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    color: #6c6b6b;

    /* Text */
    font-family: 'Segoe UI Bold';
    font-size: 18px;
    letter-spacing: 1px;

    /* Other */
}

.description-text {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    
    /* Text */
    font-size: 12px;

    /* Other */
}

.cycle-data {
    /* Positioning */
    
    /* Display & Box model */
    display: block;

    /* Color */
    
    /* Text */
    font-family: 'Segoe UI Semibold';
    font-size: 12px;

    /* Other */
}

.hub-distance-container {
    /* Positioning */
    
    /* Display & Box model */
    padding: 10px;
    border-radius: 10px;

    /* Color */
    background-color: #ececec;

    /* Text */

    /* Other */
}

.hub-component-space-between-hubs {
    /* Positioning */
    margin-bottom: 10px;

    /* Display & Box model */
    height: 10px;
    width: 100%;
    margin-bottom: 10px;

    /* Color */
    background-color: #ececec;

    /* Text */

    /* Other */
    z-index: 1;
}

.hub-component-login {
    /* Positioning */
    width: 100%;

    /* Display & Box model */

    /* Color */
    color: #0660ab;

    /* Text */
    font-size: 14px;

    /* Other */
    cursor: pointer;
}

.show-hubs-container .tooltip-right {
    /* Positioning */
    top: 229px !important;
    left: 105%;
}