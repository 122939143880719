#category-dropdown-content-mobile {
    /* Positioning */
    position: absolute;
    left: -33px;
    width: calc( 100% + 33px );
    max-height: calc(100vh - 103px);
    /* padding: 12px 16px; */

    /* Display & Box model */
    display: none;
    border: 1px solid grey;
    border-top: none !important;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;

    /* Color */
    background-color: #f9f9f9;

    /* Text */

    /* Other */
    overflow-y: auto;
}

#category-dropdown-mobile:hover:focus:active #category-dropdown-content-mobile {
    display: block;
}

#category-dropdown-content-mobile > div {
    /* Positioning */
    padding: 5px 0px 0px 0px;
    line-height: 28px;

    /* Display & Box model */
    
    /* Color */
    
    /* Text */
    font-size: 14px;
    letter-spacing: 1px;

    /* Other */
}

#category-dropdown-content-mobile > div:hover {    
    /* Color */
    background-color: #ececec !important;

    /* Other */
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    #category-dropdown-content-mobile-open {
        /* Positioning */
        position: absolute;
        left: -33px;
        width: calc( 100% + 33px );
        max-height: calc(100vh - 103px);
        /* padding: 12px 16px; */
    
        /* Display & Box model */
        display: block;
        border: 1px solid grey;
        border-top: none !important;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    
        /* Color */
        background-color: #f9f9f9;
    
        /* Text */
    
        /* Other */
        overflow-y: auto;
    }
    
    #category-dropdown-content-mobile-open  > div {
        /* Positioning */
        padding: 5px 0px 0px 0px;
        line-height: 28px;
    
        /* Display & Box model */
        
        /* Color */
        
        /* Text */
        font-size: 14px;
        letter-spacing: 1px;
    
        /* Other */
    }
}