.navContainer {
    position: absolute;
    top: 10px;
    left: 10px;
    margin-bottom: 10px;
    right: 10px;
    width: 180px;
    height: 30px;
    border-bottom: 1px solid var( --deafult-dark-grey-color );
}
.navLeftLeft {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: none;
}
.navLeftRight {
    position: absolute;
    top: 0px;
    right: 208px;
    background-color: none;
}
.navRightLeft {
    position: absolute;
    top: 0px;
    left: 208px;
    background-color: none;
}
.navRightRight {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: none;
}
.navArrows {
    height: 25px;
}