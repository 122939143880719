.content-registrationtype {
    /* Position*/
    position: absolute;
    top: 70px;
    bottom: 0px;

    /* Display & box model */
    width: 100%;

    /* Other */
    overflow-y: auto;
}

.registrationtype-container {
    /* Positioning */
    position: relative;
    top: 0px;
    bottom: 0px;
    min-height: 100%;
    width:  100%;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    grid-template-rows: repeat(6, 2fr);
    grid-row-gap: 10px;
    

    /* Color */
    background-color: var( --deafult-grey-color );
}

#back-registration-button{
    grid-column: 1;
    grid-row: 1;
    align-self: center !important;
}

.registration-title {
    /* Display & Box model */
    grid-column: 2;
    grid-row: 1;
    align-self: center;

    /* Text */
    font-size: 25pt;
    text-align: center;
    color: var( --default-blue-color );
}

.box-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column: 2;
}

.type-box {
    /* Positioning */
    height: 80px;
    /* Display & Box model */
    display: grid;
    grid-column: 2;
    align-content: center;                                                                                                                                                                                                                                                                           
    /* Color */

    /* Text */
    text-transform: uppercase;
    text-align: center;
    color: var( --default-blue-color );
    font-size: 17px;
    
    /* Other */ 
    border-radius: 10px;
    background-color: var( --default-orange-color );

}

.type-box:hover {
    /* Other */ 
    box-shadow: 0 0 11px rgba(33,33,33,.4); 
  }

.provider-box {  
    /* Display & Box model */
    grid-column: 4; 

}

@media only screen and (max-width: 700px) {
    /* .registration-container {
        /* Display & Box model */
        /*grid-template-rows: 207px 287px auto auto;
    } */

    .registrationtype-container{
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
        grid-template-rows: 1fr 1fr 4fr;
    }

    .box-container {
        /* Display & Box model */
        grid-template-columns: 10px auto 10px;
        grid-template-rows: repeat(4, 1fr);
        row-gap: 10px;
        grid-row: 3;
        grid-column: 2;
        
    }
    .registration-title {
        grid-column: 2;
        grid-row: 2;
    }
    

    .type-box {
        /* Positioning */
        height: 80px;

        /* Display & Box model */
        display: grid;
        grid-column: 2;
    }   
   
} 