.TableHubOrderHeader-container span {
    /* Display & Box model */
    display: inline-block;
}

.TableHubOrderHeader-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 7% 8% 14% 12% 7% 22% 7% 18% 0%;

    /* Text */
    color: var( --deafult-dark-grey-color );
    letter-spacing: 1px;
    font-size: 12px;
}

.TableHubOrderHeader-container .icon-slider-arroow-06::before {
    /* Display & Box model */
    width: 12px !important;
    height: 12px !important;

    /* Text */
    color: var( --deafult-dark-grey-color );
}

.TableHubOrderHeader-container .sort-arrow-up  {
   /* Display & Box model */
   width: 21px;
   height: 10px;
   transform: rotate(270deg);

   /* Other */
   cursor: pointer;
}

.TableHubOrderHeader-container .sort-arrow-down {
    /* Display & Box model */
    width: 20px;
    height: 28px;
    transform: rotate(90deg);

    /* Other */
    cursor: pointer;
}

.filter-icon {
    /* Positioning */
    position: relative;
    bottom: -11px;
    left: 9px;

    /* Other */
    cursor: pointer;
}

.code-text,
.totalPrice-text,
.customer-text,
.status-text,
.logistic-text,
.orderAddress-text,
.date-text,
.driver-text {
    /* Other */
    cursor: pointer;
}

.TableHubOrderHeader-container .status-text {
    text-align: left !important;
}

.code-text {
    margin-left: 19px !important;
}

.totalPrice-text {
    /* Positioning */
    /* margin-top: 18px; */
}

.TableHubOrderHeader-container .icon-filter-icon::before {
    /* Text */
    color: inherit;
}