
.shopping-container {
    /* Positioning */
    position: relative;
    /*height: 100%;*/
    width: 99%;

    /* Display & Box model */

    /* Color */

    /* Text */

    /* Other */
}

#hub-menu {
    /* Positioning */
    position: relative;
    height: 100%;
    width: 280px;
    z-index: 0;

    /* Display & Box model */
    display: inline-block;
    /* Color */
    background: white;

    /* Text */
    
    /* Other */
}

.white-background-full {
    /* Positioning */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;

    /* Display & Box model */
    width: 280px;

    /* Color */
    background: white;

    /* Other */
    transition: 600ms;
}

.white-background {
    /* Positioning */
    position: absolute;
    top: 0;
    bottom: 0;
    left: -280px;

    /* Display & Box model */
    width: 280px;

    /* Color */
    background: white;

    /* Other */
    transition: 600ms;
}

.divider-shaddow-full {
    /* Positioning */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 280px;
    height: 100%;
    z-index: -1;

    /* Display & Box model */
    display: inline-block;
    width: 0px;
    height: 100%;
    box-shadow: 3px -5px 5px 6px #ccc;

    /* Color */

    /* Text */
    
    /* Other */
    transition: 600ms;
}

.divider-shaddow {
    /* Positioning */
    position: absolute;
    top: 0;
    left: 0px;
    bottom: 0;
    height: 100%;
    z-index: -1;

    /* Display & Box model */
    display: inline-block;
    width: 0px;
    height: 100%;
    box-shadow: 3px 3px 5px 6px #ccc;

    /* Color */

    /* Text */
    
    /* Other */
    transition: 600ms;
}

.toggle-button,
.toggle-button-hide {
    /* Positioning */
    justify-self: right;

    /* Display & Box model */
    grid-column: 3 / 4;
}

@media only screen and (max-width: 640px) {
    .hub-menu-open {
        /* Positioning */
        width: 280px !important;  
    }

    .shopping-grid-threeth {
        /* Positioning */
        /*width: 15% !important;*/
    }

    .shopping-grid-threeth .shopping-list-container {
        /* Display & Box model */
        display: none !important;
    }

    .tooltip-text-right {
        /* Display & Box model */
        display: none !important;
    }

    #shopping-grid .tooltip-bottom {
        /* Positioning */
        display: none !important;
    }
}

.fetch-more {
    /* Positioning */
    margin: 50px auto 50px auto;

    /* Display & Box model */
    width: 250px;
    height: 40px;
    background-color: var( --default-blue-color );
    border-radius: 20px;

    /* Text */
    color: white;
    font-weight: bold;
    text-align: center;
    line-height: 40px;

    /* Other */
    cursor: pointer;
}

.fetch-more-disabled {
    /* Positioning */
    margin: 50px auto 50px auto;

    /* Display & Box model */
    width: 250px;
    height: 40px;
    background-color: var( --deafult-dark-grey-color );
    border-radius: 20px;

    /* Text */
    color: black;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
}

@media only screen and (max-width: 640px) {
    .toggle-button {
        /* Positioning */
        justify-self: center;
    
        /* Display & Box model */
        grid-column: 2 / 4;
    }

    .toggle-button-hide {
         /* Display & Box model */
         display: none;
    }
}

#shopping-grid {
    /* Positioning */
    position: relative;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 30px calc( 100% - 187px ) 155px;
    grid-template-rows: 22px auto auto auto;
    grid-template-areas: 
            "btn-hub . btn-toggle" 
            " . provider-info provider-info"
            " . hub-info hub-info"
            "items-container items-container items-container";
    height: calc( 100% - 30px );
    padding-top: 20px;
    /*box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.7);*/
    /* Color */

    /* Text */

    /* Other */
}

.hub-menu-open {
    position: relative;
    left: 0;

    /* Display & Box model */
    display: inline-block;

    transition: 600ms;
}

.hub-menu-closed {
    position: relative;
    left: -280px;
    
    /* Display & Box model */
    display: inline-block;
    transition: 600ms;
}

.shopping-grid-threeth {
    transition: 600ms;
}

.shopping-grid-full {
    margin-left: 0px !important;
    transition: 600ms;
}

.shopping-list-container {
    /* Positioning */
    padding: 10px;
    
    /* Display & Box model */
    grid-area: items-container;

    /* Color */

    /* Text */

    /* Other */
    /* overflow-y: auto; */
}

.container-layout {
    position: relative;
    display: inline-block;
    width: calc( 100% - 0px);
    margin-left: 0px;
    transition: 600ms;
    margin-left: -280px;
}

.container-layout-full {
    position: relative;
    display: inline-block;
    width: calc( 100% - 280px);
    margin-left: 0px;
    transition: 600ms;
    /*margin-left: -320px;*/
}

#shopping-grid .tooltip-bottom {
    /* Positioning */
    top: 140% !important;
}
