#current-orders-header {
    /* Positioning */
    margin-bottom: 30px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: auto auto;
    /* Color */

    /* Text */

    /* Other */
}

.current-orders-text {
    /* Positioning */
    
    /* Display & Box model */

    /* Color */

    /* Text */
    color: #0E5FA6;
    letter-spacing: 1px;
    /* Other */
}

.number-of-orders {
    /* Positioning */
    
    /* Display & Box model */
    justify-self: end;

    /* Color */

    /* Text */
    letter-spacing: 1px;
    font-size: 12px;
    /* Other */
}