#star-container {
   /* Positioning */
    width: 100%;

	/* Display & Box model */
	display: grid;
	grid-template-columns: min-content min-content min-content min-content min-content;

    /* Color */

    /* Text */

    /* Other */
    cursor: pointer;
}

#star-container > div {
    margin-right: 3px;
}