.user-profile-container {
    /* Positioning */
    display: grid;
    grid-template-columns: min-content auto 10%;
    grid-column-gap: 10px;
}

.input-line {
    /* Positioning */
    width: 40%;
}

.name-settings {
    /* Positioning */
    text-align: right;
    color: var( --deafult-dark-grey-color );
    font-weight: bold;
    white-space: nowrap;
    
    /* Display & Box model */
    grid-column: 1;
}


.my-profile-button-container {
    /* Positioning */
    margin: 15px 0 15px 0;

    /* Display & Box model */
    display: grid;
    
    grid-column: 2;


    /* Color */
    
    /* Text */

    /* Other */
}

.my-profile-save-button {
    /* Positioning */
    border-radius: 15px;
    width:100%;
    height: 35px;

    /* Display & Box model */ 
    grid-column: 2;

    /* Color */
    background-color: var( --default-blue-color );
    color: white;
    /* Text */
    font-family: 'Segoe UI Semibold';
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    /* Other */    
    cursor: pointer;
}


.back-button {
    /* Positioning */

    /* Display & Box model */
    grid-column: 1;
}

.edit-area-settings {
    /* Positioning */
    height: calc( 100% - 40px ) !important;
    width: 99%;
    min-width: min-content;


    /* Display & Box model */
    align-self: center;
}

.edit-area-red {
    color: red;
    border-color: red;
}

.settings-button {
    /* Positioning */
    width: 100%;
    height: 38px;
    padding:0.3em 1.2em;
    margin:0 0.3em 0.3em 0;
    border-radius:2em;

    /* Display & Box model */
    display:inline-block;
    box-sizing: border-box;

     /* Text */
    text-decoration:none;
    font-weight:300;
    font-family: 'Segoe UI Semibold';
    font-size: 12px !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;

    /* Color */
    color:#FFFFFF;
    background-color: var( --default-blue-color );  

    /* Other */ 
    transition: all 0.2s;
    
}

@media only screen and (max-width: 600px) {
    .user-profile-container{
        grid-template-columns: auto;
        text-align: center !important;
    }

    .name-settings{
        text-align: left;
    }

    .my-profile-button-container{
        grid-column: 1;
    }

    .setting-page-box{
        line-height: 40px;
    }
} 

@media all and (max-width:30em){
     settings-button{
        /* Positioning */
        margin:0.2em auto;

        /* Display & Box model */
        display:block;
        
    }    
}
    