#reset-shopping-list {
    /* Positioning */
    padding-top: 20px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: auto 50px;
    grid-column-gap: 10px;
    align-items: center;
    
    /* Color */

    /* Text */

    /* Other */
    cursor: pointer;
}

.reset-shopping-list-text {
    /* Positioning */
    justify-self: end;

    /* Display & Box model */
    
    /* Color */

    /* Text */
    font-size: 13px;
    letter-spacing: 1px;
    
    /* Other */
    user-select: none;
}