.progress-bar {
    /* Display and box model */
    display: contents;
}

.step-brick {
    /* Display and box model */
    min-width: 100%;
    height: 50px;
    border-right: 2px solid #ececec;

    /* Text */
    font-size: 12px;
    letter-spacing: 1px;

    /* Color */
    background-color: white;
}

.step-brick-blue {
    /* Position */
    position: relative;

    /* Display and box model */
    min-width: 100%;
    height: 50px;

    /* Color */
    background-color: #0a60a6;
}

.step-brick-blue-last {
    /* Position */
    position: relative;

    /* Display and box model */
    min-width: 100%;
    height: 50px;


    /* Color */
    background-color: #0a60a6;
}

.step-brick-blue-noarrow {
    /* Position */
    position: relative;

    /* Display and box model */
    min-width: 100%;
    height: 50px;
    border-right: 1px solid #ececec;


    /* Color */
    background-color: white;
    color: black;
}

.step-brick-blue::after {
    content: ""; 
    position: absolute; 
    right: -15px; 
    bottom: 0;
    height: 0; 
    border-left: 15px solid #0a60a6; 
    border-top: 25px solid transparent; 
    border-bottom: 25px solid transparent;
}

.text {
    /* Position */
    margin-top: 17px;
    z-index: 1;

    /* Text */
    font-size: 12px;
}

.text-white {
    /* Text */
    color: white;
    font-size: 12px;
    letter-spacing: 1px;
}

@media only screen and (max-width: 496px) {
	.arrow {
		/* Display & Box model */
		grid-template-columns: 50px 50px;
	}
}