.show-hubs-container{
    /* Positioning */
    
    /* Display & Box model */
    height: 100%;
    /* Color */
    
    /* Text */

    /* Other */
}

#hub-menu-container {
    /* Positioning */
    padding-top: 19px;
    height: 97.5%;
    /* Display & Box model */
    display: grid;
    grid-template-rows: 30px 70px 10px auto;
    /* Color */
    
    /* Text */

    /* Other */
}

.choose-hub-banner {
    /* Positioning */
    line-height: 30px;
    /* Display & Box model */
    border-right: 2px solid #ffd47d;
    /* Color */
    background-color: #f8a800;
    /* Text */
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-family: 'Segoe UI Semibold';
    letter-spacing: 1px;
    font-size: 18px;
    /* Other */
}

.blue-banner {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */
    background-color: #0660ab;
    /* Text */

    /* Other */
}

.text-banner {
    /* Positioning */
    padding: 10px;
    /* Display & Box model */
    
    /* Color */
    
    /* Text */
    text-align: center;
    letter-spacing: 1px;
    font-size: 16px;
    /* Other */

} 

@media only screen and (max-width: 360px) {
    #hub-menu-container{
        /* Positioning */

        /* Display & Box model */
        height: 97%;
        /* Color */

        /* Text */

        /* Other */
    }
}

@media only screen and (max-width: 375px) {
    #hub-menu-container{
        /* Positioning */

        /* Display & Box model */
        height: 96%;
        /* Color */

        /* Text */

        /* Other */       
    }
}

@media only screen and (max-width: 414px) {
    #hub-menu-container{
        /* Positioning */

        /* Display & Box model */
        height: 96%;
        /* Color */

        /* Text */

        /* Other */
    }
}