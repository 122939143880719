#hub-section-edit {
    /* Positioning */
    
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Color */

    /* Text */

    /* Other */
}

.hub-section-edit-button {
    /* Positioning */
    width: 180px;
    height: 30px;

    /* Display & Box model */
    border-radius: 15px;
    justify-self: center;

    /* Color */
    background-color: var( --default-blue-color );
    color: white;

    /* Text */
    font-family: 'Segoe UI Semibold';
    letter-spacing: 1px;
    text-transform: uppercase;
    
    /* Other */
    cursor: pointer;
}

.hubView-btn-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: min-content min-content;
    grid-column-gap: 10px;
}