
#hub-row-line {
    /* Positioning */
    width: 100%;

    /* Display & Box model */
    display: grid;
    grid-template-columns: auto 1fr 1fr auto;
    grid-gap: 30px;
}

.hub-row-line-info {
    /* Positioning */
    padding-top: 18px;
    padding-bottom: 22px;

    /* Display & Box model */
    display: grid;
}

.hub-row-text{  
    /* Color */
    color: var( --default-blue-color );
}

.hub-row-text-name{
    /* Color */
    color: var( --default-blue-color );

    /* Text */
    font-family: 'Segoe UI Semibold';

    /* Other */
    text-transform: uppercase;
}

.hub-row-star {
    /* Positioning */
    padding-bottom: 15px;
}

.hub-row-description {
    /* Positioning */
    max-width: 100%;

    /* Display & Box model */
    align-self: end;

    /* Color */

    /* Text */
    font-size: 12px;

    /* Other */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hub-row-line-cycle {
    /* Positioning */
    padding-top: 18px;
    padding-bottom: 20px;

    /* Display & Box model */
    display: grid;
    justify-items: center;
    align-self: end;

    /* Text */
    font-size: 12px;

    /* Other */
    white-space: nowrap;
}

#my-hub-buttons-container{
    /* Display & Box model */
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 10px;
}


.hub-row-line-show-sla{
    /* Positioning */
    width: 100%;
    height: 30px;

    /* Display & Box model */
    align-self: end;
    justify-self: start;
    display: grid;
    grid-template-columns: auto auto;
    grid-column: 4;
    align-items: center;

    /* Color */
    
    /* Text */
    text-align: center;
    font-family: 'Segoe UI';
    letter-spacing: 1px;
    text-transform: uppercase;
    color: black;

    /* Other */
    cursor: pointer;
    border-radius: 15px;
    
}

.hub-row-line-show-hub {
    /* Positioning */
    padding-bottom: 20px;
    
    /* Display & Box model */
    align-self: end;
    display: grid;
    grid-template-columns: auto auto;
    grid-column: 4;
    grid-row: 2;
    align-items: center;
    /* Color */

    /* Text */
    color: var( --default-orange-color );

    /* Other */
    cursor: pointer;
}

.hub-row-line-arrow-right {
    /* Positioning */
    width: 10px;
    height: 10px;
    margin-left: 10px;
    border-top: 2px solid var( --default-orange-color );
    border-right: 2px solid var( --default-orange-color );

    /* Other */
    transform: rotate( 45deg );
}

#black-arrow{
    /* Positioning */
    border-top: 2px solid black;
    border-right: 2px solid black;

}

#sla-view-container{
    /* Display & Box model */
    display: grid;
    grid-template-rows: 30px auto;
    grid-gap: 20px;
}

#noMarketWarning{
    /* Text */
    color:var(--default-light-red-color);
}

#my-hubs-actions-sla{
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr;
}

.SLA-selection-buttons{
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.SLA-buttons-container{
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 2;
    grid-column-gap: 15px;
}

.SLA-buttons-container .button{
    /* Positioning */
    width: auto;
    min-width: 150px;
    margin: 10px 0 10px 0 !important;
}

.SLA-buttons-container .button .gumb-sla{
    /* Display & Box model */
    justify-self: end;
}

@media only screen and (max-width: 1024px) {
    #hub-row-line {
        /* Display & Box model */
        grid-template-columns: auto !important;
        grid-template-rows: min-content min-content min-content min-content !important;
        grid-gap: 5px !important;
        justify-items: center;

    }
    .hub-row-line-info {
        /* Display & Box model */
        justify-items: center !important;
    }

    .SLA-selection-buttons{
        /* Display & Box model */
        display: grid;
        grid-template-columns: 1fr;
    }

    .SLA-buttons-container{
        /* Display & Box model */
        grid-column: 1;
        grid-column-gap: 35px;
    }

    
}
