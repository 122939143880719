.shopping-cart-container {
     /* Positioning */
     position: relative;
     top: 0px;
     bottom: 0px;
 
     /* Display & Box model */
     display: grid;
     grid-template-columns: 1fr 7fr 1fr;
     grid-template-rows: 140px 59px auto auto 120px 50px auto 80px;
     min-height: 100%;
     width:  100%;
}

.picture-banner-cart {
     /* Display & Box model */
     grid-column: 1 / 4;
     grid-row: 1 / 2;
     height: 140px;
     width: 100%;
     background: url('/img/marketing-banner-small.png') no-repeat;
     background-size: cover;
}

.shopping-cart-title-container {
     /* Display & Box model */
     display: grid;
     grid-template-columns: 1fr 1fr;
     grid-column: 2 / 3;
     grid-row: 2 / 3;
     height: 59px;
}

.shopping-cart-title {
    /* Positioning */
    align-self: end;

     /* Display & Box model */
     width: fit-content;
     height: fit-content;

     /* Text */
    color: #f8a800;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
}

.back-to-store {
    /* Positioning */
    align-self: end;
    justify-self: end;

     /* Display & Box model */
     width: fit-content;
     height: fit-content;

     /* Text */
     font-size: 11px;
     color: #565655;
     font-weight: bold;

     /* Other */
     cursor: pointer;
}

.back-to-store .text {
     /* Positioning */
     margin-left: 5px;
}

.progress-step-bar-shopping-cart {
     /* Positioning */
     padding-top: 40px;

     /* Display & Box model */
     display: grid;
     grid-template-columns: repeat(5, 1fr);
     grid-column: 2 / 3;
     grid-row: 3 / 4;
     grid-column-gap: 1px;
 
     /* Text */
     text-align: center;
}

.progress-step-bar-shopping-cart .step-brick {
    /* Display and box model */
    border-right: 2px solid white !important;

    /* Color */
    background-color: #ebebeb !important;
}

.progress-step-bar-shopping-cart .step-brick-blue-noarrow {
     /* Display and box model */
     border-right: 2px solid white !important;
 
     /* Color */
     background-color: #ebebeb !important;
 }

.shopping-cart-step-content {
     /* Display & Box model */
     padding-top: 40px;
     padding-bottom: 40px;
     grid-column: 2 / 3;
     grid-row: 4 / 5;
}

.shopping-cart-container .footer-bottom-main {
    /* Display & Box model */
    grid-column: 1 / 4;
    grid-row: 8 / 9; 
}

/* Targeting Edge */
@supports (-ms-ime-align: auto) {
     .progress-step-bar-shopping-cart .progress-bar {
         grid-column: 1/6 !important;
         width: 100% !important;
     }
     
     .progress-step-bar-shopping-cart .progress-bar .step-brick {
         display: inline-block;
         min-width: 19.5%;
     }
 
     .progress-step-bar-shopping-cart .progress-bar .step-brick .text{
         margin-top: 0px;
         padding-top: 17px;
     }
 
     .progress-step-bar-shopping-cart .progress-bar .step-brick-blue {
         display: inline-block;
         min-width: 19.5%;
     }
 
     .progress-step-bar-shopping-cart .progress-bar .step-brick-blue .text-white{
         margin-top: 0px;
         padding-top: 17px;
     }
 }

@media only screen and (max-width: 700px) {
     .progress-step-bar-shopping-cart {
          /* Positioning */
          padding-top: 40px;
     
          /* Display & Box model */
          display: grid;
          grid-template-columns: 1fr;
          grid-column: 2 / 3;
          grid-row: 3 / 4;
          grid-column-gap: 1px;
      
          /* Text */
          text-align: center;
     }

	.shopping-cart-container {
          /* Display & Box model */
          display: grid;
          grid-template-columns: auto 98% auto;
     }

     .shopping-cart-title {
          /* Positioning */
          margin-left: 20px;
     }

     .back-to-store {
          /* Positioning */
          margin-right: 15px;
     }

     .step-brick-blue::after {
          /* Other */
          content: ""; 
          position: absolute;
          top: 50px; 
          right: 0px; 
          bottom: 0;
          height: 0; 
          border-left: 49vw solid transparent; 
          border-right: 49vw solid transparent; 
          border-top: 16px solid #0a60a6;
     }

     .progress-step-bar-shopping-cart .progress-bar {
          grid-column: 1/6;
          width: 100%;
      }
      
      .progress-step-bar-shopping-cart .progress-bar .step-brick {
          min-width: 100%;
      }
      
      .progress-step-bar-shopping-cart .progress-bar .step-brick-blue {
          min-width: 100%;
      }
}