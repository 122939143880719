.PopupAddProduct-popup-main-container {
    /* Positioning */
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    
    /* Display & Box model */
    display: grid;
    background-color: rgba( 0,0,0, 0.3);
    /* Text */
    color: var( --default-orange-color );

    /* Other */
    z-index: 15;
}

.PopupAddProduct-popup-main-container .popup-window {
     /* Positioning */
     margin: 170px auto auto auto;
     
     /* Display & Box model */
     padding: 10px;
     background-color: #fff;
     border-radius: 20px;
     width: 70%;
}

.popup-window .button {
    /* Positioning */
    justify-self: center;

    /* Display & Box model */
    width: 120px;
}

.popup-instructions {
    /* Positioning */
    margin-top: 35px;

    /* Text */
    font-size: 17px;
    color: black;
    text-align: center;
}

.popup-dropDown {
    /* Text */
    text-align: center;
}

option {
    /* Display & Box model */
    background-color: white;

    /* Text */
    font-size: 14px;
    font-family: 'Segoe UI';
}

.popup-dropDown .Triangle {
    /* Positioning */
    top: 11px !important;
    left: 97%;
}


.PopupAddProduct-popup-main-container .popup-buttons {
     /* Display & Box model */
     display: block;

    /*Text*/
    text-align: center; 
}
