.fatalError {
    /* Positioning */
    position: relative;

    /* Display & Box model */
    width: 100%;
    height: calc( 100% - 80px );

    /* Text */
    font-weight: bold;
    color: var( --default-blue-color );
    font-size: 20px;
    line-height: inherit;
    text-align: left;

}

.errorMessage {
    /* Positioning */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* Display & Box model */
    width: fit-content;
}