.tableHubCycleOrder-row-container {
    /* Display & Box model */
    /* height: 51px; */
    padding-top: 5px;
    padding-bottom: 5px;
    display: grid;
    grid-template-columns: 120px repeat(11, calc( calc( 100% / 12 ) - 6px) );
    column-gap: 3px;
    background-image: linear-gradient(to right, var( --deafult-dark-grey-color ) 20%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 2px;
    background-repeat: repeat-x;

    align-items: center;

    /* Text */
    font-size: 14px;

    /* Other */
    cursor: pointer;
}

.tableHubCycle-code {
    /* Display & Box model */
    /* padding-left: 20px; */
    
    /* Text */
    font-family: 'Segoe UI Bold';
    font-size: 17px;
    color: var( --default-blue-color );
    font-weight: 600;
    text-align: left;
    /* line-height: 51px; */
}

.tableHubCycle-aggregation,
.tableHubCycle-product,
.tableHubCycle-provider, 
.tableHubCycle-delivery, 
.tableHubCycle-packaging,
.tableHubCycle-deliverability,
.tableHubCycle-status,
.tableHubCycle-storageLocation {
    /* Display & Box model */
    padding-left: 3px;
    padding-right: 3px;
    
    /* Text */
    font-family: 'Segoe UI';
    letter-spacing: 1px;
    font-size: 12px;
    text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    line-height: 51px;
}

.tableHubCycle-quantity,
.tableHubCycle-unit,
.tableHubCycle-price {
     /* Display & Box model */
     padding-left: 3px;
     padding-right: 3px;

     /* Text */
    font-family: 'Segoe UI';
    letter-spacing: 1px;
    font-size: 12px;
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    line-height: 51px;
}

.item-status-orders {
    /* Display & Box model */
    line-height: 23px !important;

    /* Other */
    border-radius: 5px;
    box-shadow: 2px 2px 2px grey;
    border: 1px solid rgb(236, 236, 236) !important;
    background-color: white;
}


.dropdown-select-my-items-statuses {
    /* Positioning */
    width: 115px;
    height: 30px;
    line-height: 35px;
    border-radius: 5px;
    background-color: white;

    /* Display & Box model */
    display: table-cell;
    vertical-align: middle;
    
    /* Other */
    cursor: pointer;  
}

.dropdown-select-my-items-statuses .Triangle {
    /* Positioning */
    left: 100px;
    top: 0px !important;   
    position: relative;
    left: 90%;
    display: block;
}

.dropdown-select-my-items-statuses .Value {
    /* Positioning */
    position: absolute;
    margin-top: -17px;
    padding-left: 2px !important;

    /* Display & Box model */
    display: inline-block;
    line-height: 14px !important;
    width: 5.5% !important;
    padding-top: 8px;
    padding-bottom: 8px;

    /* Text */
    color: black !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.dropdown-item-list-my-items-statuses {
    /* Positioning */
   margin-top: 15px;
   margin-left: 0px;
   position: absolute;
 
   /* Display & Box model */
   padding-left: 0px;
   padding-right: 0px;
   background-color: white;
   border: 1px solid black;
   width: 6%;
   max-height: 40vh;
   min-height: 0px;
   line-height: 25px;
   height: 140px;
 
   /* Text */
   color: black;
   text-align: left;
   font-size: 13px;
 
   /* Other */
   z-index: 2;
   overflow-y: auto;
 
}

.dropdown-item-list-my-items-statuses .item {
    /* Positioning */
    width: 170px;
    padding-left: 3px;
}
