.image-gallery-title {
    /* Positioning */
    padding-right: 10px;
    padding-left: 10px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr auto 1fr;

    /* Color */
    background-color: white;

    /* Text*/
    font-size: 19px;
    color: var( --default-blue-color );
    line-height: 51px;
}

#image-gallery-container .button {
    /* Positioning */
    justify-self: end;
    margin-right: 0px !important;

    /* Display & Box model */
    grid-column: 3 / 4;
    width: 75px !important;

    /* Text*/
    font-size: 14px;
    line-height: 16px;
}

#image-gallery-container {
    /* Positioning */
    position: absolute;
    left: 20%;
    top: 9%;

    /* Display & Box model */
    width: 60%;
    height: 60%;
    background-color: white;
}

.images-scrollable {
    /* Positioning */
    position: absolute;
    top: 62px;
    left: 6px;
    right: 6px;
    bottom: 55px;

    /* Display & Box model */
    display: flex;
    flex-wrap: wrap;

    /* Other */
    overflow-y: auto;
    overflow-x: hidden;
}

.image-gallery-div {
    /* Positioning */
    position: relative;

    /* Display & Box model */
    flex-basis: calc(25% - 10px);
    margin: 5px;
    border: 1px solid;
    box-sizing: border-box;
}

.image-gallery-div:before {
    /* Display & Box model */
    content: '';
    display: block;
    padding-top: 100%;
}


.image-gallery-div-selected {
    /* Positioning */
    position: relative;

    /* Display & Box model */
    flex-basis: calc(25% - 10px);
    margin: 5px;
    border: 1px solid;
    box-sizing: border-box;
}

.image-gallery-div-upload {
    /* Positioning */
    position: relative;

    /* Display & Box model */
    flex-basis: calc(25% - 10px);
    margin: 5px;
    border: 1px solid;
    box-sizing: border-box;

    /* Display & Box model */
    background-color: grey;

    /* Other */
    cursor: pointer;
}

.image-gallery-div-upload:before {
    /* Positioning */
    position: absolute;

    /* Display & Box model */
    content: "";
    height: 100%;
    width: 100%;
    background: url('/img/Clear.svg') center no-repeat;

    /* Other */
    z-index: 0;
    transform: rotate(45deg);
}

.image-gallery-div-upload:hover {
     /* Other */
     opacity: 0.3;
}

.image-container {
    /* Positioning */
    position: absolute;
    top: 0px;

    /* Display & Box model */
    width: 100%;
    height: 100%;

    /* Other */
    z-index: 1;
    background-size: cover;
}

.image-container .backgroundSelected {
    /* Positioning */
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;

   /* Display & Box model */
   width: 100%;
   height: 100%;

   /* Other */
   z-index: -1;
   opacity: 0.3;
}

.image-container .background {
    /* Positioning */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

     /* Display & Box model */
    width: 100%;
    height: 100%;

    /* Other */
    z-index: -1;
}

.image-container .background:hover {
    /* Other */
    opacity: 0.3;
}

.image-container:hover + #image-gallery-div-circle {
    visibility: visible !important;
}
.image-container:hover ~ #image-galery-default-tag {
    visibility: visible !important;
}

#image-gallery-div-circle {
    /* Positioning */
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;

    /* Display & Box model */
    visibility: hidden;
    border-radius: 100%;
    border: 1px solid grey;
    z-index: 1;

    /* Color */
    background-color: grey;

    /* Other */
    cursor: pointer;
}

#image-gallery-div-circle-visible {
    /* Positioning */
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;

    /* Display & Box model */
    border-radius: 100%;
    border: 1px solid grey;
    z-index: 100;

    /* Color */
    background-color: grey;

    /* Other */
    cursor: pointer;
}

#image-gallery-div-circle:hover {
    visibility: visible !important;
    background-color: var( --default-blue-color ) !important;
}

#image-gallery-div-circle:hover #image-gallery-div-circle-check {
    border-bottom: 2px solid var( --default-orange-color ) !important;
    border-left: 2px solid var( --default-orange-color ) !important;
}

.image-gallery-div-circle-selected {
    background-color: var( --default-blue-color ) !important;
    visibility: visible !important;
}

.image-gallery-div-circle-check-selected {
    border-bottom: 2px solid var( --default-orange-color ) !important;
    border-left: 2px solid var( --default-orange-color ) !important;
}

#image-gallery-div-circle-check {
    /* Positioning */
    margin-top: 4px;
    margin-left: 4px;
    width: 10px;
    height: 5px;

    /* Display & Box model */
    border-bottom: 2px solid white;
    border-left: 2px solid white;
    
    /* Other */
    transform: rotate( -45deg );
    z-index: 1;
}

#image-gallery-remove-btn {
    /* Positioning */
    position: absolute;
    right: 8px;
    top: 4px;

    /* Display & Box model */
    width: 25px;
    height: 25px;
    opacity: 0.3;

    /* Other */
    cursor: pointer;
    z-index: 1;
}

#image-gallery-remove-btn:hover {
    opacity: 1;
}

#image-gallery-remove-btn:before, #image-gallery-remove-btn:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 25px;
    width: 2px;
    background-color: #333;
}

#image-gallery-remove-btn:before {
    transform: rotate(45deg);
}

#image-gallery-remove-btn:after {
    transform: rotate(-45deg);
}

#image-galery-default-tag {
    /* Positioning */
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 60%;
    height: 25px;

    /* Display & Box model */
    border-radius: 10px 0 0 0;
    visibility: hidden;

    /* Color */
    background-color: grey;

    /* Text */
    text-align: center;
    font-family: 'Segoe UI Semibold';

    /* Other */
    text-transform: uppercase;
    cursor: pointer;
    z-index: 1;
}

#image-galery-default-tag-visible {
    /* Positioning */
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 60%;
    height: 25px;

    /* Display & Box model */
    border-radius: 10px 0 0 0;

    /* Color */
    background-color: grey;

    /* Text */
    text-align: center;
    font-family: 'Segoe UI Semibold';

    /* Other */
    text-transform: uppercase;
    cursor: pointer;
    z-index: 1;
}

#image-galery-default-tag:hover {
    visibility: visible !important;
}

.image-galery-default-tag-selected {
    /* Display & Box model */
    visibility: visible !important;

    /* Color */
    background-color: #42f445 !important;
}

.image-gallery-overlay {
    /* Positioning */
    position: fixed;
    top: 105px;
    bottom: 0;
    right: 0;
    left: 0;

    /* Display & Box model */
    height: calc(100% - 105px);
    display: grid;
    background-color: rgba( 0,0,0,0.3);

    /* Other */
    z-index: 1;
}

.image-gallery-overlay-hidden {
    /* Display & Box model */
    display: none;
}

.save-button {
    /* Positioning */
    position: absolute;
    bottom: 0;

    /* Display & Box model */
    width: 100%;

    /* Text */
    text-align: center;
}

.ReactCrop{
    margin: 0 auto;
}


@media only screen and (max-width: 600px) {
    .image-gallery-div {
            flex-basis: calc(50% - 10px);
        }

    .image-gallery-div-selected {
        flex-basis: calc(50% - 10px);
    }
    
    .image-gallery-div-upload {
        flex-basis: calc(50% - 10px);
    }

    .save-button {
        /* Positioning */
        position: absolute;
        bottom: 10px;
    }
}

@media only screen and (max-width: 1024px) { 
    #image-gallery-container {
        position: absolute;
        width: 100%;
        height: 100%;
        margin: auto;
        left: 0%;
        top: 0%;
        background-color: #ebebeb;
    }

    .image-gallery-title {
        /* Positioning */
        padding-right: 10px;
        padding-left: 10px;
        margin-bottom: 20px;

        /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr;
    
        /* Color */
        background-color: #ebebeb;

        /* Text*/
        font-size: 19px;
        line-height: 51px;
    }

    .images-scrollable {
        /* Positioning */
        position: absolute;
        top: 51px;
        left: 6px;
        right: 6px;
        bottom: 75px;

        /* Display & Box model */
        display: flex;
        flex-wrap: wrap;

        /* Other */
        overflow-y: auto;
        overflow-x: hidden;
    }

    .title-gallery,
    .title-text,
    #image-gallery-container .button {
        text-align: center;
    }

    .save-button {
        /* Positioning */
        position: absolute;
        bottom: 10px;
    }

    @media only screen and (orientation: portrait) {
        .images-scrollable {
            /* Positioning */
            position: absolute;
            top: 144px;
            left: 6px;
            right: 6px;
            bottom: 55px;

            /* Display & Box model */
            display: flex;
            flex-wrap: wrap;

            /* Other */
            overflow-y: auto;
            overflow-x: hidden;
        }

        .title-gallery,
        .title-text,
        #image-gallery-container .button {
            grid-column: 1 / 2;
            text-align: center;
        }

        #image-gallery-container .button {
            /* Positioning */
            margin: auto !important;

            /* Display & Box model */
            width: 75px !important;
        }
    }

    @media only screen and (orientation: landscape ) {
        .image-gallery-title {
            /* Display & Box model */
        grid-template-columns: repeat(3, 1fr) !important;
    }

        .images-scrollable {
            /* Positioning */
            position: absolute;
            top: 51px;
            left: 6px;
            right: 6px;
            bottom: 55px;

            /* Display & Box model */
            display: flex;
            flex-wrap: wrap;

            /* Other */
            overflow-y: auto;
            overflow-x: hidden;
        }

        .title-gallery {
            grid-column: 1 / 2;

        }

        .title-text {
            grid-column: 2 / 3;

        }

        .image-gallery-title .button {
            grid-column: 3 / 4;
        }

    }

    .image-container:focus ~ .background::-moz-focus-focus {
        /* Other */
        opacity: 0.3;
    }
    
    .image-container:focus + #image-gallery-div-circle {
        visibility: visible !important;
    }
    .image-container:focus ~ #image-galery-default-tag {
        visibility: visible !important;
    }

    .image-gallery-div-upload:focus {
       /* Other */
       opacity: 0.3;
    }
}
