.logged-in-container {
	/* Positioning */
	height: 100%;
    width: 100%;
    /* Display & Box model */
	display: grid;
	grid-template-columns: auto 60px 60px 60px 40px;
    align-items: center;
    justify-items: center;
    /* Color */

    /* Text */

	/* Other */
}

.logged-in-container .tooltip-bottom {
    /* Positioning */
    top: 42px !important;
}

@media only screen and (max-width: 1024px) {
    .logged-in-container {
        /* Positioning */
        height: 100%;
        width: 100%;
        /* Display & Box model */
        display: grid;
        grid-template-columns: 1fr 1fr 60px 60px 40px;
        align-items: center;
        justify-items: center;
        grid-row: 1/2;
        grid-column: 2/3;
        /* Color */
    
        /* Text */
    
        /* Other */
    }
}

.header-profile-icon {
    /* Positioning */
    
    /* Display & Box model */
    
    /* Color */

    /* Text */

    /* Other */
    cursor: pointer;
}
