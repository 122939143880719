.auto-fill {
    height: 31px;
    background-color: white;
    border-radius: 16px;
    color: black;
    font-size: 11px;
    padding-right: 25px;
    padding-left: 25px;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.input-box-dropdown {
    /* Positioning */
    position: relative;
    margin-bottom: 20px;
    left: -25px;
   
    /* Display & Box model */
    display: block;
    width: calc(100% - 49px);
    height: 31px;
    background-color: #ffffff00;
    border-radius: 16px;

    /* Text */
    color: black;
    font-size: 11px;
    padding-right: 25px;
    padding-left: 25px;
    letter-spacing: 1px;

    /* Other */
    z-index: 2;
}

.input-box-placeholder-dropdown {
    /* Positioning */
    position: absolute;
    margin-bottom: -31px;
   
    /* Display & Box model */
    display: block;

     /* width: calc(100% - 10px); */
    height: 31px;
    width: 57%;
    background-color: white;
    border-radius: 16px;

    /* Text */
    text-align: left;
    letter-spacing: 1px; 
}

.placeholder-top-dropdown {
    /* Positioning */
    position: absolute;
    margin-top: -25px;

    /* Text */
    font-size: 12px !important;
    text-align: left;

    /* Other */
    z-index: 3;
}

.placeholder-text-dropdown {
    /* Positioning */
    padding-top: 7px;

    /* Text */
    color: black;
    font-size: 11px;
}

.input-white-background-dropdown {
    /* Positioning */
    left: -25px;
}

.registration-buyer-checkBox-container {
    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 20px 100px 1fr;
    grid-column-gap: 10px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.registration-buyer-checkBox-text {
    /* Display & Box model */
    grid-column: 3 / 4;

     /* Text */
     font-size: 17px;
     color: var( --default-blue-color );
     text-align: left;
}

.registration-buyer-checkBox {
    /* Display & Box model */
    grid-column: 2 / 3;
}

#postcode {
    height: 31px;
    width: 100%;
    letter-spacing: 1px;
    font-size: 12px;
    font-family: Segoe UI, sans-serif;
}