.articles-container {
    /* Positioning */
    position: relative;
    margin-bottom: 30px;

    /* Display & Box model */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    height: 300px;
}

.article-1 {
    /* Display & Box model */
    grid-column: 1 / 2;
    padding: 5%;

    /* Text */
    font-family: 'Segoe UI Semibold';
    font-size: 34px;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;

    /* Other */
    background:url('/img/flowers.png') no-repeat center;
    background-size: cover;
}

.article-2 {
    /* Display & Box model */
    grid-column: 2 / 3;
    padding: 5%;

    /* Text */
    font-family: 'Segoe UI Semibold';
    font-size: 34px;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;

    /* Other */
    background:url('/img/fruits.png') no-repeat center;
    background-size: cover;
}

.article-3 {
    /* Display & Box model */
    grid-column: 3 / 4;
    padding: 5%;

    /* Text */
    font-family: 'Segoe UI Semibold';
    font-size: 34px;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;
    
    /* Other */
    background:url('/img/pies.png') no-repeat center;
    background-size: cover;
}

.article-4 {
    /* Display & Box model */
    grid-column: 4 / 5;
    padding: 5%;

    /* Text */
    font-family: 'Segoe UI Semibold';
    font-size: 34px;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;
    
    /* Other */
    background:url('/img/salate.png') no-repeat center;
    background-size: cover;
    align-items: center;
}

.article-title {
    /* Positioning */
    position: relative;

    /* Display & Box model */
    padding-top: 200px;
    
    /* Other */
    text-shadow: 1px 1px 1px #000000; 
    font-size: 28px;
}

@media only screen and (max-width: 1024px) {
    .articles-container {
        /* Display & Box model */
        display: grid;
        grid-template-columns: auto !important;
        grid-template-rows: 220px 220px 220px 220px !important;
        width: 100%;
        height: 960px;
    }
    
    .article-1 {
        /* Display & Box model */
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        padding-right: 15px;
        padding-left: 15px;
    }
    
    .article-2 {
        /* Display & Box model */
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        padding-right: 15px;
        padding-left: 15px;
    }
    
    .article-3 {
        /* Display & Box model */
        grid-row: 3 / 4;
        grid-column: 1 / 2;
        padding-right: 15px;
        padding-left: 15px;
    }
    
    .article-4 {
        /* Display & Box model */
        grid-row: 4 / 5;
        grid-column: 1 / 2;
        padding-right: 15px;
        padding-left: 15px;

        /* Other */
        align-items: center;
    }

    .article-title {
        /* Positioning */
        position: relative;
        margin: 0;
        top: 50%;
        left: 50%;

        /* Display & Box model */
        padding-top: 0px;
    
        /* Other */
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}